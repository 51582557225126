import { CargoType, CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { getVisitDashboardInfoByOrders } from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { VisitDashboardDirectionCard } from './VisitDashboardDirectionCard'

interface Props {
  orders: IOrderItem[]
  cargoType?: CargoType
  visitId?: number
  visitType?: CarrierType
  cardFooter?: ReactNode
  isViewExtended?: boolean
  onViewClick: () => void
  onClickExportOrders?: (direction: CarrierVisitDirection) => void
}
export const InboundDashboardCard = observer(
  ({
    orders,
    cargoType,
    visitId,
    visitType,
    cardFooter,
    isViewExtended,
    onViewClick,
    onClickExportOrders,
  }: Props) => {
    const { t } = useTranslate()

    const { totalDefinition, archetypeGroups } = computed(() => {
      return getVisitDashboardInfoByOrders(orders)
    }).get()

    return (
      <VisitDashboardDirectionCard
        title={t('discharge', 'Discharge')}
        cargoType={cargoType}
        totalDefinition={totalDefinition}
        direction={CarrierVisitDirection.Inbound}
        visitId={visitId}
        visitType={visitType}
        archetypeGroups={archetypeGroups}
        isViewExtended={isViewExtended}
        onViewClick={onViewClick}
        onClickExportOrders={
          onClickExportOrders ? () => onClickExportOrders(CarrierVisitDirection.Inbound) : undefined
        }
        cardFooter={cardFooter}
      />
    )
  },
)
