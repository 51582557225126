import { GenerateEirPdfResponseDto } from '@planning/app/api'
import documentsService from '@planning/services/documentsService'
import { action, computed, makeObservable, observable } from 'mobx'

export class GateClerkDocumentsPageViewStore {
  isLoading = false
  isDownloading = false
  generatedDocFileName: string | undefined | null = null
  routingInfoData: { containerNumber: string; blockName?: string }[] = []

  private documents = new Map<number, GenerateEirPdfResponseDto>()
  private blobs = new Map<number, Blob>()

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isDownloading: observable,
      generatedDocFileName: observable,
      routingInfoData: observable,

      loadDocument: action,
      loadBlob: action,
      getCachedDocument: action,
      reset: action,
      setGeneratedDocFileName: action,
      setRoutingInfoData: action,

      isDocumentCached: computed,
    })
  }

  setRoutingInfoData(data: { containerNumber: string; blockName?: string }[]) {
    this.routingInfoData = data
  }

  setGeneratedDocFileName(fileName: string | undefined | null) {
    this.generatedDocFileName = fileName
  }

  async loadDocument(carrierVisitId: number) {
    this.isLoading = true
    try {
      if (!this.documents.has(carrierVisitId)) {
        const doc = await documentsService.generateEirDocument({ carrierVisitId })
        this.documents.set(carrierVisitId, doc)
        this.setGeneratedDocFileName(doc.fileName)
      }
      return this.documents.get(carrierVisitId)!
    } finally {
      this.isLoading = false
    }
  }

  async loadBlob(carrierVisitId: number, fileName: string) {
    this.isDownloading = true
    try {
      if (!this.blobs.has(carrierVisitId)) {
        const blob = await documentsService.downloadEirDocument(fileName)
        this.blobs.set(carrierVisitId, blob)
      }
      return this.blobs.get(carrierVisitId)!
    } finally {
      this.isDownloading = false
    }
  }

  getCachedDocument(carrierVisitId: number) {
    return this.documents.get(carrierVisitId)
  }

  get isDocumentCached() {
    return (carrierVisitId: number) => this.documents.has(carrierVisitId)
  }

  reset() {
    this.documents.clear()
    this.blobs.clear()
    this.routingInfoData = []
    this.generatedDocFileName = null
  }
}
