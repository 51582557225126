import { Divider, Typography } from '@mui/material'
import { TruckVisitWithOrdersDto } from '@planning/app/api'
import { NavigationStackCloseButton } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'

interface props {
  truckVisit?: TruckVisitWithOrdersDto | null
  children: ReactNode
}
export const TruckVisitHeaderWithAction = observer(({ truckVisit, children }: props) => {
  const { t } = useTranslate()
  return (
    <>
      <Header
        title={truckVisit?.identifier ?? t('truck', 'Truck')}
        subTitle={truckVisit?.truckCompany?.name ?? undefined}
        leftRenderOption={<NavigationStackCloseButton />}
        rightRenderOption={children}
      />
      <ContainerMobile sx={{ paddingY: 1 }}>
        <Typography variant='captionBold'>
          {t('checkVisitDetails', 'Check visit details')}
        </Typography>
      </ContainerMobile>
      <Divider />
    </>
  )
})
