import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import { FC } from 'react'

interface Props {
  label: string | React.ReactNode
  onChange: (data: any) => void
  value?: Dayjs | string
  error: boolean
  helperText: React.ReactNode
}

export const CustomDatePicker: FC<Props> = ({ label, onChange, value, error, helperText }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        label={label}
        sx={{ width: '100%' }}
        format='DD/MM/YYYY'
        onChange={onChange}
        slotProps={{
          textField: {
            variant: 'outlined',
            helperText: error ? helperText : '',
            error: error,
            sx: { width: '100%' },
          },
        }}
      />
    </LocalizationProvider>
  )
}
