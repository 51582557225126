import { Done } from '@mui/icons-material'
import { Button, Chip, Stack } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  HoldStatus,
  NonNumericOrderDto,
  OrderResponseDto,
} from '@planning/app/api'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useState } from 'react'
import { CustomAlert } from '../../TallymanV2/Components'
import { ContainerHasHoldsAlert } from '../Atoms/ContainerHasHoldsAlert'
import { GateInAutoComplete } from './GateInAutoComplete'
import { GeneralCargoOrderCard } from './GeneralCargoOrderCard'
import { IInspectContainerFormData } from './InspectContainer'
import { PickUpOrderCard } from './PickUpOrderCard'

interface OrderDto extends OrderResponseDto {
  shifts?: number
}

interface IProps {
  store: GateInViewStore
}

export const PickUpAutoComplete: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const { tenantStore, truckVisitItemStore } = usePlanningStore()

  const [open, setOpen] = useState<boolean>(false)
  const pickUpSearchType = store.pickUpOrderSearchStore.searchType
  const pickUpContainerSearchType = store.pickUpOrderSearchStore.containerSearchType

  const isNumericOrder = (c: OrderResponseDto | NonNumericOrderDto) => 'containerNumber' in c

  const isGeneralCargoOrder = (c: OrderResponseDto | NonNumericOrderDto) => {
    return 'commodityId' in c && c.commodityId !== null
  }

  const invalidPickUp = (order: OrderResponseDto | NonNumericOrderDto) => {
    if (tenantStore.skipReleaseOrder) return false

    return 'releaseEnd' in order && !!order.releaseEnd && moment() > moment(order.releaseEnd)
  }

  const notOnTerminalValidation = (order: OrderResponseDto | NonNumericOrderDto) => {
    if (order && 'isOnTerminal' in order) {
      return (order as OrderResponseDto).isOnTerminal !== undefined && !!order.isOnTerminal
    }

    return false
  }

  const getHoldsAlertMessage = (order: OrderResponseDto | NonNumericOrderDto) => {
    if (order && 'holds' in order && order.holds.some(h => h.status === HoldStatus.Active)) {
      return <ContainerHasHoldsAlert />
    }

    return <></>
  }

  const getFirstOrderFromGroupByCurrentOrder = (order: OrderResponseDto) =>
    store.pickUpOrderSearchStore.firstOrderAvailableInGroups.find(
      x =>
        x.customerGrouping === order.customerGrouping &&
        x.linkedCustomerGroupingVisitId === order.linkedCustomerGroupingVisitId,
    )

  const isNotThePriorityFromItsGroup = (order: OrderResponseDto) => {
    const firstOrder = getFirstOrderFromGroupByCurrentOrder(order)
    return (
      firstOrder &&
      store.pickUpOrderSearchStore.containerSearchType === 'containerNumber' &&
      !store.pickUpOrders.some(
        x => x.id === firstOrder?.inboundOrderId || x.id === firstOrder.outboundOrderId,
      ) &&
      firstOrder.inboundOrderId !== (order.direction === 'Inbound' ? order.id : order.linkedOrderId)
    )
  }

  const openOutboundForADifferentCarrierValidation = (
    order: OrderResponseDto | NonNumericOrderDto,
  ) => {
    if (order && 'containerNumber' in order) {
      return !!store.pickUpOrderSearchStore.ordersByContainerNumber.find(
        item =>
          item.containerNumber === order.containerNumber &&
          item.direction === CarrierVisitDirection.Outbound &&
          item.carrierVisitId !== null,
      )
    }
  }

  const ReleaseExpiredAlert = () => (
    <CustomAlert
      message={t(
        'containerReleaseHasExpiredContactControlRoom',
        'Container release has expired, contact control room',
      )}
      severity='warning'
    />
  )

  const NotOnTerminalAlert = () => (
    <CustomAlert
      message={
        tenantStore.allowGateInWhenUnitNotOnTerminal
          ? t(
              'unitNotOnTerminalYetButTrucksArePermittedToProceed',
              'Unit (container or trailer) not on terminal yet, but trucks are permitted to proceed',
            )
          : t(
              'containerNotOnTerminalYetContactControlRoom',
              'Container not on terminal yet, contact control room',
            )
      }
      severity='warning'
    />
  )

  const OpenOutboundWithDifferentCarrierAlert = () => (
    <CustomAlert
      message={t(
        'containerHasOpenOrderForADifferentCarrier',
        'Container has an open order for a different carrier',
      )}
      severity='warning'
    />
  )

  const NotFirstFromGroupAlert: FC<{ containerNumber: string; orderId: number }> = ({
    containerNumber,
    orderId,
  }) => (
    <CustomAlert
      title={t('partOfSequencedGroup', 'Part of a sequenced group', {
        container: containerNumber,
      })}
      message={t('nextContainerToLeaveIs', 'Next container to leave is {container}', {
        container: containerNumber,
      })}
      severity='warning'
      action={
        <Button
          onClick={async event => {
            event.stopPropagation()
            await store.upsertPickUpOrderById(orderId)
            setOpen(false)
            store.pickUpOrderSearchStore.setFilter('')
          }}
        >
          {t('add', 'Add')}
        </Button>
      }
    />
  )

  const renderOption = (order: OrderDto | NonNumericOrderDto) => {
    if (pickUpSearchType === 'generalCargo') {
      return (
        <GeneralCargoOrderCard
          key={`order-card-${order.id}`}
          order={order as OrderResponseDto}
          validateOutboundRequest={store.validateOutboundRequest}
        />
      )
    } else if (store.isSearchingByReferenceNumber) {
      return <>{order.referenceNumber}</>
    }

    const visit: ITruckVisitItem =
      _.get(truckVisitItemStore.elements, order.carrierVisitId ?? 0) ?? {}

    const firstOrderFromGroup = getFirstOrderFromGroupByCurrentOrder(order as OrderResponseDto)

    if (isNumericOrder(order)) {
      return (
        <PickUpOrderCard
          key={`order-card-${order.id}`}
          pickUpOrder={order as OrderResponseDto}
          carriers={visit?.truck ? [visit.truck.data] : []}
          informationMessage={
            (order as OrderDto).shifts
              ? `${(order as OrderDto).shifts} ${t('shiftsRequired', 'shifts required')}`
              : undefined
          }
          alertMessage={[
            getHoldsAlertMessage(order),
            invalidPickUp(order) ? <ReleaseExpiredAlert /> : <></>,
            !notOnTerminalValidation(order) ? <NotOnTerminalAlert /> : <></>,
            openOutboundForADifferentCarrierValidation(order) ? (
              <OpenOutboundWithDifferentCarrierAlert />
            ) : (
              <></>
            ),
            isNotThePriorityFromItsGroup(order as OrderResponseDto) ? (
              <NotFirstFromGroupAlert
                containerNumber={firstOrderFromGroup!.containerNumber!}
                orderId={
                  firstOrderFromGroup?.outboundOrderId ?? firstOrderFromGroup!.inboundOrderId!
                }
              />
            ) : (
              <></>
            ),
          ]}
        />
      )
    } else {
      return <>{`To be assigned - ${order.referenceNumber}`}</>
    }
  }

  const getOptions = () => {
    if (store.isSearchingByReferenceNumber) {
      return store.pickUpOrderSearchStore.ordersForReferenceNumber
    }

    let containers = store.pickUpOrderSearchStore.items.filter(item => {
      if (isNumericOrder(item))
        return (
          !store.pickUpOrders.find(order => order.id === item.id) &&
          !store.pickUpGeneralCargoOrders.find(order => order.id === item.id)
        )

      return !store.nnrOrders.find(nnr => nnr.id === item.id)
    })

    if (
      store.pickUpOrderSearchStore.containerSearchType === 'referenceNumber' &&
      store.containerShifts.length
    ) {
      containers = _(containers)
        .map(item => {
          if (isNumericOrder(item)) {
            return {
              ...item,
              shifts: store.containerShifts.find(
                x => x.containerNumber === (item as OrderResponseDto).containerNumber,
              )?.shifts,
            } as OrderDto
          }

          return item
        })
        .sortBy(x => (x as OrderDto).shifts)
        .value()
    }

    return containers.filter(o => !('containerNumber' in o) || !!o.containerNumber)
  }

  const handleOrderSelection = async (
    order: OrderResponseDto | NonNumericOrderDto | undefined,
  ): Promise<void> => {
    if (store.isSearchingByReferenceNumber) {
      if (order) {
        if (store.pickUpOrderSearchStore.filter !== order.referenceNumber)
          store.pickUpOrderSearchStore.setFilter(order.referenceNumber!)
      }

      await store.setReferenceNumberAndGetContainerShifts(order?.referenceNumber ?? null)

      return
    }

    store.setReferenceNumberAndGetContainerShifts(null)
    if (!order) return

    if (store.pickUpOrderSearchStore.containerSearchType === 'referenceNumber') {
      store.pickUpOrderSearchStore.setFilter('')
    }

    if (order && isNumericOrder(order) && isGeneralCargoOrder(order)) {
      store.upsertPickUpGeneralCargoOrder(order as IInspectContainerFormData)
    } else if (order && isNumericOrder(order)) {
      store.upsertPickUpOrder(order as IInspectContainerFormData)
    } else {
      store.upsertNNROrder({
        ...order,
        pickUpAmount: 0,
      } as NonNumericOrderWithPickUpAmount)
    }
  }
  return (
    <>
      {tenantStore.hasGeneralCargo && (
        <Stack direction='row' spacing={1} mb={1}>
          <Chip
            data-cy='pick-up-autocomplete-container-chip'
            icon={pickUpSearchType === 'container' ? <Done /> : <></>}
            variant={pickUpSearchType === 'container' ? 'filled' : 'outlined'}
            label={t('unit', 'Unit')}
            onClick={() => store.pickUpOrderSearchStore.setSearchType('container')}
          />
          <Chip
            icon={pickUpSearchType === 'generalCargo' ? <Done /> : <></>}
            label={t('generalCargo', 'General Cargo')}
            variant={pickUpSearchType === 'generalCargo' ? 'filled' : 'outlined'}
            onClick={() => store.pickUpOrderSearchStore.setSearchType('generalCargo')}
          />
        </Stack>
      )}

      <GateInAutoComplete
        open={open}
        setOpen={setOpen}
        dataCy='pick-up-autocomplete'
        getOptionDisabled={option =>
          pickUpSearchType !== 'generalCargo'
            ? !store.isSearchingByReferenceNumber &&
              isNumericOrder(option) &&
              ((!tenantStore.allowGateInWhenUnitNotOnTerminal &&
                !notOnTerminalValidation(option)) ||
                invalidPickUp(option))
            : false
        }
        renderOption={renderOption}
        getOptionLabel={c => {
          if (pickUpContainerSearchType === 'referenceNumber' && c.referenceNumber)
            return c.referenceNumber

          if (isNumericOrder(c)) return (c as OrderResponseDto).containerNumber ?? ''

          return ''
        }}
        placeholder={
          pickUpContainerSearchType === 'containerNumber'
            ? t('searchUnitNumber', 'Search unit number')
            : t('searchReferenceNumber', 'Search reference number')
        }
        store={store.pickUpOrderSearchStore}
        setContainerSearchType={store.pickUpOrderSearchStore.setContainerSearchType}
        containerSearchType={store.pickUpOrderSearchStore.containerSearchType}
        searchType={store.pickUpOrderSearchStore.searchType}
        type={'PickUp'}
        options={getOptions()}
        handleOnChange={handleOrderSelection}
        handleInputOnChange={() => {
          store.setReferenceNumberAndGetContainerShifts(null)
        }}
        disableCloseOnSelect={store.isSearchingByReferenceNumber}
        ignoreResetFilter={store.pickUpOrderSearchStore.containerSearchType === 'referenceNumber'}
      />
    </>
  )
})
