import { CalendarToday, WatchLater } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { TenantStore } from '@planning/rt-stores/tenant/TenantStore'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { Header } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'
import { TruckVisitClearFitlers } from '../Molecules/TruckVisitClearFilters'
import { TruckVisitSearch } from '../Molecules/TruckVisitSearch'
import { TruckOverdue } from './TruckOverdue'
import { TruckTimeRange } from './TruckTimeRange'
import { TruckVisitAdvancedFilters } from './TruckVisitAdvancedFilters'

interface Props {
  store: TruckVisitsViewStore
  gateInViewStore: GateInViewStore
  tenantStore: TenantStore
}
export const TruckVisitHeader = observer(({ store, gateInViewStore, tenantStore }: Props) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const isTruckAppointmentImprovementEnabled = useBooleanFlagValue(
    'truck-appointment-improvement',
    false,
  )

  const onTodayClick = () => {
    const today = dayjs(new Date()).hour(0).minute(0).toDate()
    store.toggleCalendarPopper(false)
    store.setSelectedDate(today)
    store.clearTimeRange()
  }

  const onTimeRangeClick = () => {
    store.toggleTimeRangeDialog(true)
  }

  const onGateIn = async () => {
    gateInViewStore.setIsTruckAppointment(false)
    if (isTruckAppointmentImprovementEnabled) {
      navigate('new')
    } else {
      gateInViewStore.toggleGateInDialog(true)
    }
  }

  const onCreateAppointment = async () => {
    gateInViewStore.setIsTruckAppointment(true)

    if (isTruckAppointmentImprovementEnabled) {
      navigate('new')
    } else {
      gateInViewStore.toggleGateInDialog(true)
    }
  }

  return (
    <Header
      leftRenderOption={
        <Stack flex='none'>
          <Typography variant='h3'>{t('truckVisits', 'Truck visits')}</Typography>
          <TruckOverdue store={store} />
          <TruckTimeRange store={store} />
        </Stack>
      }
      rightRenderOption={
        <Stack flexDirection='row' alignItems='center' justifyContent='end' width='100%' gap={2}>
          <Button
            color='inherit'
            variant='contained'
            size='large'
            sx={{ flex: 'none' }}
            onClick={onTodayClick}
          >
            {t('today', 'Today')}
          </Button>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {!store.isCalendarPopperOpen && !store.selectedDate && (
              <IconButton
                onClick={() => {
                  store.toggleCalendarPopper(true)
                }}
                sx={{ border: `1px solid lightgray`, padding: '15px' }}
                color='secondary'
              >
                <CalendarToday />
              </IconButton>
            )}
            {(store.selectedDate || store.isCalendarPopperOpen) && (
              <DatePicker
                value={store.selectedDate ? dayjs(store.selectedDate) : null}
                open={store.isCalendarPopperOpen}
                sx={{ marginTop: '0px' }}
                onChange={newDate => {
                  store.setSelectedDate(newDate?.toDate() ?? undefined)
                  store.toggleCalendarPopper(false)
                }}
                onOpen={() => store.toggleCalendarPopper(true)}
                slotProps={{
                  field: { clearable: true },
                }}
              />
            )}
          </LocalizationProvider>

          <IconButton
            sx={{ border: `1px solid lightgray`, padding: '15px' }}
            color='secondary'
            onClick={onTimeRangeClick}
          >
            {store.isTimeRangeSet && (
              <Typography variant='body1' sx={{ marginRight: '5px' }}>
                {dayjs(store.timeRangeStart).format('hh:mm A')}
                {' - '}
                {dayjs(store.timeRangeEnd).format('hh:mm A')}
              </Typography>
            )}
            <WatchLater />
          </IconButton>

          <TruckVisitSearch store={store} />
          <TruckVisitAdvancedFilters store={store} />
          <TruckVisitClearFitlers store={store} />

          <Stack flexDirection='row' gap={2} flex='none'>
            {(tenantStore.skipTruckAppointment || !isTruckAppointmentImprovementEnabled) && (
              <Button
                data-cy='gate-control-gate-in-btn'
                key='gateInButton'
                variant='contained'
                onClick={onGateIn}
              >
                {t('gateIn', 'Gate In')}
              </Button>
            )}

            {(!tenantStore.skipTruckAppointment || !isTruckAppointmentImprovementEnabled) && (
              <Button
                key='createAppointment'
                variant='contained'
                onClick={onCreateAppointment}
                data-cy='create-appointment-btn'
              >
                {t('createAppointmentButton', 'Create appointment')}
              </Button>
            )}
          </Stack>
        </Stack>
      }
    />
  )
})
