import { Box, debounce, Stack, Typography } from '@mui/material'
import {
  CarrierVisitDirection,
  CarrierVisitStatus,
  TruckVisitWithOrdersDto,
} from '@planning/app/api'
import { HeaderAvatar, NavigationStackBackButton } from '@planning/pages/TallymanV2/Components'
import { truckVisitService } from '@planning/services'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { TruckVisitSearchAlert } from '../Molecules/TruckVisitSearchAlert'
import { TruckVisitSearchField } from '../Molecules/TruckVisitSearchField'
import {
  TruckAppointmentSearchBy,
  TruckAppointmentSearchResult,
} from '../TruckAppointmentSearchResult'

interface Props {
  label: string
  direction: CarrierVisitDirection
  onSelectVisit: (selectedVisit: TruckVisitWithOrdersDto | null) => void
}
export const GateClerkSelectTruckVisit = observer(({ label, direction, onSelectVisit }: Props) => {
  const theme = useMinimalsTheme()

  const [truckVisits, setTruckVisits] = useState<TruckVisitWithOrdersDto[]>([])
  const [filter, setFilter] = useState<string>('')
  const [searchBy, setSearchBy] = useState<TruckAppointmentSearchBy>(
    TruckAppointmentSearchBy.TruckPlate,
  )
  const [isSearched, setIsSearched] = useState(false)

  const onSearch = useCallback(
    async (filter: string, searchBy: TruckAppointmentSearchBy) => {
      const status =
        direction === CarrierVisitDirection.Inbound
          ? CarrierVisitStatus.Expected
          : CarrierVisitStatus.Arrived
      let newItems: TruckVisitWithOrdersDto[] = []

      setIsSearched(false)
      if (filter) {
        if (searchBy === TruckAppointmentSearchBy.DriverID) {
          newItems = await truckVisitService.getByDriverId(filter, status)
        } else {
          newItems = await truckVisitService.getByLicensePlate(filter, status)
        }
      }

      setIsSearched(true)
      setTruckVisits(newItems)
    },
    [direction],
  )

  const debouncedSendRequest = useMemo(() => debounce(onSearch, 500), [onSearch])

  const handleSearch = (filter: string, searchBy: TruckAppointmentSearchBy) => {
    setFilter(filter)
    setSearchBy(searchBy)
    debouncedSendRequest(filter, searchBy)
  }

  const handleClearFilter = () => {
    setFilter('')
    setTruckVisits([])
    setIsSearched(false)
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Header
        leftRenderOption={
          <Stack gap={2} width='100%' paddingY={1}>
            <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
              <Stack flexDirection='row' gap={1} alignItems='center'>
                <NavigationStackBackButton />
                <Typography variant='subtitle2'>{label}</Typography>
              </Stack>
              <HeaderAvatar />
            </Stack>
            <TruckVisitSearchField onSearch={handleSearch} onClearFilter={handleClearFilter} />
          </Stack>
        }
      />
      <ContainerMobile>
        <Stack gap={2} paddingY={2}>
          {truckVisits.map(truckVisit => (
            <TruckAppointmentSearchResult
              key={`truck-visit-search-result-${truckVisit.id}`}
              onClick={onSelectVisit}
              truckVisitWithOrdersDto={truckVisit}
              searchBy={searchBy}
              highlight={filter}
            />
          ))}

          {truckVisits.length === 0 && isSearched && filter && (
            <TruckVisitSearchAlert filter={filter} direction={direction} />
          )}
        </Stack>
      </ContainerMobile>
    </Box>
  )
})
