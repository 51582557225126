import { CarrierVisitDirection, OrderResponseDto, RestowResponseDto } from '@planning/app/api'
import { DashboardStatusField } from '@planning/components/visits-dashboard/VisitOrdersStatusRange'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import _ from 'lodash'

export const sortOrdersByContainerNumber = (orders: OrderResponseDto[]) => {
  return orders.sort((a, b) => (a.containerNumber ?? '').localeCompare(b.containerNumber ?? ''))
}

interface OrderProps {
  isOnTerminal?: boolean
  direction?: string
  hasActiveHolds?: boolean
  isReleaseExpired?: boolean
  allowGateInWhenUnitNotOnTerminal?: boolean
  skipReleaseOrder?: boolean
}
export const canOrderProceed = ({
  allowGateInWhenUnitNotOnTerminal,
  direction,
  hasActiveHolds,
  isOnTerminal,
  isReleaseExpired,
  skipReleaseOrder,
}: OrderProps): boolean => {
  const isValidOnTerminalState =
    direction === CarrierVisitDirection.Inbound
      ? !isOnTerminal
      : allowGateInWhenUnitNotOnTerminal || isOnTerminal

  const isValidState =
    isValidOnTerminalState && !hasActiveHolds && (skipReleaseOrder || !isReleaseExpired)

  return !!isValidState
}

export interface VisitDashboardTotalDefinition {
  teu?: number | null
  total?: number | null
  expected: DashboardStatusField
  onTerminal: DashboardStatusField
  offTerminal: DashboardStatusField
  full: number
  empty: number
}
export const getVisitDashboardInfoByOrders = (orders: IOrderItem[]) => {
  const totalDefinition = {
    total: 0,
    teu: 0,
    expected: {
      value: 0,
    },
    offTerminal: {
      value: 0,
    },
    onTerminal: {
      value: 0,
    },
    full: 0,
    empty: 0,
  }

  orders.forEach(order => {
    totalDefinition.total += 1
    totalDefinition.teu += order.data.containerLength && order.data.containerLength >= 30 ? 2 : 1

    if (order.data.isEmpty) totalDefinition.empty += 1
    else totalDefinition.full += 1

    if (order.containerJourney?.status === 'Departed') {
      totalDefinition.offTerminal.value += 1
    } else if (
      order.containerJourney?.status === 'OnTerminal' ||
      order.containerJourney?.status === 'OnYard'
    ) {
      totalDefinition.onTerminal.value += 1
    } else {
      totalDefinition.expected.value += 1
    }
  })

  const archetypeGroups = getOrdersArchetypeGroups(orders)

  return {
    totalDefinition,
    archetypeGroups,
  }
}

export const getVisitDashboardTotalDefinitionByRestows = (restows: RestowResponseDto[]) => {
  const total = {
    total: 0,
    teu: 0,
    expected: {
      value: 0,
    },
    offTerminal: {
      value: 0,
    },
    onTerminal: {
      value: 0,
    },
    empty: 0,
    full: 0,
  }

  restows.forEach(restow => {
    total.total += 1
    total.teu += restow.unitLength && restow.unitLength >= 30 ? 2 : 1

    if (restow.isEmpty) total.empty += 1
    else total.full += 1

    if (restow.status === 'Finished') {
      total.offTerminal.value += 1
    } else if (restow.status === 'InProgress') {
      total.onTerminal.value += 1
    } else {
      total.expected.value += 1
    }
  })

  return total
}

export interface IOrderArchetypeGroupModel {
  name: string | null
  total: number
}

const getOrdersArchetypeGroups = (orders: IOrderItem[]): IOrderArchetypeGroupModel[] => {
  if (orders.length === 0) {
    return []
  }

  const grouped = _.groupBy(orders, o => o.data.isoCodeArchetype ?? '__NULL__')

  return Object.entries(grouped).map(([key, value]) => ({
    name: key === '__NULL__' ? null : key,
    total: value.length,
  }))
}
