import { ArchetypeResponseDto } from '@admin/app/api'
import { useTranslate } from '@tolgee/react'
import { CardWithAttributes, useContainerAttributesParameters } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CardAttributesProps } from 'modules/ui/src/theme/components/card/CardAttributes'
import { FC } from 'react'

interface Props {
  archetype: ArchetypeResponseDto
  filter: string
  onClickEdit?: (id: number) => void
}

export const ArchetypeCard: FC<Props> = observer(({ archetype, filter, onClickEdit }) => {
  const { t } = useTranslate()
  const { types: containerTypeParameters, heights: containerHeightParameters } =
    useContainerAttributesParameters()

  const { id, name, lengths, heights, types, isoCodes } = archetype

  const attributes: CardAttributesProps[] = [
    {
      label: t('lengths', 'Lengths'),
      value: lengths?.map(l => `${l}ft`).join(', ') || t('notAssigned', 'Not assigned'),
    },
    {
      label: t('heights', 'Heights'),
      value:
        heights?.map(h => containerHeightParameters[h].label).join(', ') ||
        t('notAssigned', 'Not assigned'),
    },
    {
      label: t('types', 'Types'),
      value:
        types?.map(t => containerTypeParameters[t].label).join(', ') ||
        t('notAssigned', 'Not assigned'),
    },
    {
      label: t('isoCodes', 'ISO Codes'),
      value:
        isoCodes?.length > 0
          ? t('isoCodeMatches', '{amount} matches', { amount: isoCodes.length })
          : t('notAssigned', 'Not assigned'),
    },
  ]

  return (
    <CardWithAttributes
      title={name}
      highlightedText={filter}
      attributes={attributes}
      onClick={() => onClickEdit?.(id)}
    />
  )
})
