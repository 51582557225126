import { CompanyResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetCompanyByIdQuery } from '@planning/messages/queries/getCompanyByIdQueryHandler'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { CompanyItem, ICompanyItem } from './CompanyItem'

export class CompanyItemStore
  extends ItemStore<CompanyResponseDto, ICompanyItem>
  implements IEntityStore<ICompanyItem>
{
  constructor(readonly messageBus: IMessageBus) {
    super((key, data) => new CompanyItem(key, data), {
      messageBus,
      fetchFunc: (id: number) => new GetCompanyByIdQuery(id),
    })

    makeObservable(this, {
      receiveEntityMessage: action,
      receiveDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetCompanyByIdQuery.type, this.receiveEntityMessage)
    messageBus.subscribeEvent(EventTypes.CompanyUpsertedInternalEvent, this.receiveEntityMessage)
    messageBus.subscribeEvent(
      EventTypes.CompanyDeletedInternalEvent,
      this.receiveDeletedEventMessage,
    )
  }

  receiveEntityMessage = (event: IEvent<CompanyResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveDeletedEventMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }
}
