import { CarrierVisitDirection } from '@planning/app/api'
import { GatePassageNotificationStore } from '@planning/stores/gateClerk/GatePassageNotificationStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { CancellableTimeoutAlert } from './CancellableTimeoutAlert'

interface IProps {
  store: GatePassageNotificationStore
  onCancel?: () => void
}

export const GatePassageResultAlert: FC<IProps> = observer(({ store, onCancel }) => {
  const { t } = useTranslate()

  const { licensePlate, direction, cancellable, isTruckAppointment } = store
  const successText = isTruckAppointment
    ? `${t('appointmentConfirmedForTruck', 'Appointment confirmed for truck')} ${licensePlate} `
    : `${licensePlate} ${
        direction === CarrierVisitDirection.Inbound
          ? t('gatedIn', 'Gated in')
          : t('gatedOut', 'Gated out')
      }`
  const cancelledText = `${
    direction === CarrierVisitDirection.Inbound
      ? t('gateInOf', 'Gate in of')
      : t('gateOutOf', 'Gate out of')
  } ${licensePlate} ${t('cancelled', 'Cancelled')}`

  const errorText = `${
    direction === CarrierVisitDirection.Inbound
      ? t('gateInOf', 'Gate in of')
      : t('gateOutOf', 'Gate out of')
  } ${licensePlate} ${t('failed', 'Failed')}`

  if (!licensePlate || !direction) return <></>

  return (
    <CancellableTimeoutAlert
      store={store}
      onCancel={onCancel}
      successText={successText}
      errorText={errorText}
      cancelledText={cancelledText}
      cancellable={false}
      testId='gate-passage-result-alert'
    />
  )
})
