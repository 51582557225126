import { Grid, Stack } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IssueDashboardCard } from '@planning/components/IssueDashboardCard'
import { ExtendedViewSwitch } from '@planning/components/visits-dashboard/ExtendedViewSwitch'
import { InboundDashboardCard } from '@planning/components/visits-dashboard/InboundDashboardCard'
import { OutboundDashboardCard } from '@planning/components/visits-dashboard/OutboundDashboardCard'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { RailTracksPlanningView } from '@planning/pages/RailVisit/Components/RailTracksPlanningView'
import { OrderMovementEditableCard } from '@planning/pages/VesselVisit/Components/OrderMovementEditableCard'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { vesselVisitDetailPages } from '../VesselVisitsPage'
import { IDetailPages } from './CarrierVisitActionBar'
import { OrderListUploadDialogV2 } from './Upload/OrderListUploadDialog'
import { VisitBreak } from './VisitBreak'

export const CarrierVisitDashboard: FC<{
  visitType: CarrierType
  detailPages?: IDetailPages
  vesselVisitItem?: IVesselVisitItem
  railVisitItem?: IRailVisitItem
  handlePageChange: (page: string) => void
  onClickExportOrders?: (direction: CarrierVisitDirection) => void
  unresolvedOperationsIssueCount?: number
}> = observer(
  ({
    vesselVisitItem,
    railVisitItem,
    visitType,
    detailPages,
    handlePageChange,
    onClickExportOrders,
    unresolvedOperationsIssueCount,
  }) => {
    const isNewDashboardCardsActive = useBooleanFlagValue('vessel-dashboard-new-cards', false)
    const { orderListUploadViewStoreV2 } = usePlanningStore()
    const [isViewExtended, seIsViewExtended] = useState(false)

    if (!vesselVisitItem && !railVisitItem) return <></>
    const selectDetailPages = detailPages ?? vesselVisitDetailPages

    return (
      <>
        <Grid container justifyContent='flex-start' spacing={{ xs: 1, md: 3 }} paddingX='1.5rem'>
          {isNewDashboardCardsActive ? (
            <>
              <Grid item xs={12} paddingTop='0 !important'>
                <Stack alignItems='end'>
                  <ExtendedViewSwitch onChange={seIsViewExtended} />
                </Stack>
              </Grid>

              <Grid item sm={12} md={6} lg={4}>
                <InboundDashboardCard
                  visitId={(railVisitItem ?? vesselVisitItem)?.data.id}
                  orders={(railVisitItem ?? vesselVisitItem)?.discharge.orders ?? []}
                  visitType={visitType}
                  cargoType={(railVisitItem ?? vesselVisitItem)?.data.cargoType}
                  isViewExtended={isViewExtended}
                  onViewClick={() => handlePageChange(selectDetailPages.discharge)}
                  onClickExportOrders={onClickExportOrders}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <OutboundDashboardCard
                  visitId={(railVisitItem ?? vesselVisitItem)?.data.id}
                  orders={(railVisitItem ?? vesselVisitItem)?.load.orders ?? []}
                  visitType={visitType}
                  cargoType={(railVisitItem ?? vesselVisitItem)?.data.cargoType}
                  isViewExtended={isViewExtended}
                  onViewClick={() => handlePageChange(selectDetailPages.discharge)}
                  onClickExportOrders={onClickExportOrders}
                />
              </Grid>
              <OrderListUploadDialogV2
                enableFieldSelectionFromOrdersView={false}
                open={orderListUploadViewStoreV2.open}
                handleDialogClose={() => {
                  orderListUploadViewStoreV2.setOpen(false)
                }}
                visitType={visitType}
              />
            </>
          ) : (
            <>
              <Grid item sm={12} md={6} lg={4}>
                <OrderMovementEditableCard
                  visitType={visitType}
                  vesselVisitItem={vesselVisitItem}
                  railVisitItem={railVisitItem}
                  direction={CarrierVisitDirection.Inbound}
                  onViewClick={() => handlePageChange(selectDetailPages.discharge)}
                  onClickExportOrders={onClickExportOrders}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <OrderMovementEditableCard
                  visitType={visitType}
                  vesselVisitItem={vesselVisitItem}
                  railVisitItem={railVisitItem}
                  direction={CarrierVisitDirection.Outbound}
                  onViewClick={() => handlePageChange(selectDetailPages.load)}
                  onClickExportOrders={onClickExportOrders}
                />
              </Grid>
            </>
          )}

          <Grid item sm={12} md={6} lg={4}>
            <IssueDashboardCard
              visitType={visitType}
              vesselVisitItem={vesselVisitItem}
              railVisitItem={railVisitItem}
              onViewClick={() => handlePageChange(`${PATH_PLANNING.issues}`)}
              unresolvedOperationsIssueCount={unresolvedOperationsIssueCount}
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <VisitBreak vesselVisitItem={vesselVisitItem} railVisitItem={railVisitItem} />
          </Grid>

          {railVisitItem && (
            <Grid item sm={12} md={6} lg={4}>
              <RailTracksPlanningView railVisit={railVisitItem} />
            </Grid>
          )}
        </Grid>
      </>
    )
  },
)
