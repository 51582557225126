import { TruckVisitDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetTruckVisitsByIdsQuery } from '@planning/messages/queries'
import { GetTruckVisitsQuery } from '@planning/messages/queries/getTruckVisitsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { CompanyItemStore } from '../externalDriver/CompanyItemStore'
import { ExternalDriverItemStore } from '../externalDriver/ExternalDriverItemStore'
import { OrderItemStore } from '../order/OrderItemStore'
import { TruckItemStore } from '../truck/TruckItemStore'
import { IDateRange, IEntityStore } from '../types'
import { ITruckVisitItem, TruckVisitItem } from './TruckVisitItem'

export interface ITruckVisitStore extends IEntityStore<TruckVisitItem> {
  isLoading: boolean
  loadedDateRange: IDateRange
  fetch: (query: IDateRange) => Promise<void>
}

export class TruckVisitItemStore extends ItemStore<TruckVisitDto, ITruckVisitItem> {
  private orderItemStore?: OrderItemStore
  private truckStore?: TruckItemStore
  private externalDriverStore?: ExternalDriverItemStore
  private companyStore?: CompanyItemStore

  constructor(private messageBus: IMessageBus) {
    super(
      (key, data) =>
        new TruckVisitItem(
          key,
          data,
          this,
          this.truckStore,
          this.externalDriverStore,
          this.companyStore,
        ),
      {
        messageBus,
        bulkFetchFunc: (ids: number[]) => new GetTruckVisitsByIdsQuery(ids),
      },
    )
    makeObservable(this, {
      ordersByTruckVisitId: computed,
      truckVisitIds: computed,

      receiveTruckVisitsMessage: action,
      receiveTruckVisitUpsertedEventMessage: action,
      receiveTruckVisitDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetTruckVisitsQuery.type, this.receiveTruckVisitsMessage)
    messageBus.subscribeEvent(GetTruckVisitsByIdsQuery.type, this.receiveTruckVisitsMessage)
    messageBus.subscribeEvent(
      EventTypes.TruckVisitUpsertedEvent,
      this.receiveTruckVisitUpsertedEventMessage,
    )
    messageBus.subscribeEvent(
      EventTypes.TruckVisitDeletedEvent,
      this.receiveTruckVisitDeletedEventMessage,
    )
  }

  connect = (
    orderItemStore: OrderItemStore,
    truckStore: TruckItemStore,
    externalDriverStore: ExternalDriverItemStore,
    companyStore: CompanyItemStore,
  ) => {
    this.orderItemStore = orderItemStore
    this.truckStore = truckStore
    this.externalDriverStore = externalDriverStore
    this.companyStore = companyStore
  }

  fetch = (query: GetTruckVisitsQuery) => {
    this.messageBus.dispatchQuery(query)
  }

  receiveTruckVisitsMessage = (event: IEvent<TruckVisitDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveTruckVisitUpsertedEventMessage = (event: IEvent<TruckVisitDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveTruckVisitDeletedEventMessage = (event: IEvent<number>) => {
    if (event.payload) {
      const truckVisit = _.values(this.elements).find(x => x.data.id === event.payload)
      if (truckVisit) {
        this.upsert({ ...truckVisit.data, isCancelled: true })
      }
    }
  }

  get truckVisitIds() {
    return _.values(this.elements).map(i => i.id)
  }

  get ordersByTruckVisitId() {
    return this.orderItemStore?.ordersByCarrierVisitId
  }
}
