import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { IOrderArchetypeGroupModel } from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { VisitOrdersArchetypeGroups } from './VisitOrdersArchetypeGroups'

interface Props {
  title: string
  groups: IOrderArchetypeGroupModel[]
  open: boolean
  handleClose: () => Promise<void> | void
}

export const VisitOrdersArchetypeGroupsDialog = observer(
  ({ title, groups, open, handleClose }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <Dialog
        open={open}
        sx={{ zIndex: '1000' }}
        fullWidth={true}
        maxWidth='xs'
        PaperProps={{
          sx: {
            height: '50vh',
          },
        }}
      >
        <DialogTitle>
          <Stack gap={theme.customSpacing.xs}>
            {title}
            <Typography variant='body1' color='secondary'>
              {t('spliByArchetype', 'Split by archetype')}
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <VisitOrdersArchetypeGroups groups={groups} wrap />
        </DialogContent>

        <DialogActions>
          <Button color='inherit' onClick={handleClose}>
            {t('close', 'Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)
