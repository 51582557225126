import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  timeout?: number
  setMouseHover: (value: boolean) => void
}

export const Hover = ({ children, setMouseHover, timeout }: Props) => {
  let timeoutToClearMouseOver: NodeJS.Timeout

  const handleMouseEnter = () => {
    if (timeoutToClearMouseOver) {
      clearTimeout(timeoutToClearMouseOver)
    }

    setMouseHover(true)
  }

  const handleMouseLeave = () => {
    timeoutToClearMouseOver = setTimeout(() => {
      setMouseHover(false)
    }, timeout ?? 3000)
  }

  return (
    <Box
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      sx={{ height: '100%', width: '100%' }}
    >
      {children}
    </Box>
  )
}
