import { useTranslate } from '@tolgee/react'
import {
  GateClerkSelectTruckVisit,
  GateInForm,
  GateOutForm,
  IGateInFormData,
  orderService,
  railVisitService,
  SearchArrivedTruckVisit,
  SelectOperation,
  truckVisitService,
  useNavigationStore,
  usePlanningStore,
  vesselVisitService,
} from '@tom-ui/planning'
import {
  CarrierType,
  CarrierVisitDirection,
  CarrierVisitStatus,
  OrderResponseDto,
  RailVisitResponseDto,
  TruckVisitDto,
  TruckVisitWithOrdersDto,
  VesselVisitDto,
} from '@tom-ui/planning/app/api'
import GateClerkDocumentsPage from '@tom-ui/planning/pages/GateClerk/GateClerkDocumentsPage'
import {
  allocationsService,
  containerTurnoverService,
  OutboundRequestValidationControl,
} from '@tom-ui/storage'
import { SuggestedContainerDto } from '@tom-ui/storage/app/api'
import { Loader } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { OutboundOrderValidationDto } from 'modules/planning/src/stores/gateControl/ValidateOutboundDto'
import { useCallback, useEffect } from 'react'
import { GateInRouteInfoPage } from './GateInRouteInfoPage'

interface NonNumeric {
  pickUpAmount: number
  containerHeight: string
  containerLength: number
  shippingLine: string
  suggestedContainers?: SuggestedContainerDto[]
}

export const GateClerkHome = observer(() => {
  const { t } = useTranslate()
  const { gateClerkViewStore, containerDamageReportViewStore, tenantStore } = usePlanningStore()
  const { notificationStore } = gateClerkViewStore
  const navigationStore = useNavigationStore()

  useEffect(() => {
    const fetchData = async () => {
      await containerDamageReportViewStore.fetch()
    }

    fetchData()
  }, [containerDamageReportViewStore])

  // [Review] TODO: Most of the logic below should be moved to the planning module. Only the functions needed calling other services should remain on host module.
  // Also a few of those functions are quite complex and need refactoring. Moreover, there is another GateClerkHome on the planning module that is not used.
  // We should remove it.
  const getLinkedOrderArrivedOrInOperationVisitName = async (
    order: OrderResponseDto,
    isPickUp?: boolean,
  ) => {
    let arrivedOrInOperationVisitName

    if (order.linkedOrderId || isPickUp) {
      const linkedOrder: OrderResponseDto = order.linkedOrderId
        ? await orderService.getById(order.linkedOrderId)
        : await orderService.getById(order.id)
      if (!linkedOrder || !linkedOrder.carrierVisitId) return arrivedOrInOperationVisitName
      if (linkedOrder.carrierVisitType === CarrierType.Vessel) {
        const vesselVisit: VesselVisitDto = await vesselVisitService.getById(
          linkedOrder.carrierVisitId,
        )
        if (
          vesselVisit.status === CarrierVisitStatus.Arrived ||
          vesselVisit.status === CarrierVisitStatus.InOperation
        ) {
          arrivedOrInOperationVisitName = vesselVisit.identifier
            ? `${t('vessel', 'vessel')}: ${vesselVisit.identifier}`
            : undefined
        }
      }
      if (linkedOrder.carrierVisitType === CarrierType.Truck) {
        const { data: truckVisits }: { data: TruckVisitDto[] } = await truckVisitService.getByIds([
          linkedOrder.carrierVisitId,
        ])
        if (truckVisits[0].ata && !truckVisits[0].atd) {
          arrivedOrInOperationVisitName = `${t('truck', 'truck')}: ${truckVisits[0].identifier}`
        }
      }
      if (linkedOrder.carrierVisitType === CarrierType.Train) {
        const railVisit: RailVisitResponseDto = await railVisitService.getById(
          linkedOrder.carrierVisitId,
        )
        if (
          railVisit.status === CarrierVisitStatus.Arrived ||
          railVisit.status === CarrierVisitStatus.InOperation
        ) {
          const railVisit: RailVisitResponseDto = await railVisitService.getById(
            linkedOrder.carrierVisitId,
          )
          arrivedOrInOperationVisitName = `${t('train', 'Train')}: ${railVisit.identifier} ${linkedOrder.railTrackName}-${t('no', 'no')}.${linkedOrder.waggon} `
        }
      }
    }
    return arrivedOrInOperationVisitName
  }

  const populateGateOutFormData = async (visit: any) => {
    const { gateOutViewStore } = gateClerkViewStore
    gateOutViewStore.setVisit(visit)
    await gateOutViewStore.fetch()
  }

  const onSelectTruckVisitForGateOut = async (visit: any) => {
    await populateGateOutFormData(visit)

    gateClerkViewStore.arrivedTruckVisitSearchStore.setFilter('')

    navigationStore.push(<GateOutForm store={gateClerkViewStore.gateOutViewStore} />)
  }

  const onGateInFormSubmit = async (data: IGateInFormData) => {
    navigationStore.push(<Loader show={true} />)

    const result = await notificationStore.createGateInRequest(data)
    const visitId = result.truckVisitId
    gateClerkViewStore.gateInViewStore.reset()

    navigationStore.push(
      <GateClerkDocumentsPage carrierVisitId={visitId} direction={CarrierVisitDirection.Inbound} />,
    )
  }

  const goToRouteInfoPage = async (data: IGateInFormData) => {
    const dropOffs: {
      containerNumber: string
      linkedOrderArrivedOrInOperationVisitName: string | undefined
    }[] =
      (await Promise.all(
        gateClerkViewStore.gateInViewStore.dropOffOrders.map(async o => {
          return {
            containerNumber: o.containerNumber!,
            linkedOrderArrivedOrInOperationVisitName:
              await getLinkedOrderArrivedOrInOperationVisitName(o),
          }
        }),
      )) ?? []
    const pickUps: {
      containerNumber: string
      linkedOrderArrivedOrInOperationVisitName: string | undefined
    }[] =
      (await Promise.all(
        gateClerkViewStore.gateInViewStore.pickUpOrders.map(async o => {
          return {
            containerNumber: o.containerNumber!,
            linkedOrderArrivedOrInOperationVisitName:
              await getLinkedOrderArrivedOrInOperationVisitName(o, true),
          }
        }),
      )) ?? []

    const nonNumeric: NonNumeric[] =
      gateClerkViewStore.gateInViewStore.nnrOrders.map(
        (o: any) =>
          ({
            pickUpAmount: o.pickUpAmount,
            containerHeight: o.containerHeight,
            containerLength: o.containerLength,
            shippingLine: o.shippingLine,
            suggestedContainers: o.suggestedContainers,
          }) as NonNumeric,
      ) ?? []

    const { data: isOnTerminal } = await truckVisitService.isOnTerminal(data.truckPlate)

    navigationStore.push(
      <GateInRouteInfoPage
        isOnTerminal={isOnTerminal}
        dropOffs={dropOffs}
        pickUps={pickUps}
        nonNumeric={nonNumeric}
        onSubmit={async () => await onGateInFormSubmit(data)}
        onClose={() => navigationStore.pop()}
      />,
    )
  }

  const onSelectVisitForTruckAppointmentGateIn = (visit: TruckVisitWithOrdersDto | null) => {
    if (!visit) return

    gateClerkViewStore.gateInViewStore.reset()

    visit.inboundOrders?.forEach(order => {
      gateClerkViewStore.gateInViewStore.upsertDropOffOrder({
        ...order,
        carrierType: CarrierType.Truck,
        damages: [],
      })
    })

    visit.outboundOrders?.forEach(order => {
      const isNonNumericOrder = order.nonNumericOrderId && !order.containerNumber

      isNonNumericOrder
        ? gateClerkViewStore.gateInViewStore.upsertNNROrderByOrder(order)
        : gateClerkViewStore.gateInViewStore.upsertPickUpOrder({
            ...order,
            carrierType: CarrierType.Truck,
            damages: [],
          })
    })

    navigationStore.push(
      <GateInForm
        truckVisitWithOrders={visit}
        store={gateClerkViewStore.gateInViewStore}
        onSubmit={data => {
          if (visit.identifier && visit.id) {
            data.truckVisitId = visit.id
            data.truckPlate = visit.identifier
            data.truckCompanyId = visit.truckCompany?.id
            data.externalDriverId = visit.driver?.id
            goToRouteInfoPage(data)
          }
        }}
      />,
    )
  }

  const onSelectVisitForTruckAppointmentGateOut = (visit: TruckVisitWithOrdersDto | null) => {
    if (!visit || !visit.id || !visit.containers) return

    //TODO: Create method to map TruckVisitWithOrdersDto to TruckVisitDto and also use it in TruckVisitAggregationStore
    const truckVisit: TruckVisitDto = {
      id: visit.id,
      status: visit.status ?? CarrierVisitStatus.Expected,
      carrierIds: [],
      cargoType: visit.cargoType ?? 'Unknown',
      driverName: visit.driver?.name,
      driverId: visit.driver?.driverID ?? '0',
      isCancelled: visit.isCancelled,
      eta: visit.eta ?? undefined,
      ata: visit.ata ?? undefined,
      etd: visit.etd ?? undefined,
      atd: visit.atd ?? undefined,
      identifier: visit.identifier,
      truckCompanyName: visit.truckCompany?.name ?? 'Unknown',
    }

    gateClerkViewStore.gateOutViewStore.reset()
    gateClerkViewStore.gateOutViewStore.setVisit(truckVisit)

    const orders = []

    if (visit.inboundOrders) orders.push(...visit.inboundOrders)
    if (visit.outboundOrders) orders.push(...visit.outboundOrders)
    gateClerkViewStore.gateOutViewStore.processOrders(orders, visit.containers)

    navigationStore.push(
      <GateOutForm truckVisitWithOrders={visit} store={gateClerkViewStore.gateOutViewStore} />,
    )
  }

  const onSelectOperationForTruckAppointment = (operation: CarrierVisitDirection) => {
    if (operation === CarrierVisitDirection.Inbound)
      navigationStore.push(
        <GateClerkSelectTruckVisit
          label={t('gateIn', 'Gate in')}
          direction='Inbound'
          onSelectVisit={onSelectVisitForTruckAppointmentGateIn}
        />,
      )
    else
      navigationStore.push(
        <GateClerkSelectTruckVisit
          label={t('gateOut', 'Gate Out')}
          direction='Outbound'
          onSelectVisit={onSelectVisitForTruckAppointmentGateOut}
        />,
      )
  }

  const onSelectOperationNext = (operation: any) => {
    if (operation === 'Inbound')
      navigationStore.push(
        <GateInForm
          store={gateClerkViewStore.gateInViewStore}
          onSubmit={goToRouteInfoPage}
          validateAllocationSpace={allocationsService.validateAllocationCapacity}
        />,
      )
    else {
      gateClerkViewStore.arrivedTruckVisitSearchStore.setFilter('')
      navigationStore.push(
        <SearchArrivedTruckVisit
          store={gateClerkViewStore.arrivedTruckVisitSearchStore}
          onClick={onSelectTruckVisitForGateOut}
        />,
      )
    }
  }

  gateClerkViewStore.gateInViewStore.validateOutboundRequest = useCallback(
    (params: OutboundOrderValidationDto): React.ReactElement => (
      <OutboundRequestValidationControl
        isOutbound={params.isOutbound}
        customerId={params.customerId}
        commodityId={params.commodityId}
        lotNumber={params.lotNumber}
        packageId={params.packageId}
        quantity={params.quantity}
        unitIds={params.unitIds}
        imoClasses={params.imoClasses}
        hideSuccessfulValidation
      />
    ),
    [],
  )

  notificationStore.getEasiestContainerToReachFunc = async (
    containerIds?: number[],
    amount?: number,
  ) => {
    return containerTurnoverService.getEasiestContainerToReach(containerIds, amount)
  }

  return (
    <SelectOperation
      store={gateClerkViewStore}
      navigateNext={
        tenantStore.skipTruckAppointment
          ? onSelectOperationNext
          : onSelectOperationForTruckAppointment
      }
    />
  )
})
