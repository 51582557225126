import { Box, Button } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, ButtonOption, ButtonWithDropdown, Header } from '@tom-ui/ui'
import { useHistory } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { BaseSyntheticEvent, FC, useCallback } from 'react'

interface ITruckAppointmentPageHeaderProps {
  viewStore: GateInViewStore
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
interface ITruckVisitPageHeaderActionsProps extends ITruckAppointmentPageHeaderProps {
  onGoBack: () => void
}

const TruckVisitPageHeaderActions: FC<ITruckVisitPageHeaderActionsProps> = observer(
  ({ viewStore, onSubmit, onGoBack }) => {
    const { t } = useTranslate()
    const { appViewStore } = usePlanningStore()

    const { notificationStore, isTruckAppointment } = viewStore

    const onGateIn = useCallback(() => {
      viewStore.setIsTruckAppointment(false)
      onSubmit()
    }, [viewStore, onSubmit])

    const getCreateVisitOptions = () => {
      const options: ButtonOption[] = [
        {
          label: t('gateIn', 'Gate in'),
          testId: 'gate-in-submit-btn',
          onClick: onGateIn,
        },
        {
          label: t('cancelAppointment', 'Cancel appointment'),
          testId: 'deleteTruckAppointment',
          onClick: async () => {
            const isConfirmed = await appViewStore.setOpenConfirmDialog(
              true,
              t(
                'truckAppointmentWillBeCancelledDoYouWantToProceed',
                'Truck appointment will be cancelled. Do you want to proceed?',
              ),
              t('cancelAppointment', 'Cancel appointment'),
            )

            if (isConfirmed) {
              const truckVisitId = viewStore.truckVisitId!
              notificationStore.deleteTruckAppointment(truckVisitId)
              onGoBack()
            }
          },
        },
      ]

      return options
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            placeContent: 'flex-end',
            placeItems: 'center',
            alignItems: 'center',
            width: '100%',
          }}
          gap={2}
        >
          <Button key='cancelGateIn' onClick={onGoBack} color='secondary'>
            {t('cancel', 'Cancel')}
          </Button>

          {isTruckAppointment && viewStore.truckVisitId && (
            <ButtonWithDropdown
              label={t('manage', 'Manage')}
              color='inherit'
              options={getCreateVisitOptions()}
            />
          )}

          {isTruckAppointment && (
            <Button
              key='confirm'
              onClick={onSubmit}
              variant={'contained'}
              type='submit'
              data-cy='truck-appointment-save-btn'
            >
              {t('save', 'Save')}
            </Button>
          )}

          {!isTruckAppointment && (
            <Button
              data-cy='gate-in-submit-btn'
              key='confirmGateIn'
              variant='contained'
              onClick={onGateIn}
            >
              {t('gateIn', 'Gate In')}
            </Button>
          )}
        </Box>
      </>
    )
  },
)

export const TruckAppointmentPageHeader: FC<ITruckAppointmentPageHeaderProps> = observer(
  ({ viewStore, onSubmit }) => {
    const { t } = useTranslate()
    const history = useHistory()

    const onGoBack = () => {
      viewStore.reset()
      history.goBackHistoryOrDefault('/truck-visits')
    }

    const isTruckAppointmentImprovementEnabled = useBooleanFlagValue(
      'truck-appointment-improvement',
      false,
    )

    const title = computed(() => {
      const createTruckAppointmentTitle = t('createTruckAppointment', 'Create truck appointment')
      const editTruckAppointmentTitle = t('editTruckAppointment', 'Edit truck appointment')

      const gateInTitle = t('gateIn', 'Gate in')

      if (viewStore.isTruckAppointment) {
        return viewStore.truckVisitId ? editTruckAppointmentTitle : createTruckAppointmentTitle
      } else {
        return gateInTitle
      }
    }).get()

    const breadCrumbs = [
      {
        label: t('truckVisits', 'Truck visits'),
        onClick: onGoBack,
      },
      {
        label: title,
      },
    ]

    return (
      <>
        {isTruckAppointmentImprovementEnabled && <Breadcrumbs items={breadCrumbs} />}
        <Header
          title={title}
          rightRenderOption={
            isTruckAppointmentImprovementEnabled && (
              <TruckVisitPageHeaderActions
                viewStore={viewStore}
                onSubmit={onSubmit}
                onGoBack={onGoBack}
              />
            )
          }
        />
      </>
    )
  },
)
