import { Alert, Box } from '@mui/material'
import { SuggestedContainerDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTolgee } from '@tolgee/react'
import { Timeline } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import ContainerDropOffInfo from './ContainerDropOffInfo'
import ContainerPickUpInfo from './ContainerPickUpInfo'

export interface NonNumeric {
  pickUpAmount: number
  containerHeight?: string
  containerLength?: number
  shippingLine: string
  suggestedContainers?: SuggestedContainerDto[]
}
interface RoutingInfoProps {
  dropOffContainers: {
    containerNumber: string
    linkedOrderArrivedOrInOperationVisitName: string | undefined
  }[]
  pickUpContainers: {
    containerNumber: string
    linkedOrderArrivedOrInOperationVisitName: string | undefined
  }[]
  nonNumeric: NonNumeric[]
  allowDirectDelivery: boolean
  onDataExtracted?: (data: { containerNumber: string; blockName?: string }[]) => void
}

const RoutingInfo = observer(
  ({
    dropOffContainers,
    pickUpContainers,
    nonNumeric,
    allowDirectDelivery,
    onDataExtracted,
  }: RoutingInfoProps) => {
    const { routingInfoStore, tenantConfigStore } = useStores()
    const { t } = useTolgee()

    useEffect(() => {
      if (routingInfoStore.dropOffInfo.length > 0 && onDataExtracted) {
        const extractedData = routingInfoStore.dropOffInfo.map(info => ({
          containerNumber: info.containerNumber,
          blockName: info.blockName ?? undefined,
        }))
        onDataExtracted(extractedData)
      }
    }, [routingInfoStore.dropOffInfo, onDataExtracted])

    return (
      <Box>
        {routingInfoStore.notPlannedWarnings > 0 && !tenantConfigStore.skipYardPlanning && (
          <Alert severity='warning' sx={{ margin: '10px' }}>
            {t('missingLocation', 'Missing location, please contact the control room')}
          </Alert>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {dropOffContainers?.length > 0 &&
            (pickUpContainers?.length > 0 || nonNumeric?.length > 0) && (
              <Timeline size={'94px'} direction='vertical' color='secondary' />
            )}

          <Box
            sx={{
              maxWidth: '400px',
              width: '100%',
              padding: '1rem',
            }}
          >
            <ContainerDropOffInfo
              dropOffContainers={dropOffContainers}
              routingInfoStore={routingInfoStore}
              allowDirectDelivery={allowDirectDelivery}
            />
            <ContainerPickUpInfo
              pickUpContainers={pickUpContainers}
              routingInfoStore={routingInfoStore}
              nonNumeric={nonNumeric}
              allowDirectDelivery={allowDirectDelivery}
            />
          </Box>
        </Box>
      </Box>
    )
  },
)

export default RoutingInfo
