import { useTranslate } from '@tolgee/react'
import { RangeGraphWithLegend, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  full: number
  empty: number
}
export const VisitOrdersStateRange = observer(({ full, empty }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <RangeGraphWithLegend
      options={[
        {
          textColor: theme.palette.info.contrastText,
          color: theme.palette.info.dark,
          value: full,
          label: t('full', 'Full'),
          order: 0,
        },
        {
          textColor: theme.palette.info.contrastText,
          color: theme.palette.info.main,
          value: empty,
          label: t('empty', 'Empty'),
          order: 1,
        },
      ]}
    />
  )
})
