import { MenuItem, Select, Stack, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CloseButton, TruckIcon, UsersIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { TruckAppointmentSearchBy } from '../TruckAppointmentSearchResult'

interface Props {
  onSearch: (filter: string, searchBy: TruckAppointmentSearchBy) => void
  onClearFilter: () => void
}
export const TruckVisitSearchField = observer(({ onSearch, onClearFilter }: Props) => {
  const { t } = useTranslate()
  const [filter, setFilter] = useState<string>('')
  const [searchBy, setSearchBy] = useState<TruckAppointmentSearchBy>(
    TruckAppointmentSearchBy.TruckPlate,
  )

  const onSearchFilterChange = (newFilter: string) => {
    setFilter(newFilter)
    onSearch(newFilter, searchBy)
  }

  const handleClearFilter = () => {
    setFilter('')
    onClearFilter()
  }

  return (
    <Stack flexDirection='row'>
      <Select
        sx={{ width: '5rem', height: '3.5rem', borderEndEndRadius: 0, borderStartEndRadius: 0 }}
        value={searchBy}
        onChange={event => {
          setSearchBy(event.target.value as TruckAppointmentSearchBy)
          handleClearFilter()
        }}
      >
        <MenuItem value={TruckAppointmentSearchBy.TruckPlate}>
          <TruckIcon />
        </MenuItem>
        <MenuItem value={TruckAppointmentSearchBy.DriverID}>
          <UsersIcon />
        </MenuItem>
      </Select>

      <TextField
        fullWidth
        placeholder={
          searchBy === TruckAppointmentSearchBy.TruckPlate
            ? t('searchTruckPlate', 'Search truck plate')
            : t('searchDriverID', 'Search driver ID')
        }
        value={filter}
        data-cy='truck-visit-input'
        onChange={e => {
          onSearchFilterChange(e.target.value)
        }}
        InputProps={{
          style: {
            height: '3.5rem',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          },
          endAdornment: filter ? <CloseButton onClose={handleClearFilter} /> : undefined,
        }}
        sx={{
          margin: 0,
        }}
      />
    </Stack>
  )
})
