import { Box } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { NoMatchingAllocationRuleAlert } from '@planning/pages/GateControl/Atoms/NoMatchingAllocationRuleAlert'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'

interface Props {
  hasActiveHolds?: boolean
  isOnTerminal?: boolean
  direction?: CarrierVisitDirection
  releaseEnd?: string | null
  plannedYardLocation?: string | null
  invalidAllocationSpace?: boolean
}
export const OrderAlerts = observer(
  ({
    hasActiveHolds,
    isOnTerminal,
    direction,
    releaseEnd,
    plannedYardLocation,
    invalidAllocationSpace,
  }: Props) => {
    const { t } = useTranslate()
    const { tenantStore } = usePlanningStore()
    const isReleaseExpired = releaseEnd ? moment(releaseEnd) : undefined

    return (
      <>
        {isOnTerminal !== undefined && isOnTerminal && direction === 'Inbound' && (
          <Box mt='0.5rem' mb='0.5rem'>
            <CustomAlert
              message={t(
                'containerIsAlreadyOnTerminalContactControlRoom',
                'Container is already on terminal, contact control room',
              )}
              severity='warning'
            />
          </Box>
        )}

        {isOnTerminal !== undefined && !isOnTerminal && direction === 'Outbound' && (
          <Box mt='0.5rem' mb='0.5rem'>
            <CustomAlert
              message={
                tenantStore.allowGateInWhenUnitNotOnTerminal
                  ? t(
                      'unitNotOnTerminalYetButTrucksArePermittedToProceed',
                      'Unit (container or trailer) not on terminal yet, but trucks are permitted to proceed',
                    )
                  : t(
                      'containerNotOnTerminalYetContactControlRoom',
                      'Container not on terminal yet, contact control room',
                    )
              }
              severity='warning'
            />
          </Box>
        )}

        {hasActiveHolds && (
          <Box mt='0.5rem' mb='0.5rem'>
            <CustomAlert
              message={t(
                'containerHasHoldsContactControlRoom',
                'Container has holds, contact control room',
              )}
              severity='warning'
            />
          </Box>
        )}

        {isReleaseExpired && !tenantStore.skipReleaseOrder && (
          <Box mt='0.5rem' mb='0.5rem'>
            <CustomAlert
              message={t(
                'containerReleaseHasExpiredContactControlRoom',
                'Container release has expired, contact control room',
              )}
              severity='warning'
            />
          </Box>
        )}

        {direction === 'Inbound' && invalidAllocationSpace && <NoMatchingAllocationRuleAlert />}

        {direction === 'Inbound' && !plannedYardLocation && <NoMatchingAllocationRuleAlert />}
      </>
    )
  },
)
