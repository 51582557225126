import { Paper, Stack, Typography } from '@mui/material'
import { OrderItemChips } from '@planning/components/organisms/OrderItemChips'
import { GeneralCargoOrderCard } from '@planning/pages/GateControl/Components/GeneralCargoOrderCard'
import { CargoTitle } from '@planning/pages/Order/components/Molecules/CargoTitle'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useTranslate } from '@tolgee/react'
import { OptionsButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { OrderWarnings } from '../Atoms/OrderWarnings'
import { ContainerStatusChip } from './ContainerJourneyStatus'

interface Props {
  order: IOrderItem
  needsReservation?: boolean
  canDisplayWarnings?: boolean
  onReserveLocation?: (orderId: number) => void
}
export const OrderCard = observer(
  ({ order, needsReservation, canDisplayWarnings, onReserveLocation }: Props) => {
    const { t } = useTranslate()

    const doesNotHaveReservation = useMemo(
      () => needsReservation && order.hasMissingPosition,
      [needsReservation, order.hasMissingPosition],
    )

    const options = useMemo(() => {
      const options = []

      if (doesNotHaveReservation && !!onReserveLocation)
        options.push({
          label: t('reserveLocation', 'Reserve location'),
          onClick: () => {
            onReserveLocation(order.data.id)
          },
        })
      return options
    }, [doesNotHaveReservation, onReserveLocation, order.data.id, t])

    return (
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          height: '100%',
          borderLeft: '0',
          borderRadius: 0,
          backgroundColor: 'transparent',
        }}
      >
        <Stack direction='row' padding={1} justifyContent='space-between'>
          {order.data.commodityId ? (
            <GeneralCargoOrderCard order={order.data} />
          ) : (
            <>
              <Stack padding={1} gap={1}>
                <CargoTitle
                  number={order.data.containerNumber}
                  suggestedContainerNumber={order.data.suggestedEmptyContainerNumber}
                  unitType={order.data.unitType}
                />
                <OrderItemChips order={order.data} displayReferenceNumber />
                {order.containerJourney?.data.plannedYardLocation &&
                  order.data.direction === 'Inbound' && (
                    <Typography variant='body2'>
                      {t('plannedLocation', 'Planned location: {location}', {
                        location: order.containerJourney?.data.plannedYardLocation,
                      })}
                    </Typography>
                  )}
                <ContainerStatusChip
                  containerJourney={order.containerJourney}
                  suggestedContainerYardLocations={order.data.suggestedEmptyContainerYardLocations}
                />
              </Stack>

              {canDisplayWarnings && (
                <Stack direction='row' gap={1} alignItems='center'>
                  <OrderWarnings
                    hasHold={order.hasActiveHold}
                    hasMissingReservation={doesNotHaveReservation}
                  />

                  {options.length > 0 && (
                    <OptionsButton
                      tooltip={t('moreOptions', 'More options')}
                      position='right'
                      options={options}
                    />
                  )}
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Paper>
    )
  },
)
