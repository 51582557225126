import { CompanyDto } from '@billing/app/api'
import { AttributesContainer } from '@billing/components/AttributesContainer/AttributesContainer'
import { Box, Card, CardHeader, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, Tile } from '@tom-ui/ui'

interface Props {
  company: CompanyDto
  canEdit: boolean
  onClick: (company: CompanyDto) => void
}
export const Company = ({ company, canEdit, onClick }: Props) => {
  const { t } = useTranslate()

  return (
    <Card onClick={() => onClick(company)} sx={{ cursor: canEdit ? 'pointer' : undefined }}>
      <CardHeader
        title={
          <Stack direction='row' spacing={1}>
            <Typography variant='h4'>{company.shortName}</Typography>
            {company.isDeactivated && (
              <ColoredLabel color='warning' label={t('deactivated', 'Deactivated')} />
            )}
          </Stack>
        }
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      />

      <AttributesContainer>
        <Tile label={t('name', 'Name')} value={company.name} />{' '}
        {!!company.address && <Tile label={t('address', 'Address')} value={company.address} />}
        {!!company.phoneNumber && (
          <Tile label={t('phoneNumber', 'Phone number')} value={company.phoneNumber} />
        )}
        {!!company.email && <Tile label={t('email', 'Email')} value={company.email} />}
        {!!company.remarks && (
          <Tooltip title={company.remarks} placement='top'>
            <Box sx={{ width: '16rem' }}>
              <Tile label={t('notes', 'Notes')} value={company.remarks} nowrap showElipsis />
            </Box>
          </Tooltip>
        )}
      </AttributesContainer>
    </Card>
  )
}
