import { Button, Paper, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { EmptyIndicator, OptionsButton } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { TruckVisitStatus, TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'
import { OrderCounts } from '../Atoms/OrderCounts'
import { TimeVisitOnTerminal } from '../Atoms/TimeVisitOnTerminal'
import { TruckCompany } from '../Atoms/TruckCompany'
import { TruckVisitAnnouncedDate } from '../Atoms/TruckVisitAnnouncedDate'
import { TruckVisitDriver } from '../Molecules/TruckVisitDriver'
import { TruckVisitPlate } from '../Molecules/TruckVisitPlate'
import { TruckVisitTableFilter } from '../Molecules/TruckVisitTableFilter'
import { TruckVisitWarnings } from '../Molecules/TruckVisitWarnings'
import { OrderList } from './OrderList'

interface Props {
  store: TruckVisitsViewStore
  gateInViewStore: GateInViewStore
}

export const TruckVisitTable: FC<Props> = observer(({ store, gateInViewStore }) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const isTruckAppointmentImprovementEnabled = useBooleanFlagValue(
    'truck-appointment-improvement',
    false,
  )

  const columns = computed(() => {
    const tableColumns: MRT_ColumnDef<ITruckVisitItem>[] = [
      {
        header: t('truckPlate', 'Truck plate'),
        Cell: cell => <TruckVisitPlate truckVisit={cell.row.original} />,
      },
      {
        header: t('process', 'Process'),
        Cell: cell => <OrderCounts truckVisit={cell.row.original} />,
      },
      {
        header: t('truckCompany', 'Truck company'),
        accessorFn: row => <TruckCompany truckVisit={row} />,
      },
      {
        header: t('truckDriver', 'Truck driver'),
        Cell: cell => <TruckVisitDriver truckVisit={cell.row.original} />,
      },
    ]

    if (store.selectedStatus === TruckVisitStatus.expected) {
      tableColumns.push(
        {
          header: t('announcedDate', 'Announced date'),
          size: 200,
          Cell: cell => <TruckVisitAnnouncedDate truckVisit={cell.row.original} />,
        },
        {
          header: t('warnings', 'Warnings'),
          Cell: cell => (
            <TruckVisitWarnings
              truckVisit={cell.row.original}
              needsReservation={store.needsReservation}
            />
          ),
        },
        {
          header: '',
          id: `actions_${store.selectedStatus}`,
          size: 100,
          Cell: cell => (
            <OptionsButton
              tooltip={t('moreOptions', 'More options')}
              options={[
                {
                  label: t('edit', 'Edit'),
                  onClick: () => {
                    if (isTruckAppointmentImprovementEnabled) {
                      navigate(`/truck-visits/${cell.row.original.id}`)
                    } else {
                      gateInViewStore.openEditTruckVisitAppointment(cell.row.original)
                    }
                  },
                },
                {
                  label: t('cancel', 'Cancel'),
                  onClick: async () => {
                    const isConfirmed = await appViewStore.setOpenConfirmDialog(
                      true,
                      t(
                        'truckAppointmentWillBeCancelledDoYouWantToProceed',
                        'Truck appointment will be cancelled. Do you want to proceed?',
                      ),
                      t('cancelAppointment', 'Cancel appointment'),
                    )

                    if (isConfirmed) await store.deleteTruckAppointment(cell.row.original.id)
                  },
                },
              ]}
            />
          ),
        },
      )
    }

    if (store.selectedStatus === TruckVisitStatus.arrived) {
      tableColumns.push(
        {
          header: t('timeOnTerminal', 'Time on terminal'),
          Cell: cell => (
            <TimeVisitOnTerminal
              ata={cell.row.original.data.ata}
              truckOverdue={store.truckOverdue}
            />
          ),
        },
        {
          header: '',
          id: `actions_${store.selectedStatus}`,
          size: 100,
          Cell: cell => (
            <Button
              color='primary'
              onClick={() =>
                gateInViewStore.openGateOutConfirmationDialogByTruckVisit(cell.row.original)
              }
            >
              {t('gateOut', 'Gate out')}
            </Button>
          ),
        },
      )
    }

    if (store.selectedStatus === TruckVisitStatus.departed) {
      tableColumns.push({
        header: t('departureTime', 'Departure time'),
        accessorFn: row => (
          <Typography variant='subtitle2'>
            {row.data.atd ? formatDateTime(row.data.atd) : EmptyIndicator}
          </Typography>
        ),
      })
    }

    return tableColumns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }).get()

  return (
    <Paper elevation={1} sx={{ overflow: 'hidden' }}>
      <TruckVisitTableFilter store={store} />
      <MaterialReactTable
        columns={columns}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        data={store.truckVisitFilteredByStatus}
        localization={{
          noRecordsToDisplay: t('noResultFound', 'No result found'),
          rowsPerPage: t('rowsPerPage', 'Rows per page'),
          of: t('of', 'of'),
        }}
        getRowId={row => row.id.toString()}
        renderDetailPanel={({ row }) => (
          <OrderList
            visit={row.original}
            needsReservation={store.needsReservation}
            canDisplayWarnings={
              store.selectedStatus === TruckVisitStatus.expected && !row.original.data.isCancelled
            }
            onReserveLocation={store.openPlanContainerPositionDialog}
          />
        )}
      />
    </Paper>
  )
})
