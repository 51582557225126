import { Box, Typography } from '@mui/material'
import { ExternalDriverResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { useWrappedLocalPagination } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import { ExternalDriverHeader } from './Components/Organisms/ExternalDriverHeader'
import { ExternalDriverList } from './Components/Organisms/ExternalDriverList'

export const ExternalDriverPage = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const { externalDriverItemStore, appViewStore, externalDriverViewStore } = usePlanningStore()

  const { loading, error } = useAsyncFetch(async () => {
    await externalDriverItemStore.fetch()
  }, [externalDriverItemStore])

  const externalDriverListViewStore = useWrappedLocalPagination(
    () => externalDriverViewStore.items,
    () => externalDriverItemStore.fetch(),
  )

  if (error) {
    return (
      <Typography variant='h4'>
        {t('errorLoadingExternalDrivers', 'Error loading external drivers')}
      </Typography>
    )
  }

  const onEdit = (item: ExternalDriverResponseDto) => {
    externalDriverViewStore.setDataToBeEdited(item)
    navigate('/registers/external-driver-form')
  }

  const onRemoveBan = (item: ExternalDriverResponseDto) => {
    externalDriverViewStore.onRemoveBan(item)
  }

  const onBan = (item: ExternalDriverResponseDto) => {
    externalDriverViewStore.onBan(item)
  }

  const onRemove = async (item: ExternalDriverResponseDto) => {
    const confirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t(
        'areYouSureYouWantToDeleteThisExternalDriver',
        'Are you sure you want to delete this external driver?',
      ),
    )

    if (confirmed) {
      await externalDriverViewStore.onDelete(item.id)
      appViewStore.setShowAlert(
        'success',
        t('externalDriverWasCancelledSuccessfully', 'External driver was removed successfully'),
      )
    }
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <Helmet>
          <title>{t('externalDrivers', 'External Drivers')}</title>
        </Helmet>

        <ExternalDriverHeader viewStore={externalDriverViewStore} />
        <ExternalDriverList
          store={externalDriverListViewStore}
          onEdit={onEdit}
          onRemove={onRemove}
          onRemoveBan={onRemoveBan}
          onBan={onBan}
        />
      </Box>
    </InitializationWrapper>
  )
})
