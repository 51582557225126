import { ArrowRightAlt } from '@mui/icons-material'
import { Box, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import {
  CarrierType,
  ContainerPositionType,
  EquipmentType,
  OperationType,
  YardLocation,
} from '@planning/app/api'
import { toYardLocationString } from '@planning/utils/container-utils'
import { useTranslate } from '@tolgee/react'
import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  ColoredLabel,
  ColorSchema,
  CraneIcon,
  EmptyContainerHandlerIcon,
  ReachStackerIcon,
  RubberTyredGantryIcon,
  TerminalTruckIcon,
  TruckIcon,
  useMinimalsTheme,
  YardIcon,
} from '@tom-ui/ui'

interface Props {
  status: ContainerJourneyStatus
  direction?: OperationType
  inboundCarrierType?: CarrierType
  currentPosition?: ContainerPositionType
  equipment?: EquipmentType
  yardLocations?: YardLocation[] | null
  plannedLocation?: string
  sx?: SxProps<Theme>
}

export const ContainerJourneyStatus = {
  Expected: 'Expected',
  Arrived: 'Arrived',
  OnTerminal: 'OnTerminal',
  OnYard: 'OnYard',
  Departed: 'Departed',
}

export type ContainerJourneyStatus =
  (typeof ContainerJourneyStatus)[keyof typeof ContainerJourneyStatus]

export const ContainerJourneyStatusChip = ({
  status,
  direction,
  inboundCarrierType,
  currentPosition,
  equipment,
  yardLocations,
  plannedLocation,
  sx,
}: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const iconSx = { fontSize: '16px' }

  const getChipBg = (value: ContainerJourneyStatus) => {
    switch (value) {
      case ContainerJourneyStatus.Expected:
      case ContainerJourneyStatus.Arrived:
        return theme.palette.info.lighter
      case ContainerJourneyStatus.OnTerminal:
      case ContainerJourneyStatus.OnYard:
        return theme.palette.success.lighter
      default:
        return theme.palette.grey[200]
    }
  }

  const getChipColor = (value: ContainerJourneyStatus): ColorSchema => {
    switch (value) {
      case ContainerJourneyStatus.Expected:
      case ContainerJourneyStatus.Arrived:
        return 'info'
      case ContainerJourneyStatus.OnTerminal:
      case ContainerJourneyStatus.OnYard:
        return 'success'
      default:
        return 'secondary'
    }
  }

  const getLabel = (value: ContainerJourneyStatus, yardLocations?: YardLocation[] | null) => {
    switch (value) {
      case ContainerJourneyStatus.Arrived:
        return t('arrived', 'Arrived')
      case ContainerJourneyStatus.OnTerminal:
        return t('onTerminal', 'On Terminal')
      case ContainerJourneyStatus.OnYard:
        return yardLocations?.length ? toYardLocationString(yardLocations) : t('onYard', 'On Yard')
      case ContainerJourneyStatus.Departed:
        return t('departed', 'Departed')
      default:
        return t('expected', 'Expected')
    }
  }

  const getEquipmentIcon = () => {
    switch (equipment) {
      case EquipmentType.Tt:
        return <TerminalTruckIcon sx={iconSx} />
      case EquipmentType.Rs:
        return <ReachStackerIcon sx={iconSx} />
      case EquipmentType.Sts:
        return <CraneIcon sx={iconSx} />
      case EquipmentType.Rtg:
        return <RubberTyredGantryIcon sx={iconSx} />
      case EquipmentType.Ech:
        return <EmptyContainerHandlerIcon sx={iconSx} />
      default:
        return <ReachStackerIcon sx={iconSx} />
    }
  }

  const getLocationIcon = () => {
    if (equipment) {
      return getEquipmentIcon()
    }

    if (status === ContainerJourneyStatus.OnYard) {
      return <YardIcon sx={iconSx} />
    } else if (
      // Special case for inbound truck
      (!currentPosition &&
        status === ContainerJourneyStatus.OnTerminal &&
        inboundCarrierType === CarrierType.Truck) ||
      // Special case for outbound truck
      currentPosition === ContainerPositionType.Truck
    ) {
      return <TruckIcon sx={iconSx} />
    }

    return <></>
  }

  const getDirectionIcon = () => {
    switch (getDirection(status, direction, currentPosition, inboundCarrierType)) {
      case OperationType.Inbound:
        return <ArrowDownRightIcon sx={iconSx} />
      case OperationType.Outbound:
        return <ArrowUpRightIcon sx={iconSx} />
      case OperationType.Internal:
        return <ArrowRightAlt sx={iconSx} />
    }

    return <></>
  }

  const getIcons = () => {
    return (
      <>
        {getLocationIcon()}
        {getDirectionIcon()}
      </>
    )
  }

  const getPlannedLocationTooltip = (plannedLocation: string) => {
    return (
      <Stack flexDirection='column' alignItems={'center'} gap={theme.customSpacing.xxxs}>
        <Typography variant='caption' color={theme.palette.grey[400]}>
          {t('plannedLocation', 'Planned location')}
        </Typography>
        <Typography variant='captionBold'>{plannedLocation}</Typography>
      </Stack>
    )
  }

  return (
    <Box data-cy='container-journey-status' sx={{ ...sx, width: 'fit-content' }}>
      <Tooltip arrow title={plannedLocation ? getPlannedLocationTooltip(plannedLocation) : ''}>
        <span>
          <ColoredLabel
            label={getLabel(status, yardLocations)}
            color={getChipColor(status)}
            sx={{
              width: 'fit-content',
              backgroundColor: getChipBg(status),
            }}
            endIcon={getIcons()}
          />
        </span>
      </Tooltip>
    </Box>
  )
}

export const getDirection = (
  status: ContainerJourneyStatus,
  operationType?: OperationType,
  currentPosition?: ContainerPositionType,
  inboundCarrierType?: CarrierType,
): OperationType | undefined => {
  if (status !== ContainerJourneyStatus.OnTerminal) return undefined

  const allowedPositionTypes: ContainerPositionType[] = [
    ContainerPositionType.Equipment,
    ContainerPositionType.Truck,
  ]
  if (currentPosition && allowedPositionTypes.includes(currentPosition)) return operationType

  // Special case for inbound truck and job has not been started
  if (!currentPosition && inboundCarrierType === CarrierType.Truck) return OperationType.Inbound

  return undefined
}
