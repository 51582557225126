import { ExternalDriverResponseDto } from '@planning/app/api'
import externalDriverService from '@planning/services/externalDriverService'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetExternalDriversByIdsQuery implements IQueryMessage {
  static type = 'GetExternalDriversByIdsQuery'
  type = GetExternalDriversByIdsQuery.type

  constructor(public readonly ids: number[]) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

export const getExternalDriversByIdsQueryHandler: IQueryHandler<
  GetExternalDriversByIdsQuery,
  IEvent<ExternalDriverResponseDto[]>
> = async query => {
  const data = await externalDriverService.getByIds(query.ids)

  return new Event(GetExternalDriversByIdsQuery.type, data)
}
