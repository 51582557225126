import { Grid, Typography } from '@mui/material'
import { Box, Stack, SxProps, Theme } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { OrderResponseDto, OrderUpdateResponseDto } from '@planning/app/api'
import { useUnitTypeIcon } from '@planning/components/UnitType'
import { HoldsDrawerButton } from '@planning/components/hold/HoldsDrawerButton'
import { emptyIndicator } from '@planning/constants'
import { IssueStatusChip } from '@planning/pages/Issues/Components/atoms/IssueStatusChip'
import { IContainerJourneyItem } from '@planning/pages/Issues/Stores/ContainerJourneyItem'
import { OpsIssue } from '@planning/pages/VesselVisit/Components'
import { useTranslate } from '@tolgee/react'
import { ButtonOption, HighlightedText, OptionsButton, useMinimalsTheme } from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { UpdatesLabelChip } from '../../pages/VesselVisit/Components/atoms/UpdatesLabelChip'
import { ContainerJourneyStatus } from '../molecules/ContainerJourneyStatusChip'
import { ContainerJourneyStatusChipFFWrapper } from '../molecules/ContainerJourneyStatusChipFFWrapper'
import { OrderItemChips } from './OrderItemChips'

export const OrderListItem: FC<{
  order: OrderResponseDto
  containerJourney?: IContainerJourneyItem
  sx?: SxProps<Theme>
  filter?: string
  options?: ButtonOption[]
  opsIssues?: OpsIssue[]
  showBorderBottom?: boolean // todo: remove this prop when integrating bulk-add-customs-to-orders feature flag
}> = observer(
  ({ sx, order, containerJourney, filter, options, showBorderBottom = true, opsIssues }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    const unityTypeIcon = useUnitTypeIcon(order.unitType)

    const { issueItemStore, orderUpdateItemStore, orderItemStore } = usePlanningStore()

    const orderIssuesTotal = computed<number>(() => {
      const orderIssues = issueItemStore.issues.filter(
        i => order.notResolvedIssueIds?.includes(i.id) ?? false,
      )

      return orderIssues.length
    }).get()

    const opsIssuesTotal = computed<number>(() => {
      const issues =
        opsIssues?.filter(
          i => order.id === i.orderId && i.carrierVisitIds!.includes(order.carrierVisitId!),
        ) ?? []

      return issues.length
    }).get()

    const orderUpdates = computed<OrderUpdateResponseDto[]>(() => {
      const orderUpdates = orderUpdateItemStore.unreadOrderUpdates.filter(
        i => order.id === i.orderId,
      )

      return orderUpdates
    }).get()

    useEffect(() => {
      if (order.linkedOrderId) orderItemStore.fetchById(order.linkedOrderId)
    }, [order.linkedOrderId, orderItemStore])

    const getContainerNumber = (order: OrderResponseDto) => {
      let containerNumber = t('toBeAssigned', 'To be assigned')

      if (!!order.containerNumber && order.containerNumber !== '') {
        containerNumber = order.containerNumber
      } else if (order.nonNumericOrderId) {
        containerNumber = t('nonNumeric', 'Non-numeric')
      }

      return containerNumber
    }

    return (
      <Grid
        key={`grp-row-${order.id}`}
        container
        paddingX={1.5}
        sx={sx}
        height={'60px'}
        display={'flex'}
        alignItems={'center'}
        borderBottom={showBorderBottom ? `1px solid ${theme.palette.grey[300]}` : ''}
        data-cy='order-group-list-item'
      >
        <Grid item xs={2} xl={2} textAlign={'start'}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {unityTypeIcon}
            <Box>
              <Typography component={'div'} variant='body2'>
                <HighlightedText highlight={filter} color={theme.palette.info.main}>
                  {getContainerNumber(order)}
                </HighlightedText>
              </Typography>
              <Typography component={'div'} variant='mini'>
                <HighlightedText highlight={filter} color={theme.palette.info.main}>
                  {`${order.referenceNumber ?? emptyIndicator}`}
                </HighlightedText>
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={7} xl={7} alignSelf={'center'} textAlign={'start'}>
          <OrderItemChips order={order} />
        </Grid>
        <Grid textAlign={'end'} item xs={3} onClick={event => event.stopPropagation()}>
          <Grid container>
            <Grid item xs={10} xl={11} sx={{ placeContent: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  placeContent: 'flex-end',
                  gap: '4px',
                }}
                data-cy={`order-group-list-item-${getContainerNumber(order)}-status`}
              >
                {(orderIssuesTotal > 0 || opsIssuesTotal > 0) && (
                  <IssueStatusChip status='Unresolved' total={orderIssuesTotal + opsIssuesTotal} />
                )}

                {orderUpdates.length > 0 && (
                  <UpdatesLabelChip useIcon updateCount={orderUpdates.length} />
                )}

                <HoldsDrawerButton order={order} />
                <ContainerJourneyStatusChipFFWrapper
                  journeyStatus={containerJourney?.status ?? ContainerJourneyStatus.Expected}
                  orderStatus={order.status}
                  direction={containerJourney?.data.operationType}
                  inboundCarrierType={containerJourney?.inboundOrder?.data.carrierVisitType}
                  currentPosition={containerJourney?.data.currentPosition}
                  equipment={containerJourney?.data.equipmentType}
                  yardLocations={containerJourney?.data.yardLocations}
                />
              </Box>
            </Grid>
            <Grid item xs={2} xl={1}>
              {!!options?.length && <OptionsButton options={options} position='bottom' />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  },
)
