import { Typography } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { EmptyIndicator } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export interface ITruckCompanyProps {
  truckVisit: ITruckVisitItem
}

export const TruckCompany: FC<ITruckCompanyProps> = observer(({ truckVisit }) => {
  return (
    <Typography variant='subtitle2'>
      {!truckVisit.company ? EmptyIndicator : truckVisit.company.data.name}
    </Typography>
  )
})
