import { Backdrop, Box, keyframes, styled, Typography } from '@mui/material'
import { Logo } from '../logo/Logo'

interface Props {
  show: boolean
  helperText?: string
}

export const Loader = ({ show, helperText }: Props) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

  const RotatedBox = styled(Box)(() => ({
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    animation: `${spin} 1s linear infinite`,
  }))

  return (
    <Backdrop
      sx={{
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        overflow: 'hidden !important',
        zIndex: theme => theme.zIndex.tooltip + 10,
      }}
      open={show}
    >
      <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
        <RotatedBox>
          <Logo zoom={350} />
        </RotatedBox>

        {helperText && (
          <Typography variant='subtitle1' align='center' mt='3rem'>
            {helperText}
          </Typography>
        )}
      </Box>
    </Backdrop>
  )
}
