import { Stack, Typography } from '@mui/material'
import { TruckVisitWithOrdersDto } from '@planning/app/api'
import { formatDateRange } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { ClockIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  truckVisit: TruckVisitWithOrdersDto
  label?: string
}
export const TruckVisitExpectedArrival = observer(({ truckVisit, label }: Props) => {
  const { t } = useTranslate()

  if (!truckVisit.eta) return <></>

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <ClockIcon />
      <Stack>
        <Typography variant='caption' color='secondary'>
          {label ?? t('expected', 'Expected')}
        </Typography>
        <Typography variant='body2'>{formatDateRange(truckVisit.eta, truckVisit.etd)}</Typography>
      </Stack>
    </Stack>
  )
})
