import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { CargoType, CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import {
  IOrderArchetypeGroupModel,
  VisitDashboardTotalDefinition,
} from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { ButtonOption, Hover, OptionsButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { VisitOrdersArchetypeGroupsWrapper } from './VisitOrdersArchetypeGroupsWrapper'
import { VisitOrdersStateRange } from './VisitOrdersStateRange'
import { VisitOrdersStatusRange } from './VisitOrdersStatusRange'

interface Props {
  title: string
  totalDefinition: VisitDashboardTotalDefinition
  cargoType?: CargoType
  direction?: CarrierVisitDirection
  visitId?: number
  visitType?: CarrierType
  archetypeGroups: IOrderArchetypeGroupModel[]
  isViewExtended?: boolean
  onViewClick: () => void
  onClickExportOrders?: (direction: CarrierVisitDirection) => void
  additionalContent?: ReactNode
  cardFooter?: ReactNode
}
export const VisitDashboardDirectionCard = observer(
  ({
    title,
    totalDefinition,
    cargoType,
    direction,
    visitId,
    visitType,
    archetypeGroups,
    isViewExtended,
    onViewClick,
    onClickExportOrders,
    additionalContent,
    cardFooter,
  }: Props) => {
    const { t } = useTranslate()
    const [isMouseOver, setIsMouseOver] = useState(false)

    const { orderListUploadViewStoreV2 } = usePlanningStore()

    const onFileUpload = useCallback(async () => {
      orderListUploadViewStoreV2.reset()

      if (visitType === CarrierType.Train) {
        await orderListUploadViewStoreV2.setRailVisitId(visitId)
      } else {
        await orderListUploadViewStoreV2.setVesselVisitId(visitId)
      }

      if (direction) {
        orderListUploadViewStoreV2.setDirection(direction)
        orderListUploadViewStoreV2.setIsRestow(false)
      } else {
        orderListUploadViewStoreV2.setIsRestow(true)
      }

      orderListUploadViewStoreV2.setOpen(true)
    }, [direction, orderListUploadViewStoreV2, visitId, visitType])

    const actionOptions = useMemo(() => {
      const options: ButtonOption[] = []

      if (cargoType && cargoType !== CargoType.GeneralCargo) {
        if (onFileUpload) {
          options.push({
            label: t('uploadManifest', 'Upload {direction} manifest', { direction: title }),
            onClick: () => onFileUpload(),
          })
        }

        if (onClickExportOrders && direction) {
          options.push({
            label: t('exportOrders', 'Export orders'),
            onClick: () => onClickExportOrders(direction),
          })
        }
      }

      return options
    }, [cargoType, direction, title, onClickExportOrders, onFileUpload, t])

    const movementViewButtonCyId = () => {
      if (!direction) return 'view-vessel-visit-restow-btn'

      return direction === 'Inbound'
        ? 'view-vessel-visit-discharge-btn'
        : 'view-vessel-visit-loading-btn'
    }

    return (
      <Hover setMouseHover={val => setIsMouseOver(val)} timeout={0}>
        <Card sx={{ minWidth: '100%', minHeight: '100%' }}>
          <CardHeader
            action={
              <Box height='1rem'>
                {isMouseOver ? (
                  <Stack direction='row' gap={1}>
                    <Button
                      color='inherit'
                      onClick={onViewClick}
                      data-cy={movementViewButtonCyId()}
                    >
                      {t('view', 'View')}
                    </Button>
                    {actionOptions.length > 0 && (
                      <OptionsButton
                        tooltip={t('moreOptions', 'More options')}
                        options={actionOptions}
                      />
                    )}
                  </Stack>
                ) : undefined}
              </Box>
            }
            title={title}
          />

          <CardContent sx={{ minHeight: '100%' }}>
            <Stack gap={2}>
              <Stack direction='row' gap={4}>
                <Stack gap={0.5}>
                  <Typography variant='subtitle2' color='secondary'>
                    {t('teu', 'TEU')}
                  </Typography>
                  <Typography variant='h3'>{totalDefinition.teu}</Typography>
                </Stack>
                <Stack gap={0.5}>
                  <Typography
                    variant='subtitle2'
                    color='secondary'
                    data-cy={`order-direction-title-card-${direction}`}
                  >
                    {t('total', 'TOTAL')}
                  </Typography>
                  <Typography
                    variant='h3'
                    data-cy={`order-direction-title-card-${direction}-value`}
                  >
                    {totalDefinition.total}
                  </Typography>
                </Stack>
              </Stack>

              {totalDefinition.total ? (
                <>
                  <VisitOrdersStatusRange
                    expected={totalDefinition.expected}
                    offTerminal={totalDefinition.offTerminal}
                    onTerminal={totalDefinition.onTerminal}
                    direction={direction}
                  />

                  {isViewExtended && (
                    <>
                      <VisitOrdersStateRange
                        full={totalDefinition.full}
                        empty={totalDefinition.empty}
                      />

                      <VisitOrdersArchetypeGroupsWrapper
                        groups={archetypeGroups}
                        isMouseOver={isMouseOver}
                        title={title}
                      />
                    </>
                  )}
                </>
              ) : (
                <Typography variant='body2'>
                  {t('noDataAvailableYet', 'No data available yet')}
                </Typography>
              )}
            </Stack>

            {additionalContent}
          </CardContent>

          {cardFooter}
        </Card>
      </Hover>
    )
  },
)
