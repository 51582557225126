import { AllocationsApi } from '@storage/app/api'

export class AllocationsService {
  constructor(private api: AllocationsApi) {}

  validateAllocationCapacity = async (inboundOrderIds: number[]) => {
    const { data: invalidOrderIds } = await this.api.validateAllocationCapacity({
      inboundOrderIds,
    })
    return invalidOrderIds
  }
}
