import { Box } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CarrierType, CheckType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { TallymanV2ViewStore } from '@planning/rt-stores/tallyman'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { RailSelectionStepper } from './Components/RailSelectionStepper'
import { VesselSelectionStepper, VesselVisitOperation } from './Components/VesselSelectionStepper'

interface IProps {
  store: TallymanV2ViewStore
  selectedCarrierType: CarrierType
  onSelectTrainOperation: (operation: CheckType) => void
  onSelectVesselOperation: (operation: VesselVisitOperation) => void
}

export const SelectCarrierAndOperation: FC<IProps> = observer(
  ({ store, selectedCarrierType, onSelectVesselOperation, onSelectTrainOperation }) => {
    const { tenantStore } = usePlanningStore()
    const isTallyRailViewFeatureEnabled = useBooleanFlagValue('tally-rail-view', false)

    const skipRailTally = tenantStore.skipRailTally || !isTallyRailViewFeatureEnabled
    const skipVesselTally = tenantStore.skipVesselTally
    const skipRailTallyLoadPreparation = tenantStore.skipRailTallyLoadPreparation

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    if (!store.vesselTallyStore || !store.railTallyStore) return <></>

    return (
      <Box
        style={{
          minHeight: 'calc(100vh - 65px)',
        }}
      >
        {selectedCarrierType === CarrierType.Vessel && !skipVesselTally && (
          <VesselSelectionStepper
            store={store.vesselTallyStore}
            onSelectOperation={onSelectVesselOperation}
          />
        )}

        {selectedCarrierType === CarrierType.Train && !skipRailTally && (
          <RailSelectionStepper
            store={store.railTallyStore}
            skipRailTallyLoadPreparation={skipRailTallyLoadPreparation}
            onSelectTrainOperation={onSelectTrainOperation}
          />
        )}
      </Box>
    )
  },
)
