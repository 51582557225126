import * as yup from 'yup'
import { CompanyFormProfile } from './company-form.profile'

export const schema = (): yup.ObjectSchema<CompanyFormProfile> =>
  yup.object({
    id: yup.number().optional(),
    shortName: yup.string().label('Short name').emptyAsUndefined().required(),
    name: yup.string().label('Name').emptyAsUndefined().required(),
    alternativeNames: yup.array().label('Alternative name').required(),
    companyTypes: yup.array().label('Company types').required().min(1),
    taxId: yup.string().label('Tax ID').emptyAsUndefined(),
    address: yup.string().label('Address').emptyAsUndefined(),
    phoneNumber: yup.string().label('Phone number').emptyAsUndefined(),
    email: yup.string().label('Email').email().emptyAsUndefined(),
    remarks: yup.string().label('Remarks').emptyAsUndefined(),
    isDeactivated: yup.boolean().label('Deactivated').required(),
  })
