import { Stack, Tooltip, Typography } from '@mui/material'
import { UnitType } from '@planning/app/api'
import { useUnitTypeIcon, useUnitTypeLabel } from '@planning/components/UnitType'
import { useTranslate } from '@tolgee/react'
import { formatContainerNumber } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'

export interface Props {
  number?: string | null
  unitType?: UnitType
  suggestedContainerNumber?: string | null
}
export const CargoTitle = observer(({ number, unitType, suggestedContainerNumber }: Props) => {
  const { t } = useTranslate()
  const unitTypeIcon = useUnitTypeIcon(unitType)
  const unitTypeLabel = useUnitTypeLabel(unitType)
  const isNonNumeric = !number
  const title = number ?? suggestedContainerNumber
  return (
    <Stack flexDirection='row' gap={1} alignItems='center'>
      <Tooltip title={unitTypeLabel} placement='bottom'>
        {unitTypeIcon}
      </Tooltip>
      <Stack>
        <Typography variant='body2'>
          {title ? formatContainerNumber(title) : t('notAssigned', 'Not assigned')}
        </Typography>
        {isNonNumeric && suggestedContainerNumber && (
          <Typography variant='body2'>
            {t('suggestedNonNumeric', 'Suggested non numeric container')}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
})
