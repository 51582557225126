import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { useNavigationStore } from '@planning/stores'
import { pdf } from '@react-pdf/renderer'
import { useTranslate } from '@tolgee/react'
import { Header, Loader, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import PdfViewerPage from '../PdfViewer/PdfViewerPage'
import {
  NonNumericContainerInfo,
  NumericContainerInfo,
  RoutingSlip,
} from './Components/RoutingSlip'

type GateClerkDocumentsPageProps = {
  carrierVisitId: number
  direction: CarrierVisitDirection
}

export const generateRoutingSlipPdfBlob = async ({
  dropOffs,
  pickUps,
}: {
  dropOffs: (NumericContainerInfo | NonNumericContainerInfo)[]
  pickUps: (NumericContainerInfo | NonNumericContainerInfo)[]
}): Promise<Blob> => {
  const doc = <RoutingSlip dropOffs={dropOffs} pickUps={pickUps} />
  const asPdf = pdf()
  asPdf.updateContainer(doc)
  const blob = await asPdf.toBlob()
  return blob
}

const GateClerkDocumentsPage: React.FC<GateClerkDocumentsPageProps> = observer(
  ({ carrierVisitId, direction }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const navigationStore = useNavigationStore()

    const { gateClerkDocumentsPageViewStore: viewStore, gateClerkViewStore } = usePlanningStore()

    useEffect(() => {
      const fetchDocument = async () => {
        const cached = viewStore.getCachedDocument(carrierVisitId)
        if (cached) {
          viewStore.setGeneratedDocFileName(cached.fileName)
          return
        }

        try {
          await viewStore.loadDocument(carrierVisitId)
        } catch (error) {
          console.error('Failed to load document:', error)
        }
      }

      fetchDocument()
    }, [carrierVisitId, viewStore])

    const handleDocumentClick = async () => {
      try {
        const blob = await viewStore.loadBlob(carrierVisitId, viewStore.generatedDocFileName!)

        navigationStore.push(
          <PdfViewerPage
            pdfBlob={blob}
            fileName={viewStore.generatedDocFileName!}
            handleOnClose={() => navigationStore.pop()}
          />,
        )
      } catch (error) {
        console.error('Error opening document', error)
      }
    }

    const handleRoutingSlipClick = async () => {
      try {
        const dropOffs: (NumericContainerInfo | NonNumericContainerInfo)[] =
          viewStore.routingInfoData.map(c => ({
            type: 'numeric' as const,
            containerNumber: c.containerNumber,
            location: c.blockName ?? 'No location provided',
          }))

        const pickUps: (NumericContainerInfo | NonNumericContainerInfo)[] =
          gateClerkViewStore.pickUpOrderSearchStore.nnrOrders.map(n => {
            return {
              type: 'non-numeric' as const,
              location: 'No location provided',
              archetype: n.archetypeName ?? 'Unknown',
              length: n.containerLengths ?? [],
              height: n.containerHeights ?? [],
              containerType: n.containerTypes ?? [],
            }
          })

        const blob = await generateRoutingSlipPdfBlob({ dropOffs, pickUps })

        navigationStore.push(
          <PdfViewerPage
            pdfBlob={blob}
            fileName={'Routing Slip'}
            handleOnClose={() => navigationStore.pop()}
          />,
        )
      } catch (error) {
        console.error('Error opening document', error)
      }
    }

    const handleDoneClick = () => {
      gateClerkViewStore.gateInViewStore.reset()
      viewStore.reset()
      navigationStore.clear()
    }

    if (viewStore.isLoading) {
      return (
        <Loader
          show={true}
          helperText={
            direction === CarrierVisitDirection.Inbound
              ? t('checkingForRoutingInfoAndEir', 'Checking for routing info and EIR')
              : t('checkingForEir', 'Checking for EIR')
          }
        />
      )
    }

    if (viewStore.isDownloading) {
      return <Loader show={true} helperText={t('openingDocument', 'Opening document...')} />
    }

    return (
      <Box>
        <Header
          title={
            direction === CarrierVisitDirection.Inbound
              ? t('gateIn', 'Gate in')
              : t('gateOut', 'Gate out')
          }
          rightRenderOption={
            <Button variant='contained' onClick={handleDoneClick}>
              {t('done', 'Done')}
            </Button>
          }
        />
        <StepperInfo title={t('documents', 'Documents')} />

        <Box sx={{ p: 2 }}>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ListItemButton
              onClick={handleDocumentClick}
              sx={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.customRadius.medium,
                padding: 2,
                alignItems: 'center',
              }}
            >
              <ListItemIcon>
                <PictureAsPdfIcon color='error' fontSize='large' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant='subtitle1' sx={{ backgroundColor: '#fff' }}>
                    {t('eir', 'EIR')}
                  </Typography>
                }
              />
            </ListItemButton>

            {direction === CarrierVisitDirection.Inbound && (
              <ListItemButton
                onClick={handleRoutingSlipClick}
                sx={{
                  border: `1px solid ${theme.palette.grey[400]}`,
                  borderRadius: theme.customRadius.medium,
                  padding: 2,
                  alignItems: 'center',
                }}
              >
                <ListItemIcon>
                  <PictureAsPdfIcon color='error' fontSize='large' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant='subtitle1' sx={{ backgroundColor: '#fff' }}>
                      {t('routingSlip', 'Routing slip')}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}
          </List>
        </Box>
      </Box>
    )
  },
)

export default GateClerkDocumentsPage
