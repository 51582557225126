import { Stack, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { LocationCodeAutoComplete, VesselServiceAutocomplete } from '@planning/components'
import { LocationCodeAutocompleteViewStore } from '@planning/stores/locationCode/LocationCodeAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const VesselServiceFields = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { vesselServiceItemStore } = usePlanningStore()

  const locationCodeAutocompleteViewStore = useMemo(
    () => new LocationCodeAutocompleteViewStore(),
    [],
  )
  const {
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext()

  const getPortRotation = (id: number) => {
    const options = computed(() =>
      _.values(vesselServiceItemStore.elements).map(vesselService => vesselService.data),
    ).get()
    return options.find(item => item.id === id)?.portRotation
  }

  const handleLocationCodeChange = (codes: string[]) => {
    setValue('portRotation', codes)
  }
  const vesselServiceId = watch('vesselServiceId')
  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('services', 'Services')}
      </Typography>

      <Stack gap={theme.customSpacing.m}>
        <Controller
          control={control}
          name='vesselServiceId'
          rules={{ required: true }}
          render={({ field }) => (
            <VesselServiceAutocomplete
              label={t('vesselService', 'Vessel service')}
              value={field.value}
              onChange={id => {
                field.onChange(id)
                setValue('portRotation', getPortRotation(id!))
              }}
              hasError={!!errors.vesselServiceId}
              required
            />
          )}
        />
        {vesselServiceId ? (
          <Controller
            name='portRotation'
            control={control}
            rules={{ required: true }}
            defaultValue={getValues('portRotation')}
            render={() => (
              <LocationCodeAutoComplete
                store={locationCodeAutocompleteViewStore}
                values={getValues('portRotation')}
                onChange={value => handleLocationCodeChange(value)}
                label={t('unCode', 'UN code')}
                error={!!errors.portRotation}
                required
                helperText={
                  errors.portRotation ? t('fieldIsRequired', 'Field is required') : undefined
                }
              />
            )}
          />
        ) : null}
      </Stack>
    </>
  )
})
