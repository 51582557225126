import { GateInFormDialog } from '@tom-ui/planning/pages/GateControl/Components/GateInDialog'
import { allocationsService, containerTurnoverService } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export const GateInFormDialogWrapper: FC = observer(() => {
  const getEasiestContainerToReachFunc = async (containerIds?: number[], amount?: number) => {
    return containerTurnoverService.getEasiestContainerToReach(containerIds, amount)
  }

  return (
    <GateInFormDialog
      getEasiestContainerToReachFunc={getEasiestContainerToReachFunc}
      validateAllocationSpace={allocationsService.validateAllocationCapacity}
    />
  )
})
