import { MenuItem, Select, Stack } from '@mui/material'
import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'
import { vesselVisitDetailPages } from '../VesselVisitsPage'
import { OrderListGridToolbar } from './OrderListGridToolbar'

export type IDetailPages = {
  dashboard: string
  discharge: string
  load: string
  restow: string
}
interface Props {
  visitType: CarrierType
  vesselVisitItem?: IVesselVisitItem
  railVisitItem?: IRailVisitItem
  detailPages?: IDetailPages
  currentPage: string
  handlePageChange: (page: string) => void
  dashboardActionButtons?: ReactElement[]
  dischargeOrdersListStore: IPaginatedStoreWithItems<IOrderItem>
  loadOrdersListStore: IPaginatedStoreWithItems<IOrderItem>
  validationOutboundRequest?: ValidateOutboundOrderFunc
  onClickExportOrders?: () => void
  restowListStore?: IPaginatedStoreWithItems<any>
}

export const CarrierVisitActionBar: FC<Props> = observer(
  ({
    visitType,
    vesselVisitItem,
    railVisitItem,
    currentPage,
    detailPages,
    handlePageChange,
    dashboardActionButtons,
    dischargeOrdersListStore,
    loadOrdersListStore,
    restowListStore,
    validationOutboundRequest,
    onClickExportOrders,
  }) => {
    const { t } = useTranslate()

    if (!vesselVisitItem && !railVisitItem) return <></>

    const visitData = vesselVisitItem?.data ?? railVisitItem?.data

    const selectDetailPages = detailPages ?? vesselVisitDetailPages

    return (
      <div className='vessel-visit-details-action'>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent='space-between'
          alignItems='baseline'
          spacing={{ xs: 1 }}
          padding='1.5rem'
        >
          <Select value={currentPage} onChange={e => handlePageChange(e.target.value)}>
            <MenuItem value={selectDetailPages.dashboard}>{t('dashboard', 'Dashboard')}</MenuItem>
            <MenuItem value={selectDetailPages.discharge}>{t('discharge', 'Discharge')}</MenuItem>
            <MenuItem value={selectDetailPages.load}>{t('load', 'Load')}</MenuItem>
            {visitType === CarrierType.Vessel && (
              <MenuItem value={selectDetailPages.restow}>{t('restow', 'Restow')}</MenuItem>
            )}
          </Select>
          {currentPage === selectDetailPages.dashboard && dashboardActionButtons && (
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1 }}>
              {dashboardActionButtons}
            </Stack>
          )}
          {currentPage === selectDetailPages.discharge && (
            <OrderListGridToolbar
              visitType={visitType}
              cargoType={visitData?.cargoType}
              visitId={visitData?.id}
              direction={CarrierVisitDirection.Inbound}
              store={dischargeOrdersListStore}
              onClickExportOrders={onClickExportOrders}
            />
          )}
          {currentPage === selectDetailPages.load && (
            <OrderListGridToolbar
              visitType={visitType}
              cargoType={visitData?.cargoType}
              visitId={visitData?.id}
              direction={CarrierVisitDirection.Outbound}
              store={loadOrdersListStore}
              validationOutboundRequest={validationOutboundRequest}
              onClickExportOrders={onClickExportOrders}
            />
          )}

          {currentPage === selectDetailPages.restow && (
            <OrderListGridToolbar
              isRestow
              visitType={visitType}
              cargoType={visitData?.cargoType}
              visitId={visitData?.id}
              direction={CarrierVisitDirection.Outbound}
              store={restowListStore!}
            />
          )}
        </Stack>
      </div>
    )
  },
)
