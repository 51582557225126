import { useAdminStore } from '@admin/AppProvider'
import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import {
  AutoCompleteWithChips,
  CardAttributes,
  useContainerAttributesParameters,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { CardAttributesProps } from 'modules/ui/src/theme/components/card/CardAttributes'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ArchetypeControlUIStore, ArchetypeFormData } from '../../stores/archetype-control.ui-store'

interface Props {
  viewStore: ArchetypeControlUIStore
  onSubmit: (data: ArchetypeFormData) => void
  onRemoveArchetypeDuplicates: (
    archetypeId: number,
    archetypeName: string,
    isoCodes: string[],
  ) => void
}
export const ArchetypeForm = observer(
  ({ viewStore, onSubmit, onRemoveArchetypeDuplicates }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { isoCodeMappingStore } = useAdminStore()
    const { types: containerTypeParameters, heights: containerHeightParameters } =
      useContainerAttributesParameters()

    const {
      control,
      formState: { errors },
      reset,
      watch,
      getValues,
      handleSubmit,
    } = useForm<ArchetypeFormData>({
      defaultValues: {
        name: '',
        isoCodes: [],
      },
    })

    const isoCodes = watch('isoCodes')

    const resetForm = useCallback(() => {
      reset({
        name: '',
        isoCodes: [],
      })
    }, [reset])

    useEffect(() => {
      if (viewStore.selectedArchetype) {
        reset({
          id: viewStore.selectedArchetype.id,
          name: viewStore.selectedArchetype.name,
          isoCodes: viewStore.selectedArchetype.isoCodes,
        })
      } else {
        resetForm()
      }
    }, [viewStore.selectedArchetype, reset, resetForm])

    useAsyncFetch(async () => {
      if (!isoCodes.length) {
        viewStore.setCurrentAttributes([])
        return
      }

      const attributes = await isoCodeMappingStore.getContainerCharacteristicsByIsoCodes(isoCodes)

      viewStore.setCurrentAttributes(attributes)
    }, [isoCodeMappingStore, isoCodes, viewStore])

    const duplicatedIsoCodes = computed(() => {
      const selectedIsoCodes = new Set(isoCodes.map(code => code.trim().toUpperCase()))

      const duplicatedIsoCodes = viewStore.inUseIsoCodes
        .filter(
          inUseIsoCode =>
            inUseIsoCode.archetypeId !== viewStore.id &&
            inUseIsoCode.isoCodes.some(code => selectedIsoCodes.has(code.trim().toUpperCase())),
        )
        .map(inUseIsoCode => ({
          ...inUseIsoCode,
          isoCodes: inUseIsoCode.isoCodes.filter(code =>
            selectedIsoCodes.has(code.trim().toUpperCase()),
          ),
        }))

      return duplicatedIsoCodes
    }).get()

    const hasDuplicateIsoCodes = computed(() => duplicatedIsoCodes.length > 0).get()

    const attributes: CardAttributesProps[] = computed(() => [
      {
        label: t('lengths', 'Lengths'),
        value:
          viewStore.currentArchetypeLengths?.map(l => `${l}ft`).join(', ') ||
          t('notAssigned', 'Not assigned'),
      },
      {
        label: t('heights', 'Heights'),
        value:
          viewStore.currentArchetypeHeights
            ?.map(h => containerHeightParameters[h].label)
            .join(', ') || t('notAssigned', 'Not assigned'),
      },
      {
        label: t('types', 'Types'),
        value:
          viewStore.currentArchetypeTypes?.map(t => containerTypeParameters[t].label).join(', ') ||
          t('notAssigned', 'Not assigned'),
      },
    ]).get()

    return (
      <form id='archetype-form' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
        <Grid container spacing={1} paddingTop={0.2}>
          <Grid item xs={2}>
            <Controller
              name='name'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  required
                  {...field}
                  label={`${t('name', 'Name')}`}
                  variant='outlined'
                  fullWidth
                  type='text'
                  onChange={async event => {
                    field.onChange(event)
                  }}
                  error={!!errors.name}
                  helperText={errors.name ? t('fieldIsRequired', 'Field is required.') : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <CardAttributes
              attributes={attributes}
              sx={{
                width: '100%',
                padding: `${theme.customSpacing.xxs} ${theme.customSpacing.s}`,
                borderRadius: `${theme.customRadius.medium}`,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} paddingTop={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`isoCodes`}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <AutoCompleteWithChips
                  label={t('isoCodes', 'ISO Codes')}
                  required
                  freeSolo
                  chipSeparators={[',', ', ', ';', ' ', '\n', '/', '.']}
                  options={isoCodes ?? []}
                  selectedValues={isoCodes ?? []}
                  onChange={value => onChange(value.map(v => v.trim().toUpperCase()))}
                  error={!!errors.isoCodes}
                  helperText={errors.isoCodes ? t('fieldIsRequired', 'Field is required.') : ''}
                />
              )}
            />
            <Typography ml={1.8} variant='caption' color='textSecondary'>
              {t(
                'isoCodesDescription',
                'Enter ISO codes separated by space, comma, semicolon, or new line.',
              )}
            </Typography>

            {hasDuplicateIsoCodes &&
              duplicatedIsoCodes.map(duplicatedIsoCode => (
                <Alert
                  key={duplicatedIsoCode.archetypeId}
                  severity='warning'
                  sx={{ marginTop: 2 }}
                  action={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <span>Remove from</span>
                      <Button
                        sx={{ bgcolor: '#0000000f' }}
                        color='inherit'
                        size='small'
                        onClick={() =>
                          reset({
                            ...getValues(),
                            isoCodes: isoCodes.filter(
                              code =>
                                !duplicatedIsoCode.isoCodes.includes(code.trim().toUpperCase()),
                            ),
                          })
                        }
                      >
                        {t('thisArchetype', 'This archetype')}
                      </Button>
                      {onRemoveArchetypeDuplicates && (
                        <Button
                          sx={{ bgcolor: '#0000000f' }}
                          color='inherit'
                          size='small'
                          onClick={() => {
                            const targetArchetype = viewStore.inUseIsoCodes.find(
                              inUseCode => inUseCode.archetypeId === duplicatedIsoCode.archetypeId,
                            )

                            if (!targetArchetype) return

                            const uniqueIsoCodes = targetArchetype.isoCodes.filter(
                              code => !isoCodes.includes(code.trim().toUpperCase()),
                            )

                            onRemoveArchetypeDuplicates(
                              duplicatedIsoCode.archetypeId,
                              duplicatedIsoCode.archetypeName,
                              uniqueIsoCodes,
                            )
                          }}
                        >
                          {duplicatedIsoCode.archetypeName}
                        </Button>
                      )}
                    </Box>
                  }
                >
                  {t(
                    'isoCodesUniqueArchetypeWarning',
                    'ISO code {isoCodes} is already assigned to archetype {archetype}',
                    {
                      isoCodes: duplicatedIsoCode.isoCodes.join(', '),
                      archetype: duplicatedIsoCode.archetypeName,
                    },
                  )}
                </Alert>
              ))}
          </Grid>
        </Grid>
      </form>
    )
  },
)
