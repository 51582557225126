import { action, computed, makeObservable, observable } from 'mobx'

export interface ICancellableTimeoutStore {
  request?: NodeJS.Timeout
  isCancelled: boolean
  requestErrorMessage?: string
  isError: boolean
  createRequest: (requestFunc: () => Promise<void>) => void
  cancelRequest: () => void
  reset: () => void
}

export class CancellableTimeoutStore implements ICancellableTimeoutStore {
  request?: NodeJS.Timeout
  requestErrorMessage?: string
  isCancelled = false
  cancellable = true

  constructor(protected delay = 5000) {
    makeObservable(this, {
      request: observable,
      requestErrorMessage: observable,
      isCancelled: observable,
      cancellable: observable,
      isError: computed,
      reset: action,
      setRequestErrorMessage: action,
      createRequest: action,
      cancelRequest: action,
    })
  }

  get isError() {
    return !!this.requestErrorMessage
  }

  createRequest = <T>(requestFunc: () => Promise<T>): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      this.request = setTimeout(async () => {
        try {
          this.cancellable = false
          const result = await requestFunc()
          resolve(result)
        } catch (error) {
          reject(error)
        } finally {
          this.cancellable = true
        }
      }, this.delay)
    })
  }

  cancelRequest() {
    if (!this.request) return

    clearTimeout(this.request)
    this.isCancelled = true
  }

  setRequestErrorMessage = (message?: string) => {
    this.requestErrorMessage = message
  }

  reset() {
    this.request = undefined
    this.isCancelled = false
    this.setRequestErrorMessage()
  }
}
