import { DocumentApi, GenerateEirPdfCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class DocumentsService {
  httpClient = createApiClient(DocumentApi)

  downloadEirDocument = async (filename: string): Promise<Blob> => {
    const { data } = await this.httpClient.downloadEirDocument(filename, { responseType: 'blob' })
    // TODO: Ensure proper typing to avoid casting
    return data as unknown as Blob
  }

  generateEirDocument = async (cmd: GenerateEirPdfCommand) => {
    const { data } = await this.httpClient.generateEirDocument(cmd)

    return data
  }
}

const documentsService = new DocumentsService()

export default documentsService
