import { Alert, Box, Typography } from '@mui/material'
import { EntityFieldDataChange, OrderStatus, OrderUpdateResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { PageHeader } from '@planning/components/molecules/PageHeader'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { containerService } from '@planning/services'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, useMinimalsTheme } from '@tom-ui/ui'
import { authStore, useAsyncFetch } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useOrderBreadcrumbs } from '../../hooks/useOrderBreadcrumbs'
import { CreateOrderPageForm, IOrderFormData } from './components/CreateOrderPageForm'
import { OrderUpdateNotification } from './components/Organisms/OrderUpdateNotification'
import { ContainerVisitHoldsDrawerButton } from './EditOrders'
import { OrderUpdatesViewStore } from './stores/OrderUpdatesViewStore'
import { SelectOrderViewStore } from './stores/SelectOrderViewStore'

const DEFAULT_RETURN_PATH = PATH_PLANNING.visits

export type HandleOrderPageLocationState = {
  returnPath: string
}

const EntityFieldDataChangeList: FC<{ update: OrderUpdateResponseDto }> = ({ update }) =>
  update.entityFieldDataChanges.map(change => (
    <EntityFieldDataChangeText key={change.name} change={change} />
  ))

const EntityFieldDataChangeText: FC<{ change: EntityFieldDataChange }> = ({ change }) => (
  <Box>
    {change.name} has changed from <strong>{change.prevValue}</strong> to{' '}
    <strong>{change.newValue}</strong>
  </Box>
)

export const HandleOrderPage: FC = observer(() => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const {
    appViewStore,
    orderItemStore,
    orderUpdateItemStore,
    containerJourneyDataStore,
    tenantStore,
  } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const isAdmin = authStore.isAdmin()

  const {
    getBookingBreadcrumbs,
    getVesselVisitOrderDetailsBreadcrumbs,
    getRailVisitOrderDetailsBreadcrumbs,
  } = useOrderBreadcrumbs()

  const { state, pathname } = useLocation() as {
    state: HandleOrderPageLocationState
    pathname: string
  }

  const { orderId: orderIdParam } = useParams()
  const orderId = Number(orderIdParam)

  const selectOrderViewStore = useMemo(
    () => new SelectOrderViewStore(orderItemStore, containerJourneyDataStore),
    [containerJourneyDataStore, orderItemStore],
  )

  const orderUpdatesViewStore = useMemo(
    () => new OrderUpdatesViewStore(orderUpdateItemStore),
    [orderUpdateItemStore],
  )

  const order = computed(() => orderItemStore.elements[orderId]?.data).get()
  const journey = computed(() => orderItemStore.elements[orderId]?.containerJourney).get()

  const updates = computed(() =>
    orderUpdatesViewStore.entityFieldUpdates.map(update => ({
      title: <EntityFieldDataChangeList key={update.id} update={update} />,
      subtitle: `${formatDateTime(update.received)} - ${update.source}`,
    })),
  ).get()

  const { loading: orderLoading, error: orderError } = useAsyncFetch(async () => {
    orderUpdatesViewStore.setOrderId(orderId)
    await orderItemStore.fetchById(orderId)
  }, [orderId, orderItemStore, orderUpdatesViewStore])

  const { loading: visitLoading, error: visitError } = useAsyncFetch(async () => {
    if (!order) return

    const { inbound, outbound } = await orderItemStore.getOrderContainerVisitDetails(order)
    if (!inbound && !outbound) return

    selectOrderViewStore.selectContainerVisit([inbound, outbound])
    if (order.containerId) selectOrderViewStore.setContainerId(order.containerId)
  }, [order, orderItemStore, selectOrderViewStore])

  const onSubmit = async (data: IOrderFormData) => {
    if (submitLoading) return
    setSubmitLoading(true)
    const { inbound, outbound } = selectOrderViewStore.visitOrders

    const cmd = selectOrderViewStore.mapOrderFormDataToUpdateContainerVisitCommand(
      data,
      tenantStore.skipReleaseOrder,
      inbound,
      outbound,
    )

    try {
      await containerService.updateContainerVisit(cmd)
      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))

      selectOrderViewStore.reset()
      onBack()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    } finally {
      setSubmitLoading(false)
    }
  }

  const onBack = () => {
    navigate(state?.returnPath || DEFAULT_RETURN_PATH)
  }

  //TODO Create a general loading error component
  if (orderError || visitError) {
    return <Typography variant='h4'>{t('errorLoadingOrder', 'Error loading the order')}</Typography>
  }

  const getRouteBreadcrumbs = useCallback(
    (pathname: string) => {
      if (!order) return []

      const path = pathname.split('/').filter(Boolean)

      const isBooking = path.includes('bookings')
      const isVesselVisit = path.includes('vessel-visits')
      const isRailVisit = path.includes('rail-visits')

      if (isBooking) {
        return getBookingBreadcrumbs(order)
      } else if (isVesselVisit) {
        return getVesselVisitOrderDetailsBreadcrumbs(order)
      } else if (isRailVisit) {
        return getRailVisitOrderDetailsBreadcrumbs(order)
      } else {
        return []
      }
    },
    [
      getBookingBreadcrumbs,
      getRailVisitOrderDetailsBreadcrumbs,
      getVesselVisitOrderDetailsBreadcrumbs,
      order,
    ],
  )

  const isOrderFulfilledAndUserNonAdmin = useMemo(
    () => !isAdmin && order?.status === OrderStatus.Fulfilled,
    [isAdmin, order?.status],
  )

  return (
    <InitializationWrapper
      isInitializing={!selectOrderViewStore.selectedContainerVisit || orderLoading || visitLoading}
    >
      <>
        <Breadcrumbs items={getRouteBreadcrumbs(pathname)} />
        <PageHeader
          formId='order-form'
          onBack={onBack}
          saveDisabled={isOrderFulfilledAndUserNonAdmin}
          isLoading={submitLoading}
        >
          <ContainerVisitHoldsDrawerButton selectOrderViewStore={selectOrderViewStore} />
        </PageHeader>

        <Box
          paddingX={theme.customSpacing.l}
          paddingY={theme.customSpacing.m}
          gap={theme.customSpacing.m}
          display={'flex'}
          flexDirection={'column'}
        >
          <OrderUpdateNotification
            onMarkAllAsRead={async () => {
              try {
                await orderUpdatesViewStore.markAllAsRead()

                appViewStore.setShowAlert(
                  'success',
                  t('markAsReadSuccessfully', 'Marked as read successfully'),
                )
              } catch (error) {
                appViewStore.setShowAlert(
                  'error',
                  t('failedToMarkUpdateAsRead', 'Failed to mark update as read'),
                )
              }
            }}
            updates={updates}
          />
          {isOrderFulfilledAndUserNonAdmin && (
            <Alert severity='warning' sx={{ marginBottom: theme.customSpacing.m }}>
              {t('cannotEditOrderWithXStatus', 'You cannot edit an order with status "{status}"', {
                status: journey?.status,
              })}
            </Alert>
          )}
          <CreateOrderPageForm store={selectOrderViewStore} onSubmit={onSubmit} />
        </Box>
      </>
    </InitializationWrapper>
  )
})
