import { Alert, Button } from '@mui/material'
import { CargoType, CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { getVisitDashboardInfoByOrders } from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { VisitDashboardDirectionCard } from './VisitDashboardDirectionCard'

interface Props {
  orders: IOrderItem[]
  cargoType?: CargoType
  visitId?: number
  visitType?: CarrierType
  cardFooter?: ReactNode
  isViewExtended?: boolean
  onViewClick: () => void
  onClickExportOrders?: (direction: CarrierVisitDirection) => void
}
export const OutboundDashboardCard = observer(
  ({
    orders,
    cargoType,
    visitId,
    visitType,
    cardFooter,
    isViewExtended,
    onViewClick,
    onClickExportOrders,
  }: Props) => {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { nnrOrderItemStore } = usePlanningStore()

    const { totalDefinition, archetypeGroups } = computed(() => {
      return getVisitDashboardInfoByOrders(orders)
    }).get()

    const nnrOrderSummary = _.get(nnrOrderItemStore.orderSummaryForVesselVisitId, visitId!)
    const handleAssignNnrOrder = () => {
      if (!!nnrOrderSummary && nnrOrderSummary.nnrOrders.length === 1) {
        navigate(`${PATH_PLANNING.nnrOrders}/${nnrOrderSummary.nnrOrders[0].id}`)
      } else {
        navigate(`${PATH_PLANNING.nnrOrdersPageForVisit}/${visitId}`)
      }
    }

    const getUnassignedReleasesAlertText = (totalAmount: number, totalUnassigned: number) => {
      return `${totalAmount} ${
        totalUnassigned > 1
          ? t('containerPlannedForNonNumericLoadidng', 'containers planned for non-numeric loading')
          : t('containerPlannedForNonNumericLoadidng', 'container planned for non-numeric loading')
      } (${totalUnassigned} ${t('notAssignedYet', 'not assigned yet')})`
    }

    return (
      <VisitDashboardDirectionCard
        title={t('load', 'Load')}
        cargoType={cargoType}
        totalDefinition={totalDefinition}
        direction={CarrierVisitDirection.Outbound}
        visitId={visitId}
        visitType={visitType}
        archetypeGroups={archetypeGroups}
        isViewExtended={isViewExtended}
        onViewClick={onViewClick}
        onClickExportOrders={
          onClickExportOrders
            ? () => onClickExportOrders(CarrierVisitDirection.Outbound)
            : undefined
        }
        additionalContent={
          <>
            {nnrOrderSummary?.totalUnassignedContainersForVisit > 0 && (
              <Alert
                data-cy='unassigned-nnr-orders-alert'
                severity='warning'
                action={
                  <Button color='inherit' size='small' onClick={handleAssignNnrOrder}>
                    {t('assign', 'Assign')}
                  </Button>
                }
              >
                {getUnassignedReleasesAlertText(
                  nnrOrderSummary.totalAmount,
                  nnrOrderSummary.totalUnassignedContainersForVisit,
                )}
              </Alert>
            )}
          </>
        }
        cardFooter={cardFooter}
      />
    )
  },
)
