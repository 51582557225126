import { CompanyResponseDto } from '@planning/app/api'
import companyService from '@planning/services/customerService'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetCompanyByIdQuery implements IQueryMessage {
  static type = 'GetCompanyByIdQuery'
  type = GetCompanyByIdQuery.type

  constructor(public readonly id: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

export const getCompanyByIdQueryHandlerHandler: IQueryHandler<
  GetCompanyByIdQuery,
  IEvent<CompanyResponseDto>
> = async query => {
  const data = await companyService.getById(query.id)

  return new Event(GetCompanyByIdQuery.type, data)
}
