import { Button, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { IOrderArchetypeGroupModel } from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { VisitOrdersArchetypeGroups } from './VisitOrdersArchetypeGroups'
import { VisitOrdersArchetypeGroupsDialog } from './VisitOrdersArchetypeGroupsDialog'

interface Props {
  groups: IOrderArchetypeGroupModel[]
  isMouseOver: boolean
  title: string
}

export const VisitOrdersArchetypeGroupsWrapper = observer(
  ({ groups, isMouseOver, title }: Props) => {
    const { t } = useTranslate()

    const boxRef = useRef<HTMLDivElement>(null)
    const [showShadow, setShowShadow] = useState(false)
    const [showDialog, setshowDialog] = useState(false)

    useEffect(() => {
      const checkOverflow = () => {
        if (!boxRef.current) return
        const { scrollWidth, clientWidth } = boxRef.current
        setShowShadow(scrollWidth > clientWidth)
      }

      checkOverflow()
      window.addEventListener('resize', checkOverflow)

      return () => {
        window.removeEventListener('resize', checkOverflow)
      }
    }, [groups])

    return (
      <>
        <Stack direction='row'>
          <Box
            ref={boxRef}
            position='relative'
            whiteSpace='nowrap'
            overflow='hidden'
            flex={1}
            sx={{
              '&:after': {
                content: `""`,
                position: 'absolute',
                top: 0,
                right: 0,
                width: '40px',
                height: '100%',
                background: showShadow
                  ? 'linear-gradient(to left, rgb(255 255 255) 50%, transparent)'
                  : 'none',
                pointerEvents: 'none',
              },
            }}
          >
            <VisitOrdersArchetypeGroups groups={groups} />
          </Box>

          {isMouseOver && showShadow && (
            <Button
              color='inherit'
              sx={{ flex: 0.5, whiteSpace: 'nowrap' }}
              onClick={() => setshowDialog(true)}
            >
              {t('showMore', 'Show more')}
            </Button>
          )}
        </Stack>

        <VisitOrdersArchetypeGroupsDialog
          open={showDialog}
          groups={groups}
          title={title}
          handleClose={() => setshowDialog(false)}
        />
      </>
    )
  },
)
