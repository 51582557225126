import { AppBar, InputBase, List, ListItem, ListItemText, Stack, Toolbar } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { SearchIcon } from '../../icons'
import { ColorSchema } from '../../palette'

interface Props {
  title?: string
  titleColor?: ColorSchema
  subTitle?: string
  enableSearchField?: boolean
  displaySearchIcon?: boolean
  defaultSearchFilter?: string
  searchFilter?: string
  searchInputType?: 'tel' | 'text'
  searchInputLabel?: string
  onSearchFieldChange?: (filter: string) => void
  leftRenderOption?: ReactNode
  rightRenderOption?: ReactNode
  filterTestId?: string
}

export const Header = ({
  title,
  titleColor,
  subTitle,
  enableSearchField,
  displaySearchIcon,
  defaultSearchFilter,
  searchFilter,
  searchInputType,
  searchInputLabel,
  onSearchFieldChange,
  leftRenderOption,
  rightRenderOption,
  filterTestId,
}: Props) => {
  const theme = useMinimalsTheme()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const scrollThreshold = 10

      setIsScrolled(scrollPosition > scrollThreshold)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AppBar
      position='sticky'
      style={{
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        boxShadow: isScrolled ? '0px 16px 32px -4px rgba(145, 158, 171, 0.16)' : 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          gap: 2,
          minHeight: '4rem',
          px: theme.customSpacing.m,
        }}
      >
        {leftRenderOption}

        {title && (
          <List
            sx={{
              width: '100%',
              ...(enableSearchField && {
                width: 'max-content',
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              }),
            }}
          >
            <ListItem disablePadding={!leftRenderOption}>
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  variant: subTitle ? 'subtitle2' : 'h3',
                  color: titleColor,
                }}
                secondary={subTitle}
                secondaryTypographyProps={{
                  variant: 'caption',
                  sx: {
                    [theme.breakpoints.down('md')]: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '400px',
                    },
                    [theme.breakpoints.down('sm')]: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px',
                    },
                  },
                }}
                data-cy='page-title'
              />
            </ListItem>
          </List>
        )}

        {enableSearchField && (
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
              ml: theme.customSpacing.xs,
              flex: 1,
              width: '100%',
            }}
          >
            {displaySearchIcon && (
              <SearchIcon sx={{ '*': { fill: theme.palette.secondary.main } }} />
            )}
            <InputBase
              data-cy={filterTestId}
              fullWidth
              type={searchInputType || 'tel'}
              defaultValue={defaultSearchFilter}
              value={searchFilter}
              placeholder={searchInputLabel}
              inputRef={input => input && input.focus()}
              onChange={e => onSearchFieldChange?.(e.target.value)}
            />
          </Stack>
        )}

        {rightRenderOption}
      </Toolbar>
    </AppBar>
  )
}
