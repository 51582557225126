import { Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { RangeGraphOption, RangeGraphOptionWithSize } from './range-graph-option'

interface Props {
  options: RangeGraphOption[]
}
export const RangeGraph = observer(({ options }: Props) => {
  const theme = useMinimalsTheme()

  const totalValue = useMemo(
    () => options.reduce((acc, option) => acc + (option.value ?? 0), 0),
    [options],
  )

  const getPercentage = useCallback(
    (value: number) => {
      return (value / totalValue) * 100
    },
    [totalValue],
  )

  const optionsWithSize = useMemo(() => {
    const newOptions: RangeGraphOptionWithSize[] = []
    let total = 0
    const count = options.filter(option => option.value).length

    options
      .sort((a, b) => (a.value > b.value ? 1 : -1))
      .filter(option => option.value)
      .forEach((option, index) => {
        let width = getPercentage(option.value)

        if (width <= 5) width = 5 //In case it is too small, it gives a mininum value

        if (count === index - 1) {
          width = 100 - total
        }

        total += width

        newOptions.push({ ...option, width })
      })

    return newOptions.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [getPercentage, options])

  return (
    <Stack
      flexDirection='row'
      sx={{ borderRadius: theme.customRadius.small, overflow: 'hidden', width: '100%' }}
    >
      {optionsWithSize.map(option => (
        <Box
          key={option.color}
          sx={{
            height: theme.customSpacing.l,
            backgroundColor: option.color,
            width: `${option.width}%`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='captionBold' sx={{ color: option.textColor }}>
            {Math.floor(getPercentage(option.value!))}%
          </Typography>
        </Box>
      ))}
    </Stack>
  )
})
