import { CarrierVisitStatus } from '@planning/app/api'
import { TenantStore } from '@planning/rt-stores/tenant/TenantStore'
import { TruckVisitAggregationStore } from '@planning/rt-stores/truckVisit/TruckVisitAggregationStore'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { truckAppointmentService, truckVisitService } from '@planning/services'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'

export enum TruckVisitIssues {
  missingPosition,
  hold,
  overdue,
}
export enum TruckVisitStatus {
  expected,
  arrived,
  departed,
  cancelled,
}

const timeIsBetweenFromTo = (from: Date, to: Date, date?: string | null) => {
  return moment(date).isBetween(moment(from), moment(to), 'm', '[]')
}

const filterByLicensePlate = (truckVisit: ITruckVisitItem, filter?: string) => {
  return filter
    ? truckVisit.truck?.data.licensePlate.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    : true
}

export class TruckVisitsViewStore {
  isOverdueDialogOpen = false
  isTimeRangeDialogOpen = false
  isCalendarPopperOpen = false
  isAdvancedFilterOpen = false

  filter = ''
  selectedStatus: TruckVisitStatus = TruckVisitStatus.expected
  selectedIssues: TruckVisitIssues[] = []
  timeRangeStart?: Date
  timeRangeEnd?: Date
  selectedDate?: Date

  sorting?: string
  filterBy: 'eta' | 'ata' | 'atd' = 'eta'

  openPlanContainerPositionDialog?: (orderId: number) => void

  constructor(
    private readonly tenantStore: TenantStore,
    private readonly truckVisitAggregationStore: TruckVisitAggregationStore,
  ) {
    makeObservable(this, {
      isTimeRangeDialogOpen: observable,
      isCalendarPopperOpen: observable,
      isOverdueDialogOpen: observable,
      isAdvancedFilterOpen: observable,
      timeRangeStart: observable,
      timeRangeEnd: observable,
      selectedDate: observable,
      selectedIssues: observable,
      filter: observable,
      selectedStatus: observable,
      sorting: observable,
      filterBy: observable,

      isFiltering: computed,
      truckOverdue: computed,
      isTimeRangeSet: computed,
      truckVisitFilteredByStatus: computed,
      truckVisitItems: computed,
      needsReservation: computed,
      fromFilter: computed,
      toFilter: computed,
      totalForSelectedStatus: computed,
      totalPerStatus: computed,
      arrivedTruckVisits: computed,
      arrivedTruckVisitsInDateRange: computed,
      expectedTruckVisits: computed,
      expectedTruckVisitsInDateRange: computed,
      departedTruckVisits: computed,
      departedTruckVisitsInDateRange: computed,
      filteredExpectedTruckVisitsInDateRange: computed,
      filteredArrivedTruckVisits: computed,
      filteredDepartedTruckVisitsInDateRange: computed,
      sortedTruckVisits: computed,
      expectedTruckVisitsForToday: computed,
      departedTruckVisitsForToday: computed,
      carrierVisitStatus: computed,

      toggleOverdueDialog: action,
      toggleCalendarPopper: action,
      toggleTimeRangeDialog: action,
      toggleAdvancedFilter: action,
      setTimeRange: action,
      setIssues: action,
      setSelectedDate: action,
      clearTimeRange: action,
      setTruckVisitStatus: action,
      setFilter: action,
      resetFilters: action,
      setSorting: action,
      setFilterBy: action,
      handleFilterChange: action,
      fetchVisits: action,
      deleteTruckAppointment: action,
      saveOverdueTime: action,
    })
  }

  async fetchVisits() {
    const truckVisitsWithOrders = await truckVisitService.getWithOrders(
      this.fromFilter,
      this.toFilter,
    )

    this.truckVisitAggregationStore.upsertVisitsToItemStores(truckVisitsWithOrders)
  }

  deleteTruckAppointment = async (truckVisitId: number) => {
    await truckAppointmentService.delete(truckVisitId)
  }

  toggleOverdueDialog = (isOpen: boolean) => {
    this.isOverdueDialogOpen = isOpen
  }

  toggleTimeRangeDialog = (isOpen: boolean) => {
    this.isTimeRangeDialogOpen = isOpen
  }

  toggleCalendarPopper = (isOpen: boolean) => {
    this.isCalendarPopperOpen = isOpen
  }

  toggleAdvancedFilter = (isOpen: boolean) => {
    this.isAdvancedFilterOpen = isOpen
  }

  clearTimeRange = () => {
    this.timeRangeStart = undefined
    this.timeRangeEnd = undefined
  }

  setTimeRange = (start: Date, end: Date) => {
    this.timeRangeStart = start
    this.timeRangeEnd = end

    this.fetchVisits()
  }

  setFilter = (filter: string) => {
    if (this.filter !== filter) {
      this.filter = filter
    }
  }

  setIssues = (issues: TruckVisitIssues[]) => {
    this.selectedIssues = issues
  }

  setSelectedDate = (date?: Date) => {
    this.selectedDate = date

    this.fetchVisits()
  }

  setTruckVisitStatus(status: TruckVisitStatus) {
    if (this.selectedStatus !== status) {
      this.selectedStatus = status

      if (this.totalForSelectedStatus !== this.truckVisitFilteredByStatus.length) {
        this.fetchVisits()
      }
    }
  }

  resetFilters() {
    this.setIssues([])
    this.setSelectedDate()
    this.clearTimeRange()
    this.setFilter('')
  }

  async saveOverdueTime(overdueValue: number) {
    await this.tenantStore.update(overdueValue)
  }

  get totalPerStatus() {
    return {
      expected: this.getTotalForStatus(TruckVisitStatus.expected),
      arrived: this.getTotalForStatus(TruckVisitStatus.arrived),
      departed: this.getTotalForStatus(TruckVisitStatus.departed),
      cancelled: this.getTotalForStatus(TruckVisitStatus.cancelled),
    }
  }

  get totalForSelectedStatus() {
    switch (this.selectedStatus) {
      case TruckVisitStatus.expected:
        return this.totalPerStatus.expected
      case TruckVisitStatus.arrived:
        return this.totalPerStatus.arrived
      case TruckVisitStatus.departed:
        return this.totalPerStatus.departed
      case TruckVisitStatus.cancelled:
        return this.totalPerStatus.cancelled
      default:
        return 0
    }
  }

  get truckVisitItems() {
    return _.values(this.truckVisitAggregationStore.truckVisits).filter(
      item => this.filterByDates(item) && this.filterByName(item) && this.filterByIssues(item),
    )
  }

  get truckVisitFilteredByStatus() {
    const isCancelled = this.selectedStatus === TruckVisitStatus.cancelled
    return this.truckVisitItems.filter(item =>
      this.filterByStatus(item, this.carrierVisitStatus, isCancelled),
    )
  }

  get needsReservation() {
    return !this.tenantStore.skipYardPlanning
  }

  get isFiltering() {
    return (
      !!this.filter || !!this.selectedIssues.length || !!this.selectedDate || this.isTimeRangeSet
    )
  }

  get truckOverdue() {
    return this.tenantStore.tenant?.truckVisitOverdueTime
  }

  get isTimeRangeSet() {
    return this.timeRangeStart && this.timeRangeEnd
  }

  get fromFilter() {
    const from = this.selectedDate ? new Date(this.selectedDate) : new Date()
    const hours = this.timeRangeStart?.getHours() ?? 0
    const minutes = this.timeRangeStart?.getMinutes() ?? 0

    from.setHours(hours, minutes, 0)

    return from
  }

  get toFilter() {
    const to = this.selectedDate ? new Date(this.selectedDate) : new Date()
    const hours = this.timeRangeEnd?.getHours() ?? 0
    const minutes = this.timeRangeEnd?.getMinutes() ?? 0

    if (hours === 0 && minutes === 0) {
      to.setDate(to.getDate() + 1)
    }
    to.setHours(hours, minutes, 0)

    return to
  }

  get carrierVisitStatus(): CarrierVisitStatus[] {
    return this.convertTruckVisitStatusToCarrierVisitStatus(this.selectedStatus)
  }

  private filterByStatus = (
    truckVisit: ITruckVisitItem,
    status: CarrierVisitStatus[],
    onlyCancelledVisits?: boolean,
  ) => {
    if (onlyCancelledVisits) {
      return truckVisit.data.isCancelled
    }

    return !truckVisit.data.isCancelled && status.includes(truckVisit.data.status)
  }

  private filterByName = (truckVisit: ITruckVisitItem) => {
    return (
      !this.filter ||
      truckVisit.data.identifier?.toLowerCase()?.includes(this.filter.toLowerCase()) ||
      truckVisit.driver?.data.name.toLowerCase()?.includes(this.filter.toLowerCase())
    )
  }

  private filterByIssues = (truckVisit: ITruckVisitItem) => {
    if (!this.selectedIssues.length) return true

    const hasMissingPosition =
      !this.selectedIssues.includes(TruckVisitIssues.missingPosition) ||
      (truckVisit.orders.some(o => o.hasMissingPosition) &&
        truckVisit.data.status === CarrierVisitStatus.Expected)

    const hasActiveHold =
      !this.selectedIssues.includes(TruckVisitIssues.hold) ||
      (truckVisit.orders.some(o => o.hasActiveHold) &&
        truckVisit.data.status === CarrierVisitStatus.Expected)

    const hasOverdue =
      !this.selectedIssues.includes(TruckVisitIssues.overdue) ||
      (this.isOverdueTime(truckVisit.data.ata) &&
        (truckVisit.data.status === CarrierVisitStatus.Arrived ||
          truckVisit.data.status === CarrierVisitStatus.InOperation))

    return hasMissingPosition && hasActiveHold && hasOverdue
  }

  private filterByDates = (truckVisit: ITruckVisitItem) => {
    const expectedDates =
      truckVisit.data.status === CarrierVisitStatus.Expected &&
      (this.isDateWithinSelectedRange(truckVisit.data.eta) ||
        this.isDateWithinSelectedRange(truckVisit.data.etd) ||
        this.isDateWithinRange(
          truckVisit.data.eta,
          truckVisit.data.etd,
          this.fromFilter.toUTCString(),
        ))

    const arrivedDates =
      (truckVisit.data.status === CarrierVisitStatus.Arrived ||
        truckVisit.data.status === CarrierVisitStatus.InOperation) &&
      this.isDateWithinSelectedRange(truckVisit.data.ata)

    const departedDates =
      (truckVisit.data.status === CarrierVisitStatus.Departed ||
        truckVisit.data.status === CarrierVisitStatus.Completed) &&
      this.isDateWithinSelectedRange(truckVisit.data.atd)

    return expectedDates || arrivedDates || departedDates
  }

  private isDateWithinSelectedRange = (date?: string | null) => {
    return this.isDateWithinRange(this.fromFilter.toUTCString(), this.toFilter.toUTCString(), date)
  }

  private isDateWithinRange = (min?: string | null, max?: string | null, date?: string | null) => {
    if (!date || !min || !max) return false

    const dateObj = new Date(date)
    return dateObj >= new Date(min) && dateObj <= new Date(max)
  }

  private isOverdueTime = (ata?: string | null) => {
    if (!this.truckOverdue || !ata) return false

    const ataDate = new Date(ata)
    const now = new Date()
    const diff = Math.abs(now.getTime() - ataDate.getTime())
    const minutes = Math.floor(diff / 1000 / 60)

    return minutes > this.truckOverdue
  }

  private getTotalForStatus(status: TruckVisitStatus) {
    const visitStatus = this.convertTruckVisitStatusToCarrierVisitStatus(status)
    const isCancelled = status === TruckVisitStatus.cancelled

    return this.truckVisitItems.reduce((acc, item) => {
      if (this.filterByStatus(item, visitStatus, isCancelled)) {
        return acc + 1
      }

      return acc
    }, 0)
  }

  private convertTruckVisitStatusToCarrierVisitStatus(
    status: TruckVisitStatus,
  ): CarrierVisitStatus[] {
    switch (status) {
      case TruckVisitStatus.arrived:
        return [CarrierVisitStatus.Arrived, CarrierVisitStatus.InOperation]
      case TruckVisitStatus.departed:
        return [CarrierVisitStatus.Departed, CarrierVisitStatus.Completed]
      case TruckVisitStatus.cancelled:
      case TruckVisitStatus.expected:
      default:
        return [CarrierVisitStatus.Expected]
    }
  }

  get sortedTruckVisits() {
    return _.values(this.truckVisitAggregationStore.truckVisits).sort(
      (a: ITruckVisitItem, b: ITruckVisitItem) => {
        const timestampA = a.data?.[this.filterBy]
        const timestampB = b.data?.[this.filterBy]
        const dateA = timestampA ? new Date(timestampA).getTime() : 0
        const dateB = timestampB ? new Date(timestampB).getTime() : 0

        return this.sorting === 'new' ? dateB - dateA : dateA - dateB
      },
    )
  }

  get expectedTruckVisits() {
    return this.sortedTruckVisits.filter(v => v.data.status === CarrierVisitStatus.Expected)
  }

  get expectedTruckVisitsInDateRange() {
    return this.expectedTruckVisits.filter(
      v =>
        !this.timeRangeStart ||
        !this.timeRangeEnd ||
        timeIsBetweenFromTo(this.timeRangeStart, this.timeRangeEnd, v.data.eta),
    )
  }

  get expectedTruckVisitsForToday() {
    return this.expectedTruckVisits
      .filter(v =>
        timeIsBetweenFromTo(
          moment().startOf('d').toDate(),
          moment().endOf('d').toDate(),
          v.data.eta,
        ),
      )
      .filter(v => filterByLicensePlate(v, this.filter))
  }

  get filteredExpectedTruckVisitsInDateRange() {
    return this.expectedTruckVisitsInDateRange.filter(v => filterByLicensePlate(v, this.filter))
  }

  get arrivedTruckVisits() {
    return this.sortedTruckVisits.filter(v => v.data.status === CarrierVisitStatus.Arrived)
  }

  get arrivedTruckVisitsInDateRange() {
    return this.arrivedTruckVisits.filter(
      v =>
        !this.timeRangeStart ||
        !this.timeRangeEnd ||
        timeIsBetweenFromTo(this.timeRangeStart, this.timeRangeEnd, v.data.ata),
    )
  }

  get filteredArrivedTruckVisits() {
    return this.arrivedTruckVisitsInDateRange.filter(v => filterByLicensePlate(v, this.filter))
  }

  get departedTruckVisits() {
    return this.sortedTruckVisits.filter(v => v.data.status === CarrierVisitStatus.Departed)
  }

  get departedTruckVisitsForToday() {
    return this.departedTruckVisits
      .filter(v =>
        timeIsBetweenFromTo(
          moment().startOf('d').toDate(),
          moment().endOf('d').toDate(),
          v.data.atd,
        ),
      )
      .filter(v => filterByLicensePlate(v, this.filter))
  }

  get departedTruckVisitsInDateRange() {
    return this.departedTruckVisits.filter(
      v =>
        !this.timeRangeStart ||
        !this.timeRangeEnd ||
        timeIsBetweenFromTo(this.timeRangeStart, this.timeRangeEnd, v.data.atd),
    )
  }

  get filteredDepartedTruckVisitsInDateRange() {
    return this.departedTruckVisitsInDateRange.filter(v => filterByLicensePlate(v, this.filter))
  }

  setSorting(sorting: string) {
    this.sorting = sorting
  }

  setFilterBy(filterBy: 'eta' | 'ata' | 'atd') {
    this.filterBy = filterBy
  }

  handleFilterChange = (value: any) => {
    const includeCompleted = true
    const isDescending = false

    const orderBy: 'eta' | 'ata' | 'atd' = 'ata'
    let filterBy: 'eta' | 'ata' | 'atd' = 'ata'

    if (['expectedToday', 'expectedLast7Days', 'expectedNext7Days'].includes(value)) {
      filterBy = 'eta'
    } else if (['departedToday', 'departedLast7Days'].includes(value)) {
      filterBy = 'atd'
    }

    this.setFilterBy(filterBy)

    if (['expectedToday', 'departedToday'].includes(value)) {
      const todayStartDate = moment().startOf('d')
      const todayEndDate = moment().endOf('d')
      this.setTimeRange(todayStartDate.toDate(), todayEndDate.toDate())
    } else if (value === 'onTerminal') {
      this.clearTimeRange()
    } else if (['expectedLast7Days', 'departedLast7Days'].includes(value)) {
      const sevenDaysAgoStartDate = moment().add(-7, 'd').startOf('d')
      const sevenDaysAgoEndDate = moment().endOf('d')
      this.setTimeRange(sevenDaysAgoStartDate.toDate(), sevenDaysAgoEndDate.toDate())
    } else if (value === 'expectedNext7Days') {
      const nextSevenDaysStartDate = moment().startOf('d')
      const nextSevenDaysEndDate = moment().add(7, 'd').endOf('d')
      this.setTimeRange(nextSevenDaysStartDate.toDate(), nextSevenDaysEndDate.toDate())
    }

    this.truckVisitAggregationStore.fetch(
      this.timeRangeStart,
      this.timeRangeEnd,
      includeCompleted,
      orderBy,
      isDescending,
      filterBy,
    )
  }
}
