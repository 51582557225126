import { Box, Button, Grid, Typography } from '@mui/material'
import { ContainerHeight, ContainerType, UnitType, UpsertContainerCommand } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { UnitTypeField } from './Molecules/UnitTypeField'
import { ContainerInfoForm } from './Organisms/ContainerInfoForm'
import { SwapBodyInfoForm } from './Organisms/SwapBodyInfoForm'
import { TrailerInfoForm } from './Organisms/TrailerInfoForm'

interface IProps {
  containerNumber: string
  onSubmit: (data: UpsertContainerCommand) => Promise<void> | void
}

export const AddUnitInfoForm: FC<IProps> = observer(({ onSubmit, containerNumber }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const methods = useForm<UpsertContainerCommand>({
    defaultValues: {
      number: containerNumber.toUpperCase(),
      unitType: UnitType.Container,
      type: ContainerType.Unknown,
      height: ContainerHeight.Standard,
    },
  })

  const selectedUnitType = methods.watch('unitType')

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Box sx={{ m: '1rem 0' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('addUnitInformation', 'Add Unit Information')}
            </Typography>
          </Grid>

          <FormProvider {...methods}>
            <UnitTypeField
              sx={{
                margin: theme.customSpacing.m,
                marginBottom: theme.customSpacing.xxs,
              }}
            />
            {selectedUnitType === UnitType.Container && <ContainerInfoForm />}
            {selectedUnitType === UnitType.SwapBody && <SwapBodyInfoForm />}
            {selectedUnitType === UnitType.Trailer && <TrailerInfoForm />}
          </FormProvider>

          <Grid item xs={12} textAlign='right'>
            <Button variant='contained' type='submit' data-cy='create-new-order-btn'>
              {t('createNewOrder', 'Create new order')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
})
