import { Box, Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { HeaderWithActions } from '@planning/components/HeaderWithActions'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { GatePassageResultAlert } from '../GateClerk/Components/GatePassageResultAlert'
import { TruckVisitsViewStore } from '../TruckVisits/Stores/TruckVisitsViewStore'
import { GateInDialog } from './Components/GateInDialog'
import { TabsList } from './Components/TabsList'

export const GateControlHome = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const {
    truckVisitItemStore,
    truckVisitAggregationStore,
    tenantStore,
    gateInViewStore,
    generalCargoViewStore,
    orderItemStore,
    containerItemStore,
  } = usePlanningStore()

  const truckVisitsViewStore = useMemo(
    () => new TruckVisitsViewStore(tenantStore, truckVisitAggregationStore),
    [tenantStore, truckVisitAggregationStore],
  )

  useEffect(() => {
    const todayStartDate = moment().startOf('d')
    const todayEndDate = moment().endOf('d')
    const includeCompleted = true
    const orderBy: 'eta' | 'ata' | 'atd' = 'ata'
    const isDescending = false

    console.log('Fetching truck visit aggregations')
    truckVisitAggregationStore.fetch(
      todayStartDate.toDate(),
      todayEndDate.toDate(),
      includeCompleted,
      orderBy,
      isDescending,
      'eta',
    )

    truckVisitAggregationStore.fetch(
      todayStartDate.toDate(),
      todayEndDate.toDate(),
      includeCompleted,
      orderBy,
      isDescending,
      'atd',
    )

    truckVisitAggregationStore.fetch(
      undefined,
      undefined,
      includeCompleted,
      orderBy,
      isDescending,
      'eta',
      true,
    )
  }, [truckVisitAggregationStore])

  useAsyncFetch(async () => {
    const ids = truckVisitItemStore.truckVisitIds

    await orderItemStore.fetchByVisitIds([...ids])
    await containerItemStore.fetchByVisitIds([...ids])
  }, [truckVisitItemStore.truckVisitIds, orderItemStore, containerItemStore])

  const onClickGateIn = async () => {
    await generalCargoViewStore.getPackages()
    gateInViewStore.setIsTruckAppointment(false)
    gateInViewStore.toggleGateInDialog(true)
  }

  const onClickTruckAppointment = async () => {
    await generalCargoViewStore.getPackages()
    gateInViewStore.setIsTruckAppointment(true)
    gateInViewStore.toggleGateInDialog(true)
  }

  return (
    <Box
      sx={{ backgroundColor: theme.palette.grey[200], paddingBottom: '16px', minHeight: '100%' }}
    >
      <HeaderWithActions
        title={t('gateControl', 'Gate Control')}
        actions={[
          <Button
            key='createAppointment'
            variant='outlined'
            size='large'
            onClick={onClickTruckAppointment}
          >
            {t('createAppointmentButton', 'Create appointment')}
          </Button>,
          <Button
            data-cy='gate-control-gate-in-btn'
            key='gateInButton'
            variant='contained'
            size='large'
            onClick={onClickGateIn}
          >
            {t('gateIn', 'Gate In')}
          </Button>,
        ]}
      />

      <Box
        sx={{
          padding: `0 ${theme.customSpacing.l}`,
        }}
      >
        <TabsList viewStore={truckVisitsViewStore} />

        <GateInDialog store={gateInViewStore} />
        <GatePassageResultAlert store={gateInViewStore.notificationStore} />
      </Box>
    </Box>
  )
})
