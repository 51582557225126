import { Stack, Typography } from '@mui/material'
import { TruckVisitWithOrdersDto } from '@planning/app/api'
import { HighlightedText, useMinimalsTheme, UsersIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  truckVisit: TruckVisitWithOrdersDto
  isSearch?: boolean
  highlight?: string
}
export const TruckVisitDriver = observer(({ truckVisit, isSearch, highlight }: Props) => {
  const theme = useMinimalsTheme()

  if (!truckVisit.driver?.driverID) return <></>

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <UsersIcon />
      {isSearch ? (
        <Typography variant='subtitle1'>
          <HighlightedText highlight={highlight} color={theme.palette.info.main}>
            {truckVisit.driver.driverID}
          </HighlightedText>
        </Typography>
      ) : (
        <Stack>
          <Typography variant='body2'>{truckVisit.driver.name}</Typography>
          <Typography variant='caption' color='secondary'>
            {truckVisit.driver.driverID}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
})
