import {
  CarrierType,
  CarrierVisitDirection,
  ContainerResponseDto,
  OrderResponseDto,
  TruckVisitDto,
} from '@planning/app/api'
import { IGateOutFormData } from '@planning/pages/GateClerk'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { carrierVisitService } from '@planning/services'
import { action, makeObservable, observable } from 'mobx'
import { IGateClerkViewStore } from './GateClerkViewStore'
import { GateOperationViewStore } from './GateOperationViewStore'

export class GateOutViewStore extends GateOperationViewStore {
  visit: TruckVisitDto | undefined
  gateOutFormData?: IGateOutFormData
  allOrders: IInspectContainerFormData[] = []
  orders: IInspectContainerFormData[] = []
  deletedOrderIds: number[] = []
  checkedOrders: IInspectContainerFormData[] = []
  notFinishedInboundOrders: IInspectContainerFormData[] = []
  notDroppedInboundOrders: IInspectContainerFormData[] = []

  constructor(public parentStore: IGateClerkViewStore) {
    super()

    makeObservable(this, {
      visit: observable,
      orders: observable,
      deletedOrderIds: observable,
      checkedOrders: observable,
      notFinishedInboundOrders: observable,
      notDroppedInboundOrders: observable,

      processOrders: action,
      setVisit: action,
      upsertOrder: action,
      deleteOrder: action,
      setCheckedOrder: action,
      toggleNotDroppedInboundOrder: action,
      reset: action,
    })
  }

  setVisit = (visit: TruckVisitDto) => {
    this.visit = visit
  }

  setGateOutFormData = (data: IGateOutFormData) => {
    this.gateOutFormData = data
  }

  upsertOrder = (order: IInspectContainerFormData) => {
    this.orders = [...this.orders.filter(o => o.id !== order.id), order]
  }

  deleteOrder = (orderId: number) => {
    this.deletedOrderIds.push(orderId)
    this.orders = [...this.orders.filter(o => o.id !== orderId)]
    this.checkedOrders = [...this.checkedOrders.filter(o => o.id !== orderId)]
  }

  setCheckedOrder = (order: IInspectContainerFormData) => {
    this.orders = [...this.orders.filter(o => o.id !== order.id)]
    this.checkedOrders = [...this.checkedOrders.filter(o => o.id !== order.id), order]
  }

  toggleNotDroppedInboundOrder = (orderId: number) => {
    if (this.notDroppedInboundOrders.some(x => x.id === orderId)) {
      this.notDroppedInboundOrders = this.notDroppedInboundOrders.filter(x => x.id !== orderId)
    } else {
      const inboundOrder = this.notFinishedInboundOrders.find(x => x.id === orderId)

      if (inboundOrder) this.notDroppedInboundOrders.push(inboundOrder)
    }
  }

  reset = () => {
    this.resetDialogs()
    this.visit = undefined
    this.orders = []
    this.checkedOrders = []
    this.deletedOrderIds = []
    this.gateOutFormData = undefined
  }

  fetch = async () => {
    if (!this.visit) return

    // [JH] We could hook this one up to the item stores
    const orders = await carrierVisitService.orders(this.visit.id)
    const containers = await carrierVisitService.containers(this.visit.id)

    this.processOrders(orders, containers)
  }

  processOrders(orders: OrderResponseDto[], containers: ContainerResponseDto[]) {
    this.allOrders = orders.map(o => ({
      ...o,
      carrierType: o.carrierVisitType ?? CarrierType.Truck,
      damages: containers.find(c => c.number === o.containerNumber)?.damages ?? [],
    }))

    orders
      .filter(o => o.direction === CarrierVisitDirection.Outbound)
      .forEach(o =>
        this.upsertOrder({
          ...o,
          carrierType: o.carrierVisitType ?? CarrierType.Truck,
          damages: containers.find(c => c.number === o.containerNumber)?.damages ?? [],
        }),
      )

    this.notFinishedInboundOrders = this.allOrders.filter(
      o => o.direction === CarrierVisitDirection.Inbound && !o.isJobFinished,
    )
  }
}
