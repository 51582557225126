import {
  CarrierVisitDirection,
  ContainerDamageResponseDto,
  CreateContainerOrderDto,
  CreateTruckAppointmentCommand,
  TruckAppointmentDto,
  UpdateOrderCommand,
} from '@planning/app/api'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { IGateInFormData } from '@planning/pages/GateClerk/GateInForm'
import { IGateOutFormData } from '@planning/pages/GateClerk/GateOutForm'
import {
  containerService,
  nnrOrderService,
  orderService,
  truckAppointmentService,
  truckVisitService,
} from '@planning/services'
import { action, makeObservable, observable } from 'mobx'
import { CancellableTimeoutStore } from './CancellableTimeoutStore'
import { GetEasiestContainerToReachFunc, SuggestedContainerDto } from './SuggestedContainerDto'
export class GatePassageNotificationStore extends CancellableTimeoutStore {
  licensePlate?: string
  direction?: CarrierVisitDirection
  isTruckAppointment?: boolean = false
  getEasiestContainerToReachFunc?: GetEasiestContainerToReachFunc
  constructor(protected delay = 5000) {
    super(delay)
    makeObservable(this, {
      licensePlate: observable,
      direction: observable,
      isTruckAppointment: observable,
      setLicensePlate: action,
      setDirection: action,
      setIsTruckAppointment: action,
    })
  }

  setLicensePlate = (licensePlate?: string) => {
    this.licensePlate = licensePlate
  }

  setDirection = (direction?: CarrierVisitDirection) => {
    this.direction = direction
  }

  setIsTruckAppointment = (allowGateIn?: boolean) => {
    this.isTruckAppointment = allowGateIn
  }

  createGateInRequest = async (
    data: IGateInFormData,
    allowGateIn = true,
  ): Promise<TruckAppointmentDto> => {
    this.setGateInData(data, allowGateIn)

    return this.createRequest(() => this.handleGateInRequest(data, allowGateIn))
  }

  submitGateInData = async (data: IGateInFormData, allowGateIn = true) => {
    this.setGateInData(data, allowGateIn)
    await this.handleGateInRequest(data, allowGateIn)
  }

  setGateInData = (data: IGateInFormData, allowGateIn = true) => {
    this.setLicensePlate(data.truckPlate)
    this.setDirection(CarrierVisitDirection.Inbound)
    this.setIsTruckAppointment(!allowGateIn)
  }

  //TODO: Refactor once we remove truck-appointment-improvement Feature Flag
  public async handleGateInRequest(
    data: IGateInFormData,
    allowGateIn = true,
  ): Promise<TruckAppointmentDto> {
    try {
      return await this.gateInTruck(data, allowGateIn)
    } catch (error: any) {
      console.log('Error while gating in truck', error)
      this.setRequestErrorMessage('Failed to allow passage')
      throw error
    }
  }

  deleteTruckAppointment = async (truckVisitId: number) => {
    await truckAppointmentService.delete(truckVisitId)
  }

  mapDropOffOrderToCreateContainerOrderDto = (
    dropOff: IInspectContainerFormData,
  ): CreateContainerOrderDto => ({
    direction: CarrierVisitDirection.Inbound,
    containerIsoCode: dropOff.containerIsoCode,
    containerNumber: dropOff.containerNumber,
    containerAttributes: {
      length: dropOff.containerLength,
      height: dropOff.containerHeight,
      type: dropOff.containerType,
    },
    carrierVisitId: null,
    linkedOrderId: dropOff.id,
    containerMaxGross: dropOff.containerMaxGross,
    containerTare: dropOff.containerTare,
    grossWeight: dropOff.grossWeight,
    imoClasses: dropOff.imoClasses,
    isEmpty: dropOff.isEmpty,
    operator: dropOff.operator,
    referenceNumber: dropOff.referenceNumber,
    temperature: dropOff.temperature,
    isAccidentalDischarge: false,
    isToBeStoredInYard: true,
    isDeliveryByTruck: true,
    damages: dropOff.damages,
    hasSeals: dropOff.hasSeals,
    seals: dropOff.seals,
    isOOG: dropOff?.oog?.isOog,
    overWidthRight: dropOff?.oog?.overWidthRight,
    overWidthLeft: dropOff?.oog?.overWidthLeft,
    overLengthRear: dropOff?.oog?.overLengthRear,
    overLengthFront: dropOff?.oog?.overLengthFront,
    overHeight: dropOff?.oog?.overHeight,
    unitLabel: dropOff?.unitLabel,
  })

  mapPickUpOrderToCreateContainerOrderDto = (
    pickUp: IInspectContainerFormData,
  ): CreateContainerOrderDto => ({
    direction: CarrierVisitDirection.Outbound,
    containerIsoCode: pickUp.containerIsoCode,
    containerNumber: pickUp.containerNumber,
    containerAttributes: {
      length: pickUp.containerLength,
      height: pickUp.containerHeight,
      type: pickUp.containerType,
    },
    carrierVisitId: null,
    linkedOrderId: pickUp.id,
    grossWeight: pickUp.grossWeight,
    containerMaxGross: pickUp.containerMaxGross,
    containerTare: pickUp.containerTare,
    imoClasses: pickUp.imoClasses,
    isEmpty: pickUp.isEmpty,
    operator: pickUp.operator,
    referenceNumber: pickUp.referenceNumber,
    temperature: pickUp.temperature,
    isAccidentalDischarge: false,
    isToBeStoredInYard: true,
    damages: pickUp.damages,
    hasSeals: pickUp.hasSeals,
    seals: pickUp.seals,
    isOOG: pickUp?.oog?.isOog,
    overWidthRight: pickUp?.oog?.overWidthRight,
    overWidthLeft: pickUp?.oog?.overWidthLeft,
    overLengthRear: pickUp?.oog?.overLengthRear,
    overLengthFront: pickUp?.oog?.overLengthFront,
    overHeight: pickUp?.oog?.overHeight,
    unitLabel: pickUp?.unitLabel,
  })

  createGateOutRequest = async (data: IGateOutFormData) => {
    this.setLicensePlate(data.truckPlate)
    this.setDirection(CarrierVisitDirection.Outbound)

    this.createRequest(async () => {
      try {
        const orders = await this.getOrdersWithDamage(data.checkOrders.filter(i => i.isEdited))

        const updateRequests = orders.map(this.updateOrder)
        await Promise.all(updateRequests)

        await truckVisitService.allowExit({
          id: data.visitId,
          orderIds: data.checkOrders.filter(o => o.id).map(o => o.id!),
          notDroppedInboundOrderIds: data.notDroppedInboundOrders.map(o => o.id),
        })

        this.reset()
      } catch (error: any) {
        this.setRequestErrorMessage('Failed to allow passage')
      }
    })
  }

  private updateOrder = async (order: IInspectContainerFormData) => {
    if (order.id && order.containerNumber && order.containerIsoCode) {
      const request: UpdateOrderCommand = {
        id: order.id,
        direction: order.direction,
        carrierType: order.carrierType,
        containerNumber: order.containerNumber,
        unitType: order.unitType,
        containerIsoCode: order.containerIsoCode,
        containerDamages: order.damages,
        isAccidentalDischarge: false,
        isToBeStoredInYard: true,
        imoClasses: order.imoClasses,
        isEmpty: order.isEmpty,
        operator: order.operator,
        referenceNumber: order.referenceNumber,
        temperature: order.temperature,
        grossWeight: order.grossWeight,
        containerMaxGross: order.containerMaxGross,
        hasSeals: order.hasSeals ?? false,
        seals: order.seals,
        containerTare: order.containerTare,
        isDamaged: !!order.damagesReported?.length,
        portOfDischarge: order.portOfDischarge,
        portOfLoading: order.portOfLoading,
        isOOG: order?.oog?.isOog,
        overWidthRight: order?.oog?.overWidthRight,
        overWidthLeft: order?.oog?.overWidthLeft,
        overLengthRear: order?.oog?.overLengthRear,
        overLengthFront: order?.oog?.overLengthFront,
        overHeight: order?.oog?.overHeight,
        unitLabel: order?.unitLabel,
      }

      await orderService.update(request)
    }
  }

  cancelRequest = () => {
    super.cancelRequest()

    setTimeout(async () => {
      this.reset()
    }, this.delay)
  }

  private mapBookingToCreateOrderDto = (booking: IInspectContainerFormData) => {
    const mapped: CreateContainerOrderDto = {
      grossWeight: booking.grossWeight,
      temperature: booking.temperature,
      hasSeals: booking.hasSeals,
      vgm: booking.vgm,
      containerNumber: booking.containerNumber,
      direction: CarrierVisitDirection.Inbound,
      linkedOrderId: booking.id,
      referenceNumber: booking.referenceNumber,
      imoClasses: booking.imoClasses,
      containerIsoCode: booking.containerIsoCode,
      containerMaxGross: booking.containerMaxGross,
      containerTare: booking.containerTare,
      isEmpty: booking.isEmpty,
      operator: booking.operator,
      damages: booking.damages,
      isOOG: booking.oog?.isOog,
      overWidthRight: booking.oog?.overWidthRight,
      overWidthLeft: booking.oog?.overWidthLeft,
      overLengthRear: booking.oog?.overLengthRear,
      overLengthFront: booking.oog?.overLengthFront,
      overHeight: booking.oog?.overHeight,
      seals: booking.seals,
      isToBeStoredInYard: true,
      isAccidentalDischarge: false,
      unitLabel: booking.unitLabel,
    }

    return mapped
  }

  public async gateInTruck(data: IGateInFormData, allowGateIn: boolean) {
    const { dropOffOrders, pickUpOrders, bookings } =
      await this.getDropOffAndPickUpOrdersWithDamage(data)

    const existingDropOffOrders = dropOffOrders.filter(
      o => o.direction === CarrierVisitDirection.Inbound,
    )
    const existingPickUpOrders = pickUpOrders.filter(
      o => o.direction === CarrierVisitDirection.Outbound,
    )
    const existingDropOffGcOrders =
      (data.generalCargoOrders &&
        data.generalCargoOrders.filter(o => o.direction === CarrierVisitDirection.Inbound)) ||
      []
    const existingPickUpGcOrders =
      (data.generalCargoOrders &&
        data.generalCargoOrders.filter(o => o.direction === CarrierVisitDirection.Outbound)) ||
      []

    const dropOffOrdersToBeCreated = dropOffOrders
      .filter(o => o.direction === CarrierVisitDirection.Outbound)
      .map(this.mapDropOffOrderToCreateContainerOrderDto)
    const pickUpOrdersToBeCreated = pickUpOrders
      .filter(o => o.direction === CarrierVisitDirection.Inbound)
      .map(this.mapPickUpOrderToCreateContainerOrderDto)
    const bookingsToBeCreated = bookings.map(this.mapBookingToCreateOrderDto)

    const existingOrders = [
      ...existingDropOffOrders,
      ...existingPickUpOrders,
      ...existingDropOffGcOrders,
      ...existingPickUpGcOrders,
    ]

    const updateRequests = existingOrders.filter(i => i.isEdited).map(this.updateOrder)
    await Promise.all(updateRequests)

    if (allowGateIn) await this.handleSuggestedContainers(data)

    const cmd: CreateTruckAppointmentCommand = {
      licensePlate: data.truckPlate,
      driverName: data.driverName,
      eta: data.eta,
      etd: data.etd,
      externalDriverId: data.externalDriverId,
      truckCompanyId: data.truckCompanyId,
      externalPortGatePassRef: data.externalPortGatePassRef,
      ordersWithDirection: existingOrders
        .filter(o => o.id)
        .map(o => ({
          id: o.id!,
          direction: o.direction!,
        })),
      ordersToBeCreated: [
        ...dropOffOrdersToBeCreated,
        ...pickUpOrdersToBeCreated,
        ...bookingsToBeCreated,
      ],
      nnrOrders: data.nnrOrders.map(nnr => {
        return {
          nnrOrderId: nnr.id,
          amountToPickUp: nnr.pickUpAmount,
          suggestedContainerIds: nnr.suggestedContainers?.map(c => c.containerId),
        }
      }),
    }

    const appointment = await this.upsertAppointment(data, cmd)

    if (allowGateIn) {
      await truckVisitService.allowEntry({
        id: appointment.truckVisitId,
        inboundOrderIds: appointment.orders
          .filter(o => o.carrierVisitDirection === CarrierVisitDirection.Inbound)
          .map(o => o.orderId),
        outboundOrderIds: appointment.orders
          .filter(o => o.carrierVisitDirection === CarrierVisitDirection.Outbound)
          .map(o => o.orderId),
      })
    }

    return appointment
  }

  public async handleSuggestedContainers(data: IGateInFormData) {
    if (!data.nnrOrders.length || !this.getEasiestContainerToReachFunc) return

    const sortedNnrOrders = [...data.nnrOrders].sort((a, b) => a.id - b.id)

    const matchingContainerResults = await Promise.allSettled(
      sortedNnrOrders.map(nnr => nnrOrderService.getAllSuitableContainers(nnr.id)),
    )

    const suggestedContainerResults = await Promise.allSettled(
      sortedNnrOrders.map((nnr, index) =>
        matchingContainerResults[index].status === 'fulfilled'
          ? this.getEasiestContainerToReachFunc?.(
              (matchingContainerResults[index] as PromiseFulfilledResult<number[]>).value,
              nnr.pickUpAmount,
            )
          : [],
      ),
    )

    sortedNnrOrders.forEach((nnr, index) => {
      const nnrOrder = data.nnrOrders.find(x => x.id === nnr.id)
      if (nnrOrder) {
        nnrOrder.suggestedContainers =
          suggestedContainerResults[index].status === 'fulfilled'
            ? (suggestedContainerResults[index] as PromiseFulfilledResult<SuggestedContainerDto[]>)
                .value
            : []
      }
    })
  }

  private async upsertAppointment(data: IGateInFormData, cmd: CreateTruckAppointmentCommand) {
    if (data.truckVisitId)
      return await truckAppointmentService.put({
        ...cmd,
        truckVisitId: data.truckVisitId,
      })

    return await truckAppointmentService.post(cmd)
  }

  reset() {
    super.reset()
    this.setLicensePlate()
    this.setDirection()
  }

  private async getOrdersWithDamage(orders: IInspectContainerFormData[]) {
    const ordersWithDamage = [...orders]

    const imgUploadRequests = ordersWithDamage
      ?.map((x, index) => ({
        index: index,
        request:
          x.containerNumber && x.damagesReported?.length
            ? containerService.getDamageDtoForUploadedDamages(x.containerNumber, x.damagesReported)
            : undefined,
      }))
      .filter(x => x.request)

    const imgUploadResults = await Promise.all(imgUploadRequests.map(x => x.request))

    ordersWithDamage.forEach((order, index) => {
      const imagRequestIndex = imgUploadRequests.findIndex(x => x.index === index)
      if (index >= 0) {
        order.damages =
          imgUploadResults[imagRequestIndex]?.map(x => x as ContainerDamageResponseDto) ?? []
      }
    })

    return ordersWithDamage
  }

  private async getDropOffAndPickUpOrdersWithDamage(data: IGateInFormData) {
    const dropOffOrders = [...data.dropOffOrders]
    const pickUpOrders = [...data.pickUpOrders]
    const bookings = [...data.bookings]

    const imgUploadRequests = dropOffOrders
      ?.map((x, index) => ({
        index: index,
        orderType: 'dropOff',
        request:
          x.containerNumber && x.damagesReported?.length
            ? containerService.getDamageDtoForUploadedDamages(x.containerNumber, x.damagesReported)
            : undefined,
      }))
      .filter(x => x.request)

    pickUpOrders.forEach((x, index) => {
      if (x.containerNumber && x.damagesReported?.length) {
        imgUploadRequests.push({
          index: index,
          orderType: 'pickUp',
          request:
            x.containerNumber && x.damagesReported?.length
              ? containerService.getDamageDtoForUploadedDamages(
                  x.containerNumber,
                  x.damagesReported,
                )
              : undefined,
        })
      }
    })

    bookings.forEach((booking, index) => {
      if (booking.containerNumber && booking.damagesReported?.length) {
        imgUploadRequests.push({
          index: index,
          orderType: 'booking',
          request:
            booking.containerNumber && booking.damagesReported?.length
              ? containerService.getDamageDtoForUploadedDamages(
                  booking.containerNumber,
                  booking.damagesReported,
                )
              : undefined,
        })
      }
    })

    const imgUploadResults = await Promise.all(imgUploadRequests.map(x => x.request))

    const mapDamageImageUploadResults = (
      order: IInspectContainerFormData,
      index: number,
      orderType: 'dropOff' | 'pickUp' | 'booking',
    ): void => {
      const imagRequestIndex = imgUploadRequests.findIndex(
        x => x.index === index && x.orderType === orderType,
      )
      if (index >= 0) {
        order.damages =
          imgUploadResults[imagRequestIndex]?.map(x => x as ContainerDamageResponseDto) ?? []
      }
    }

    dropOffOrders.forEach((x, index) => mapDamageImageUploadResults(x, index, 'dropOff'))
    pickUpOrders.forEach((x, index) => mapDamageImageUploadResults(x, index, 'pickUp'))
    bookings.forEach((x, index) => mapDamageImageUploadResults(x, index, 'booking'))

    return { dropOffOrders, pickUpOrders, bookings }
  }
}
