import { YardLocation } from '@planning/app/api'
import {
  ContainerJourneyStatus,
  ContainerJourneyStatusChip,
} from '@planning/components/molecules/ContainerJourneyStatusChip'
import { IContainerJourneyItem } from '@planning/pages/Issues/Stores/ContainerJourneyItem'
import { toYardLocationString } from '@planning/utils/container-utils'
import { ColoredLabel } from '@tom-ui/ui'

interface Props {
  containerJourney?: IContainerJourneyItem
  suggestedContainerYardLocations?: YardLocation[] | null
}

export const ContainerStatusChip = ({
  containerJourney,
  suggestedContainerYardLocations,
}: Props) => {
  const isNonNumeric = !containerJourney

  if (isNonNumeric && !!suggestedContainerYardLocations?.length) {
    const yardLocationString = toYardLocationString(suggestedContainerYardLocations)
    return <ColoredLabel color='success' label={yardLocationString} sx={{ width: 'max-content' }} />
  }

  return (
    <ContainerJourneyStatusChip
      status={containerJourney?.status ?? ContainerJourneyStatus.Expected}
      direction={containerJourney?.data.operationType}
      inboundCarrierType={containerJourney?.inboundOrder?.data?.carrierVisitType}
      currentPosition={containerJourney?.data.currentPosition}
      equipment={containerJourney?.data.equipmentType}
      yardLocations={containerJourney?.data.yardLocations}
    />
  )
}
