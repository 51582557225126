import { useAdminStore } from '@admin/AppProvider'
import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, PageHeader, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { FC, useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import { DeleteArchetypeConfirmationDialog } from './components/molecules/ArchetypeDeleteConfirmationDialog'
import { ArchetypeForm } from './components/molecules/ArchetypeForm'
import { useArchetypeMessagesHandler } from './hooks/useArchetypeMessagesHandler'
import { ArchetypeControlUIStore, ArchetypeFormData } from './stores/archetype-control.ui-store'

export const ArchetypeControlPage: FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const theme = useMinimalsTheme()
  const { appStore, archetypeStore, isoCodeMappingStore } = useAdminStore()
  const { getExceptionMessage, getSuccessMessage } = useArchetypeMessagesHandler()

  const archetypeUIStore = useMemo(
    () => new ArchetypeControlUIStore(archetypeStore),
    [archetypeStore],
  )

  const { id: archetypeId } = useParams()

  useAsyncFetch(async () => {
    try {
      const id = Number(archetypeId)

      if (id > 0) {
        archetypeUIStore.setId(id)
      }

      appStore.increaseLoadingCounter()
      await archetypeUIStore.archetypeStore.load()
    } finally {
      appStore.decreaseLoadingCounter()
    }
  }, [appStore, archetypeId, archetypeUIStore])

  useAsyncFetch(async () => {
    if (archetypeUIStore.selectedArchetype?.isoCodes?.length) {
      const attributes = await isoCodeMappingStore.getContainerCharacteristicsByIsoCodes(
        archetypeUIStore.selectedArchetype?.isoCodes,
      )

      archetypeUIStore.setCurrentAttributes(attributes)
    }
  }, [archetypeUIStore, isoCodeMappingStore])

  const editLabel = t('editArchetype', 'Edit archetype')
  const newLabel = t('createArchetype', 'Create archetype')

  const breadcrumbEditLabel = archetypeUIStore.selectedArchetype?.name ?? editLabel

  const title = archetypeId ? editLabel : newLabel
  const breadcrumbTitle = archetypeId ? breadcrumbEditLabel : newLabel

  const breadcrumbs = [
    { label: t('archetypes', 'Archetypes'), onClick: () => navigate('/registers/archetypes') },
    {
      label: breadcrumbTitle,
    },
  ]

  const onSubmit = async (data: ArchetypeFormData) => {
    try {
      await archetypeUIStore.onSubmit(data)
      appStore.setShowAlert('success', getSuccessMessage(!!archetypeId, data.name))
      navigate('/registers/archetypes')
    } catch (error) {
      appStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  const onRemoveArchetypeDuplicates = useCallback(
    async (archetypeId: number, archetypeName: string, isoCodes: string[]) => {
      try {
        await archetypeUIStore.onRemoveArchetypeDuplicates(archetypeId, archetypeName, isoCodes)
        appStore.setShowAlert('success', t('duplicatesRemoved', 'Duplicates removed'))
      } catch (error) {
        appStore.setShowAlert(
          'error',
          getExceptionMessage(error, { customIsoCodeFieldCannotBeEmptyMessage: true }),
        )
      }
    },
    [appStore, archetypeUIStore, getExceptionMessage, t],
  )

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbs} />
      <PageHeader
        pageTitle={title}
        formId='archetype-form'
        onBack={() => navigate('/registers/archetypes')}
        showDeleteButton={!!archetypeId}
        onDelete={() => archetypeUIStore.toggleDeleteDialog(true)}
      />
      <Box
        paddingX={theme.customSpacing.l}
        paddingY={theme.customSpacing.m}
        gap={theme.customSpacing.m}
        display={'flex'}
        flexDirection={'column'}
      >
        <ArchetypeForm
          viewStore={archetypeUIStore}
          onSubmit={onSubmit}
          onRemoveArchetypeDuplicates={onRemoveArchetypeDuplicates}
        />
      </Box>
      <DeleteArchetypeConfirmationDialog viewStore={archetypeUIStore} />
    </>
  )
}
