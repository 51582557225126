import { Card, CardContent, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { TruckVisitWithOrdersDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import {
  ArrowDownRightIcon,
  ArrowUpLeftIcon,
  ColoredLabel,
  HighlightedText,
  TruckIcon,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { FC } from 'react'
import { TruckVisitDriver } from './Atoms/TruckVisitDriver'
import { TruckVisitExpectedArrival } from './Atoms/TruckVisitExpectedArrival'

export enum TruckAppointmentSearchBy {
  TruckPlate = 'TruckPlate',
  DriverID = 'DriverID',
}

interface Props {
  truckVisitWithOrdersDto: TruckVisitWithOrdersDto
  onClick: (truckVisit: TruckVisitWithOrdersDto) => void
  searchBy: TruckAppointmentSearchBy
  highlight: string
}

export const TruckAppointmentSearchResult: FC<Props> = ({
  truckVisitWithOrdersDto,
  onClick,
  searchBy,
  highlight,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const outOfSchedule = () => {
    const now = new Date()
    if (truckVisitWithOrdersDto.eta) {
      const etaDate = new Date(truckVisitWithOrdersDto.eta)
      if (now.getTime() < etaDate.getTime()) {
        return false
      }
    }
    if (truckVisitWithOrdersDto.etd) {
      const etdDate = new Date(truckVisitWithOrdersDto.etd)
      if (now.getTime() > etdDate.getTime()) {
        return true
      }
    }
    return false
  }

  return (
    <Card
      sx={{
        width: '100%',
      }}
      onClick={() => onClick(truckVisitWithOrdersDto)}
      data-cy={`truck-visit-${truckVisitWithOrdersDto.identifier}`}
    >
      <CardContent>
        <Stack>
          <Stack direction={'row'} spacing={1} alignItems='center' justifyContent='space-between'>
            <Stack direction={'row'} spacing={1}>
              <TruckIcon />
              <Typography variant='subtitle1'>
                <HighlightedText
                  highlight={searchBy === TruckAppointmentSearchBy.TruckPlate ? highlight : ''}
                  color={theme.palette.info.main}
                >
                  {truckVisitWithOrdersDto.identifier}
                </HighlightedText>
              </Typography>
            </Stack>
            {outOfSchedule() && (
              <ColoredLabel color='warning' label={t('outOfSchedule', 'Out of schedule')} />
            )}
          </Stack>
          <>
            <TruckVisitDriver
              truckVisit={truckVisitWithOrdersDto}
              highlight={searchBy === TruckAppointmentSearchBy.DriverID ? highlight : ''}
              isSearch
            />

            {truckVisitWithOrdersDto.inboundOrders?.map((i, index) => (
              <Stack direction={'row'} spacing={1} key={i.id}>
                {index === 0 && <ArrowDownRightIcon />}
                <Typography paddingLeft={index === 0 ? undefined : 4} variant='body1'>
                  {i.containerNumber ?? t('non-Numeric', 'Non-numeric')}
                </Typography>
              </Stack>
            ))}

            {truckVisitWithOrdersDto.outboundOrders?.map((o, index) => (
              <Stack direction={'row'} spacing={1} key={o.id}>
                {index === 0 && <ArrowUpLeftIcon />}
                <Typography paddingLeft={index === 0 ? undefined : 4} variant='body1'>
                  {o.containerNumber ?? t('non-Numeric', 'Non-numeric')}
                </Typography>
              </Stack>
            ))}

            <TruckVisitExpectedArrival truckVisit={truckVisitWithOrdersDto} />
          </>
        </Stack>
      </CardContent>
    </Card>
  )
}
