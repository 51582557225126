import { FormControlLabel, Switch, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useState } from 'react'

interface Props {
  onChange: (value: boolean) => void
}
export const ExtendedViewSwitch = ({ onChange }: Props) => {
  const [value, setValue] = useState(false)
  const { t } = useTranslate()

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={(_, val) => {
            onChange(val)

            return setValue(val)
          }}
        />
      }
      label={<Typography variant='caption'>{t('extendedView', 'Extended view')}</Typography>}
    />
  )
}
