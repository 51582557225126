import { OrderResponseDto } from '@planning/app/api'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { action, makeObservable, observable } from 'mobx'
import { ValidateOutboundOrderFunc } from '../gateControl/ValidateOutboundDto'
import { NonNumericOrderWithPickUpAmount } from '../truckAppointment/TruckAppointmentDetailsViewStore'
import { IGateClerkViewStore } from './GateClerkViewStore'
import { GateOperationViewStore } from './GateOperationViewStore'

export class GateInViewStore extends GateOperationViewStore {
  licensePlate = ''
  dropOffOrders: IInspectContainerFormData[] = []
  pickUpOrders: IInspectContainerFormData[] = []
  nnrOrders: NonNumericOrderWithPickUpAmount[] = []

  validateOutboundRequest?: ValidateOutboundOrderFunc

  constructor(public parentStore: IGateClerkViewStore) {
    super()

    makeObservable(this, {
      licensePlate: observable,
      dropOffOrders: observable,
      pickUpOrders: observable,
      nnrOrders: observable,
      setLicensePlate: action,
      upsertDropOffOrder: action,
      upsertPickUpOrder: action,
      upsertNNROrder: action,
      deleteDropOffOrder: action,
      deletePickUpOrder: action,
      deleteNNROrder: action,
      reset: action,
    })
  }

  setLicensePlate = (licensePlate: string) => {
    this.licensePlate = licensePlate
  }

  upsertDropOffOrder = (order: IInspectContainerFormData) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== order.id), order]
  }

  deleteDropOffOrder = (orderId: number) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== orderId)]
  }

  upsertPickUpOrder = (order: IInspectContainerFormData) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== order.id), order]
  }

  deletePickUpOrder = (orderId: number) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== orderId)]
  }

  upsertNNROrderByOrder = (order: OrderResponseDto) => {
    let nnrOrder = this.nnrOrders.find(o => o.id === order.nonNumericOrderId)

    if (nnrOrder) {
      nnrOrder.pickUpAmount = nnrOrder.pickUpAmount + 1
    } else {
      nnrOrder = {
        id: order.nonNumericOrderId,
        referenceNumber: order.referenceNumber,
        pickUpAmount: 1,
      } as NonNumericOrderWithPickUpAmount
    }

    this.upsertNNROrder(nnrOrder)
  }

  upsertNNROrder = (order: NonNumericOrderWithPickUpAmount) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== order.id), order]
  }

  deleteNNROrder = (orderId: number) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== orderId)]
  }

  reset = () => {
    this.resetDialogs()
    this.licensePlate = ''
    this.dropOffOrders = []
    this.pickUpOrders = []
    this.nnrOrders = []
  }
}
