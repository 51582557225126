import { Box } from '@mui/system'
import { VesselVisitDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { DropOffVisit, PickUpVisit, TruckVisitCard } from '../TruckVisitCard'

export const TabItem: FC<{ item: ITruckVisitItem }> = observer(({ item }) => {
  const { vesselVisitItemStore, truckVisitItemStore, gateInViewStore } = usePlanningStore()

  const dropOffOrders: DropOffVisit[] = item.inboundOrders.map(orderItem => {
    const linkedOutboundOrder = orderItem.containerJourney?.outboundOrder

    // [Review] TODO: visit is only set if the order is referencing a vessel visit
    const visit = vesselVisitItemStore.getById(linkedOutboundOrder?.data?.carrierVisitId ?? 0)

    return {
      dropOffOrder: orderItem.data,
      theOtherDirectionVisitType: linkedOutboundOrder?.data?.carrierVisitType,
      theOtherDirectionStatus: linkedOutboundOrder?.data?.status,
      theOtherDirectionVisit: linkedOutboundOrder?.visit?.data as VesselVisitDto,
      theOtherDirectionCarriers: visit?.vessels ? visit.vessels.map(item => item.data) : [],
    }
  })

  const pickUpOrders: PickUpVisit[] = item.outboundOrders.map(item => {
    const visit: ITruckVisitItem =
      _.get(truckVisitItemStore.elements, item.data?.carrierVisitId ?? 0) ?? {}
    return {
      pickUpOrder: item.data,
      carriers: visit?.truck ? [visit.truck.data] : [],
    }
  })

  return (
    <Box mb='16px'>
      <TruckVisitCard
        viewStore={gateInViewStore}
        visit={item}
        dropOffOrders={dropOffOrders}
        pickUpOrders={pickUpOrders}
      />
    </Box>
  )
})
