import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { useTranslate } from '@tolgee/react'
import {
  CheckmarkIcon,
  CloseButton,
  EditIcon,
  EmptyIcon,
  FullIcon,
  IconButton,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { FC } from 'react'
import { IInspectContainerFormData } from './InspectContainer'
import { OperationTypeChip } from './OperationTypeChip'
interface Props {
  order: IInspectContainerFormData
  isContainerCheckRequired?: boolean
  onEdit?: (order: IInspectContainerFormData) => void
  onRemove?: (order: IInspectContainerFormData) => void
}

export const OrderListItem: FC<Props> = ({ order, isContainerCheckRequired, onEdit, onRemove }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '1rem',
      }}
    >
      <Box>
        <Stack display='flex' flexDirection={'row'}>
          {order.isChecked && (
            <CheckmarkIcon sx={{ color: theme.palette.success.main, mr: '.75rem' }} />
          )}
          <Typography variant='subtitle1'>{order.containerNumber}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <OperationTypeChip direction={order.direction} />
          {order.containerIsoCode && <OrderStatusChip status={order.containerIsoCode} />}
          {(order.isEmpty && <EmptyIcon />) || <FullIcon />}
        </Stack>
      </Box>

      <Box>
        {isContainerCheckRequired && !order.isChecked && onEdit && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => onEdit(order)}
            data-cy={`gate-${order.direction === 'Inbound' ? 'in' : 'out'}-check-order-${order.containerNumber}-btn`}
          >
            {t('check', 'Check')}
          </Button>
        )}

        {(!isContainerCheckRequired || order.isChecked) && onEdit && (
          <Tooltip title={t('edit', 'Edit')}>
            <IconButton sx={{ mr: '.5rem' }} onClick={() => onEdit(order)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        {onRemove && <CloseButton onClose={() => onRemove(order)} />}
      </Box>
    </Box>
  )
}
