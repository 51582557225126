import { CheckinContainerPage } from '@host/pages/CheckinContainer/CheckinContainerPage'
import { ContainerMasterDataWrapper } from '@host/pages/ContainerMasterData/ContainerMasterDataWrapper'
import { Dashboard } from '@host/pages/Dashboard/Dashboard'
import DGSettingsPage from '@host/pages/DGSettings/DGSettingsPage'
import { GateInFormDialogWrapper } from '@host/pages/GateControl/components/GateInDialogWrapper'
import { GeneralCargoOperator } from '@host/pages/GeneralCargoOperator/GeneralCargoOperator'
import { GeneralCargoOrdersPage } from '@host/pages/GeneralCargoOrders/GeneralCargoOrdersPage'
import { IsoCodeMappingWrapper } from '@host/pages/IsoCodeMapping/IsoCodeMappingWrapper'
import { Issue } from '@host/pages/Issues/Issue'
import { NNROrderControlWrapper } from '@host/pages/NNROrders/NNROrderControlWrapper'
import { OrdersPageWrapper } from '@host/pages/Orders/OrdersPageWrapper'
import { RailVisitDetails } from '@host/pages/RailVisit/RailVisitDetails'
import { ReeferMonitoringPage } from '@host/pages/ReeferMonitoring/ReeferMonitoringPage'
import { ServiceOrdersPage } from '@host/pages/ServiceOrders/ServiceOrdersPage'
import { TenantConfigurationPage } from '@host/pages/TenantConfiguration/TenantConfiguration'
import { TenantPage } from '@host/pages/Tenants/Tenants'
import { TruckVisitsPage } from '@host/pages/TruckVisits/TruckVisitsPage'
import { UpdateContainerByOperator } from '@host/pages/UpdateContainerByOperator/UpdateContainerByOperator'
import { ArchetypeControlPage, ArchetypeListPage, DamageCatalogue, Users } from '@tom-ui/admin'
import { CommoditiesAndPackaging, Companies, Reports } from '@tom-ui/billing'
import {
  CraneOperatorItemView,
  CraneOperatorLanding,
  CraneOperatorListView,
  CraneOperatorNoGeometryView,
  Equipment,
  EquipmentPlanning,
  EquipmentPlanningRail,
  OperatorLanding,
  ReeferOperator,
  WorkInstructions,
} from '@tom-ui/operations'
import {
  BookingsPage,
  CheckoutContainerPage,
  ContainerMasterDataPage,
  CreateOrdersPage,
  DeliveryOrdersPage,
  ExternalDriverFormPage,
  ExternalDriverPage,
  HandleOrderPage,
  IssuesPage,
  LocationCodesPage,
  MasterLocationCodesPage,
  NNROrderDetailsPage,
  NNROrdersPage,
  OrdersList,
  RailcarsPage,
  RailVisitPage,
  RailVisitPlanningPage,
  ReleaseOrdersPage,
  VesselPage,
  VesselServicePage,
  VesselVisitPlanningPage,
  VesselVisitsPage,
  VisitsPage,
} from '@tom-ui/planning'
import { OrdersList as OrdersList2 } from '@tom-ui/planning/pages/Order/components/Organisms'
import {
  AllocationRuleTemplatesPage,
  Berths,
  ContainerTurnovers,
  GeneralCargoAreas,
  GeneralCargoInventory,
  GlobalAllocationRules,
  OperatorContainerTurnoversList,
  Piers,
  RailTracksPage,
  WeightClassesPage,
  YardInconsistencies,
  YardManagement,
  YardPlanningDashboard,
  YardPlanningDashboardDetails,
  YardPlanningIssues,
  YardSetup,
} from '@tom-ui/storage'
import { ComponentType } from 'react'
import { EquipmentOperator } from '../pages/EquipmentOperator/EquipmentOperator'
import { GateClerkPage } from '../pages/GateClerk/GateClerkPage'
import { GateControl } from '../pages/GateControl/GateControl'
import Profile from '../pages/Profile/Profile'
import ResetDemo from '../pages/Reset/Reset'
import { TallyclerkPage } from '../pages/TallymanV2/TallyclerkPage'
import { VesselVisitDetails } from '../pages/VesslVisit/VesselVisitDetails'
import { PATHS } from './paths'
import Permission from './permission.enum'

export type RequiredFeatureFlag = {
  name: string
  enable: boolean
}

export interface RouteProps {
  path: string
  component?: ComponentType
  requiredPermission?: Permission
  requiredFeatureFlag?: RequiredFeatureFlag
  hidden?: boolean
}

interface Props {
  skipCraneSplit: boolean
  showUnifiedOperatorPage: boolean
  skipVesselTally: boolean
  skipRailTally: boolean
  hasGeneralCargo: boolean
  hasDamageCatalogue: boolean
  skipYardPlanning: boolean
  issuePageV2: boolean
  bookingOrderListV2: boolean
}

export const routes = ({
  skipCraneSplit,
  showUnifiedOperatorPage,
  skipVesselTally,
  skipRailTally,
  hasGeneralCargo,
  hasDamageCatalogue,
  skipYardPlanning,
  issuePageV2,
  bookingOrderListV2,
}: Props): RouteProps[] => [
  {
    path: PATHS.dashboard,
    component: Dashboard,
  },
  {
    path: PATHS.profile,
    component: Profile,
  },
  {
    path: PATHS.registers.reset,
    component: ResetDemo,
    requiredPermission: Permission.ResetData,
  },
  {
    path: PATHS.registers.vessels,
    component: VesselPage,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: PATHS.registers.containerMasterData,
    component: ContainerMasterDataPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.registers.containerMasterData}/new`,
    component: ContainerMasterDataWrapper,
    requiredPermission: Permission.WriteOrders,
  },
  {
    path: `${PATHS.registers.containerMasterData}/:id`,
    component: ContainerMasterDataWrapper,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: PATHS.registers.railcars,
    component: RailcarsPage,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: PATHS.registers.locationCodes,
    component: LocationCodesPage,
    requiredPermission: Permission.ReadLocationCodes,
    requiredFeatureFlag: { name: 'location-codes', enable: true },
  },
  {
    path: PATHS.registers.masterLocationCodes,
    component: MasterLocationCodesPage,
    requiredPermission: Permission.ResetData,
    requiredFeatureFlag: { name: 'location-codes', enable: true },
  },
  {
    path: PATHS.registers.archetypes,
    component: ArchetypeListPage,
    requiredPermission: Permission.ReadTenants,
  },
  {
    path: `${PATHS.registers.archetypes}/new`,
    component: ArchetypeControlPage,
    requiredPermission: Permission.ReadTenants,
  },
  {
    path: `${PATHS.registers.archetypes}/:id`,
    component: ArchetypeControlPage,
    requiredPermission: Permission.ReadTenants,
  },
  {
    path: PATHS.registers.isoCodeMappings,
    component: IsoCodeMappingWrapper,
    requiredPermission: Permission.ReadTenants,
  },
  {
    path: PATHS.jobs.reeferMonitoring,
    component: ReeferMonitoringPage,
  },
  {
    path: PATHS.registers.reports,
    component: Reports,
    requiredPermission: Permission.ReadBillableReport,
  },
  {
    path: PATHS.registers.companies,
    component: Companies,
    requiredPermission: Permission.ReadCompanies,
  },
  {
    path: PATHS.registers.users,
    component: Users,
    requiredPermission: Permission.ReadUsers,
  },
  {
    path: PATHS.registers.externalDrivers,
    component: ExternalDriverPage,
    requiredPermission: Permission.ReadWriteExternalDrivers,
  },
  {
    path: PATHS.registers.externalDriverForm,
    component: ExternalDriverFormPage,
    requiredPermission: Permission.ReadWriteExternalDrivers,
  },
  {
    path: PATHS.registers.tenants,
    component: TenantPage,
    requiredPermission: Permission.ReadAdminTenant,
  },
  {
    path: PATHS.registers.tenantConfiguration,
    component: TenantConfigurationPage,
    requiredPermission: Permission.ReadTenants,
  },
  {
    path: PATHS.vesselVisits,
    component: VesselVisitsPage,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: PATHS.visits,
    component: VisitsPage,
    requiredPermission: Permission.ReadVessels,
    requiredFeatureFlag: { name: 'visits-page', enable: true },
  },
  {
    path: PATHS.bookings,
    component: BookingsPage,
    requiredPermission: Permission.ReadOrders,
    requiredFeatureFlag: { name: 'enable-bookings-page', enable: true },
  },
  {
    path: `${PATHS.bookings}/:bookingNumber/visit/:visitId/orders`,
    component: bookingOrderListV2 ? OrdersList2 : OrdersList,
    requiredPermission: Permission.ReadOrders,
    requiredFeatureFlag: { name: 'enable-bookings-page', enable: true },
  },
  {
    path: `${PATHS.bookings}/:bookingNumber/visit/:visitId/orders/:orderId`,
    component: HandleOrderPage,
    requiredPermission: Permission.ReadOrders,
    requiredFeatureFlag: { name: 'enable-bookings-page', enable: true },
  },
  {
    path: PATHS.railVisits,
    component: RailVisitPage,
    // [OCTA-706] TODO: Permission
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: `/rail-visits/:id`,
    component: RailVisitDetails,
    // [OCTA-706] TODO: Permission
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: `rail-visits/:id/order/:orderId`,
    component: HandleOrderPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `rail-visits/:id/checkin/:orderId`,
    component: CheckinContainerPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `rail-visits/:id/checkout/:orderId`,
    component: CheckoutContainerPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `/rail-visits/:id/:page`,
    component: RailVisitDetails,
    // [OCTA-706] TODO: Permission
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: PATHS.gateControl,
    component: GateControl,
  },
  {
    path: PATHS.truckVisits,
    component: TruckVisitsPage,
  },
  {
    path: `${PATHS.truckVisits}/new`,
    component: GateInFormDialogWrapper,
    requiredPermission: Permission.WriteOrders,
  },
  {
    path: `${PATHS.truckVisits}/:id`,
    component: GateInFormDialogWrapper,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: PATHS.serviceOrders,
    component: ServiceOrdersPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.containerVisits}/:containerNumber?`,
    component: OrdersPageWrapper,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.containerVisits}/new`,
    component: CreateOrdersPage,
    requiredPermission: Permission.WriteOrders,
  },
  {
    path: `${PATHS.containerVisits}/:id/checkin/:orderId`,
    component: CheckinContainerPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.containerVisits}/:id/checkout/:orderId`,
    component: CheckoutContainerPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: PATHS.tallyclerk,
    component: TallyclerkPage,
    requiredPermission: Permission.ReadVessels,
    hidden: skipVesselTally && skipRailTally,
  },
  {
    path: PATHS.gateClerk,
    component: GateClerkPage,
  },
  {
    path: PATHS.deliveryOrders,
    component: DeliveryOrdersPage,
    requiredPermission: Permission.ReadOrders,
    requiredFeatureFlag: { name: 'drop-off-order', enable: true },
  },
  {
    path: PATHS.releaseOrders,
    component: ReleaseOrdersPage,
    requiredPermission: Permission.ReadOrders,
    requiredFeatureFlag: { name: 'pick-up-order', enable: true },
  },
  {
    path: PATHS.nnrOrders,
    component: NNROrdersPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: PATHS.nnrOrders,
    component: NNROrdersPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.nnrOrders}/new`,
    component: NNROrderControlWrapper,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.nnrOrders}/:nnrOrderId`,
    component: NNROrderControlWrapper,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.nnrOrders}/:nnrOrderId/details`,
    component: NNROrderDetailsPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `${PATHS.nnrOrdersPageForVisit}/:vesselVisitId`,
    component: NNROrdersPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: '/EquipmentPlanning/:id',
    component: EquipmentPlanning,
    requiredPermission: Permission.EquipmentsAssignment,
    hidden: skipCraneSplit,
  },
  {
    path: '/EquipmentPlanningRail/:id',
    component: EquipmentPlanningRail,
    requiredPermission: Permission.EquipmentsAssignment,
    hidden: skipCraneSplit,
  },
  {
    path: '/EquipmentPlanning/:id/:page',
    component: EquipmentPlanning,
    requiredPermission: Permission.ReadJobs,
    hidden: skipCraneSplit,
  },
  {
    path: PATHS.jobs.workInstructions,
    component: WorkInstructions,
    requiredPermission: Permission.ReadJobs,
  },
  {
    path: PATHS.crane,
    component: CraneOperatorLanding,
    requiredPermission: Permission.StartFinishJobs,
  },
  {
    path: '/operatorViews/Crane/BayView/List/:vesselVisitId/:equipmentId',
    component: CraneOperatorListView,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: '/operatorViews/Crane/BayView/Item/:vesselVisitId/:equipmentId/:bay/:operationType',
    component: CraneOperatorItemView,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: '/operatorViews/Crane/NoGeometryView/:vesselVisitId/:equipmentId',
    component: CraneOperatorNoGeometryView,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: PATHS.tt,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: PATHS.rtg,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: PATHS.rmg,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: PATHS.rs,
    requiredPermission: Permission.StartFinishJobs,
  },
  {
    path: PATHS.sts,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage || !skipVesselTally,
  },
  {
    path: PATHS.ech,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: PATHS.ech,
    requiredPermission: Permission.StartFinishJobs,
    hidden: showUnifiedOperatorPage,
  },
  {
    path: PATHS.all,
    requiredPermission: Permission.StartFinishJobs,
    hidden: !showUnifiedOperatorPage,
  },
  {
    path: PATHS.gc,
    component: GeneralCargoOperator,
    requiredPermission: Permission.StartFinishJobs,
    hidden: !hasGeneralCargo,
  },
  {
    path: PATHS.reeferOperator,
    component: ReeferOperator,
    requiredPermission: Permission.StartFinishJobs,
  },
  {
    path: '/Operator/:page',
    component: OperatorLanding,
    requiredPermission: Permission.StartFinishJobs,
  },
  {
    path: '/Operator/:page/:equipmentId',
    component: EquipmentOperator,
    requiredPermission: Permission.StartFinishJobs,
  },
  {
    path: PATHS.registers.equipments,
    component: Equipment,
    requiredPermission: Permission.ReadEquipments,
  },
  {
    path: '/vessel-visits-planning',
    component: VesselVisitPlanningPage,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: '/vessel-visits-planning/:id',
    component: VesselVisitPlanningPage,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: '/rail-visits-planning',
    component: RailVisitPlanningPage,
    requiredPermission: Permission.ReadRailVisits,
  },
  {
    path: '/rail-visits-planning/:id',
    component: RailVisitPlanningPage,
    requiredPermission: Permission.ReadRailVisits,
  },
  {
    path: '/vessel-visits/:id',
    component: VesselVisitDetails,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: `vessel-visits/:id/order/:orderId`,
    component: HandleOrderPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: '/vessel-visits/:id/:page',
    component: VesselVisitDetails,
    requiredPermission: Permission.ReadVessels,
  },
  {
    path: `vessel-visits/:id/checkin/:orderId`,
    component: CheckinContainerPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: `vessel-visits/:id/checkout/:orderId`,
    component: CheckoutContainerPage,
    requiredPermission: Permission.ReadOrders,
  },
  {
    path: PATHS.issues,
    component: issuePageV2 ? Issue : IssuesPage,
    requiredPermission: Permission.ReadDamageCatalogue,
  },
  {
    path: PATHS.generalCargoOrders,
    component: GeneralCargoOrdersPage,
    hidden: !hasGeneralCargo,
  },
  {
    path: PATHS.yard,
    component: YardManagement,
    requiredPermission: Permission.ReadYardBlocks,
  },
  {
    path: PATHS.layout.yardSetup,
    component: YardSetup,
    requiredPermission: Permission.ReadYardBlocks,
  },
  {
    path: PATHS.containerTurnovers,
    component: ContainerTurnovers,
  },
  {
    path: PATHS.yardPlanningIssues,
    component: YardPlanningIssues,
  },
  {
    path: PATHS.yardInconsistencies,
    component: YardInconsistencies,
  },
  {
    path: PATHS.yardPlanningDashboard.root,
    component: YardPlanningDashboard,
  },
  {
    path: PATHS.yardPlanningDashboard.details.root,
    component: YardPlanningDashboardDetails,
  },
  {
    path: PATHS.globalAllocationRules,
    component: GlobalAllocationRules,
  },
  {
    path: PATHS.layout.berths,
    component: Berths,
    requiredPermission: Permission.ReadBerths,
  },
  {
    path: PATHS.layout.piers,
    component: Piers,
    requiredPermission: Permission.ReadPiers,
  },
  {
    path: PATHS.layout.railTracks,
    component: RailTracksPage,
  },
  {
    path: PATHS.operatorContainerTurnoversList.root,
    component: OperatorContainerTurnoversList,
  },
  {
    path: PATHS.operatorContainerTurnoversList.positionUpdate.root,
    component: UpdateContainerByOperator,
  },
  {
    path: PATHS.generalCargoHub.generalCargoInventory,
    component: GeneralCargoInventory,
  },
  {
    path: PATHS.generalCargoHub.generalCargoAreas,
    component: GeneralCargoAreas,
  },
  {
    path: PATHS.registers.commoditiesAndPacking,
    component: CommoditiesAndPackaging,
    requiredPermission: Permission.ReadCommodities,
    hidden: !hasGeneralCargo,
  },
  {
    path: PATHS.registers.allocationRuleTemplates,
    component: AllocationRuleTemplatesPage,
    hidden: skipYardPlanning,
  },
  {
    path: PATHS.registers.weightClasses,
    component: WeightClassesPage,
    requiredPermission: Permission.ReadWeightClasses,
  },
  {
    path: PATHS.registers.damageCatalogue,
    component: DamageCatalogue,
    requiredPermission: Permission.ReadDamageCatalogue,
    hidden: !hasDamageCatalogue,
  },
  {
    path: PATHS.registers.dangerousGoodsSettings,
    component: DGSettingsPage,
  },
  {
    path: PATHS.registers.vesselServices,
    component: VesselServicePage,
    requiredPermission: Permission.ReadVessels,
    requiredFeatureFlag: { name: 'vessel-services', enable: true },
  },
]
