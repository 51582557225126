import { Stack, Typography } from '@mui/material'
import { IOrderArchetypeGroupModel } from '@planning/utils/order-utils'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  groups: IOrderArchetypeGroupModel[]
  wrap?: boolean
}

export const VisitOrdersArchetypeGroups = observer(({ groups, wrap }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Stack direction='row' gap={theme.customSpacing.s} flexWrap={wrap ? 'wrap' : 'nowrap'}>
      {groups.map(group => (
        <Stack key={group.name}>
          <Typography variant='body2' color='secondary'>
            {group.name ?? 'Unknown'}
          </Typography>
          <Typography variant='h4'>{group.total}</Typography>
        </Stack>
      ))}
    </Stack>
  )
})
