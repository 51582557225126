import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { CompanyResponseDto, CompanyType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import companyService from '@planning/services/customerService'
import { CustomerAutocompleteViewStore } from '@planning/stores/customer/CustomerAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { matchSorter } from 'match-sorter'
import { observer, useLocalStore } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

interface Props {
  selectedCustomer?: CompanyResponseDto | null
  handleOnChange?: (value?: CompanyResponseDto) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disable?: boolean
  required?: boolean
  filterByTypes?: CompanyType[]
  selectedCompanyShortName?: string | null
  selectedCompanyId?: number | null
  dataCy?: string
  hasNoneOption?: boolean
}

export const CustomerAutoComplete: FC<Props> = observer(
  ({
    selectedCustomer,
    handleOnChange,
    error,
    helperText,
    label,
    disable,
    required,
    filterByTypes,
    selectedCompanyShortName,
    selectedCompanyId,
    dataCy,
    hasNoneOption,
  }) => {
    const { t } = useTranslate()
    const { appViewStore } = usePlanningStore()
    const customerAutocompleteViewStore = useLocalStore(() => new CustomerAutocompleteViewStore())
    const [resolvedValue, setResolvedValue] = useState<CompanyResponseDto | null>(null)

    useEffect(() => {
      const fetchValue = async () => {
        if (selectedCustomer) {
          setResolvedValue(selectedCustomer)
          return
        }

        if (selectedCompanyId) {
          let company = customerAutocompleteViewStore.items.find(c => c.id === selectedCompanyId)
          if (!company) {
            company = await companyService.getById(selectedCompanyId)
          }
          setResolvedValue(company ? { ...company } : null)
          return
        }

        if (selectedCompanyShortName) {
          let company = customerAutocompleteViewStore.items.find(
            c => c.shortName === selectedCompanyShortName,
          )

          if (!company) {
            company = await companyService.getByShortName(selectedCompanyShortName)
          }
          setResolvedValue(company ? { ...company } : null)
          return
        }

        setResolvedValue(null)
      }

      fetchValue().catch(error => appViewStore.setShowAlert('error', error.message))
    }, [
      selectedCustomer,
      selectedCompanyId,
      selectedCompanyShortName,
      customerAutocompleteViewStore.items,
      appViewStore,
    ])

    useEffect(() => {
      const fetch = async () => {
        await customerAutocompleteViewStore.fetch(customerAutocompleteViewStore.filter)
      }

      fetch().catch(error => appViewStore.setShowAlert('error', error.message))
    }, [appViewStore, customerAutocompleteViewStore])

    const onSearchFilterChange = async (newFilter: string) => {
      customerAutocompleteViewStore.setFilter(newFilter)

      if (!newFilter.length) {
        await customerAutocompleteViewStore.fetch(newFilter)
      }
    }

    const handleOnClose = () => {
      customerAutocompleteViewStore.setOpen(false)
      if (!resolvedValue) {
        onSearchFilterChange('')
      }
    }
    const filterOptions = (
      options: CompanyResponseDto[],
      { inputValue }: { inputValue: string },
    ) => {
      return matchSorter(options, inputValue.replace(/\s/g, ''), {
        sorter: rankedItems => rankedItems,
        keys: [
          item => (item.shortName ? item.shortName.replace(/\./g, '').replace(/\s/g, '') : ''),
          item => item.name.replace(/\./g, '').replace(/\s/g, ''),
        ],
        threshold: matchSorter.rankings.STARTS_WITH,
      })
    }

    const getOptions = () => {
      let options = customerAutocompleteViewStore.items.filter(
        x => !filterByTypes?.length || x.types?.some(t => filterByTypes.includes(t)),
      )

      if (hasNoneOption) {
        options = [
          {
            id: -1,
            name: t('none', 'None'),
            referenceId: 0,
          },
          ...options,
        ]
      }

      return options
    }

    return (
      <Autocomplete
        disablePortal
        getOptionDisabled={option => !!option.isDeactivated}
        disabled={disable}
        getOptionLabel={customer => customer.name}
        open={customerAutocompleteViewStore.isOpen}
        onOpen={() => customerAutocompleteViewStore.setOpen(true)}
        onClose={handleOnClose}
        options={getOptions()}
        isOptionEqualToValue={(option: CompanyResponseDto, value: CompanyResponseDto) =>
          option.id === value.id
        }
        value={resolvedValue}
        onChange={(_, customer) => {
          if (handleOnChange) handleOnChange(customer ?? undefined)
        }}
        filterOptions={filterOptions}
        renderOption={(props, option) => (
          <Box component='li' {...props} key={`location_${option.id}`}>
            <Stack flexDirection='row'>
              <Typography>{`${option.name} ${option?.shortName ? `- ${option.shortName}` : ''}${option.isDeactivated ? ' (' + t('deactivated', 'Deactivated') + ')' : ''}`}</Typography>
            </Stack>
          </Box>
        )}
        renderInput={params => (
          <TextField
            required={required}
            {...params}
            data-cy={dataCy ?? 'customer-autocomplete'}
            label={label ?? t('customer', 'Customer')}
            error={error}
            helperText={helperText}
            onChange={e => {
              onSearchFilterChange(e.target.value)
            }}
          />
        )}
      />
    )
  },
)
