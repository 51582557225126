import {
  CarrierVisitDirection,
  CarrierVisitStatus,
  ContainerJourneyDto,
  OrderResponseDto,
  OrderStatus,
  TruckVisitDto,
} from '@planning/app/api'
import { IListStore, SimpleListStore } from '@planning/components/list/SimpleListStore'
import { TenantStore } from '@planning/rt-stores/tenant/TenantStore'
import { orderService, truckVisitService } from '@planning/services'
import generalCargoService from '@planning/services/generalCargoService'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { GateInViewStore } from './GateInViewStore'
import { GateOutViewStore } from './GateOutViewStore'
import { GatePassageNotificationStore } from './GatePassageNotificationStore'
import { PickUpOrderSearchStore } from './PickUpOrderSearchStore'

export interface IGateClerkViewStore {
  operation: CarrierVisitDirection
  gateInViewStore: GateInViewStore
  gateOutViewStore: GateOutViewStore
  dropOffOrderSearchStore: IListStore<ContainerJourneyDto>
  pickUpOrderSearchStore: PickUpOrderSearchStore
  arrivedTruckVisitSearchStore: IListStore<TruckVisitDto>
  notificationStore: GatePassageNotificationStore
  ordersByContainerNumber: OrderResponseDto[]

  setOperation: (operation: CarrierVisitDirection) => void
  validateAllocationSpace?: (inboundOrderIds: number[]) => Promise<number[]>
}

//TODO ContainerJourneyDTO does not reflect the ContainerJourney, only the linked orders. Avoid using it
export interface GateClerkSearchResultDto extends ContainerJourneyDto {
  invalidAllocationSpace?: boolean
}

export class GateClerkViewStore implements IGateClerkViewStore {
  onSearch = async (filter?: string) => {
    if (filter) {
      const filteredDropoffOrders = await this.getFilteredDropoffOrders(filter)
      const ordersWithAllocationRules = await this.validateOrderAllocations(filteredDropoffOrders)

      return ordersWithAllocationRules
    }

    return []
  }

  getFilteredDropoffOrders = async (filter: string) => {
    const dropOffOrders = await orderService.dropOffOrdersContainerJourney(filter)
    let generalCargoOrders: ContainerJourneyDto[] = []
    if (this.tenantStore.hasGeneralCargo) {
      generalCargoOrders = (await generalCargoService.getByCustomerOrReferenceNumber(
        filter,
        CarrierVisitDirection.Inbound,
      )) as ContainerJourneyDto[]
    }

    this.ordersByContainerNumber = await orderService.getByContainerNumber(filter, OrderStatus.Open)
    const filteredData: Record<string, ContainerJourneyDto> = {}

    dropOffOrders.forEach((item: ContainerJourneyDto) => {
      if (item.containerNumber) {
        if (filteredData[item.containerNumber]) {
          if (item.direction === 'Inbound' && item.linkedOrder !== null) {
            filteredData[item.containerNumber] = item
          }
        } else {
          filteredData[item.containerNumber] = item
        }
      }
    })

    const result: GateClerkSearchResultDto[] = _.union(
      Object.values(filteredData),
      generalCargoOrders,
    )

    return result
  }

  validateOrderAllocations = async (orders: GateClerkSearchResultDto[]) => {
    try {
      const orderIdsWithNoAllocationSpace = await this.fetchInvalidAllocationSpaceIds(
        orders.map(order => order.id),
      )

      orders.forEach(
        order => (order.invalidAllocationSpace = orderIdsWithNoAllocationSpace.includes(order.id)),
      )

      return orders
    } catch (error) {
      console.error(error)
    }

    return []
  }

  operation: CarrierVisitDirection = 'Inbound'
  ordersByContainerNumber: OrderResponseDto[] = []
  gateInViewStore: GateInViewStore
  gateOutViewStore: GateOutViewStore
  dropOffOrderSearchStore = new SimpleListStore(this.onSearch)
  pickUpOrderSearchStore: PickUpOrderSearchStore
  arrivedTruckVisitSearchStore = new SimpleListStore(
    async (filter?: string) =>
      await truckVisitService.get(1, 20, CarrierVisitStatus.Arrived, filter),
  )
  notificationStore = new GatePassageNotificationStore()

  validateAllocationSpace?: (inboundOrderIds: number[]) => Promise<number[]>

  constructor(private tenantStore: TenantStore) {
    makeObservable(this, {
      operation: observable,
      setOperation: action,
      ordersByContainerNumber: observable,
    })

    this.pickUpOrderSearchStore = new PickUpOrderSearchStore(this.tenantStore)
    this.gateInViewStore = new GateInViewStore(this)
    this.gateOutViewStore = new GateOutViewStore(this)
  }

  setOperation = (operation: CarrierVisitDirection) => {
    this.operation = operation
  }

  fetchInvalidAllocationSpaceIds = async (inboundOrderIds: number[]) => {
    if (!this.validateAllocationSpace || !inboundOrderIds.length) return []

    const result = await this.validateAllocationSpace(inboundOrderIds)

    return result
  }
}
