import { Box, Container, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { ContainerStackOutFunc } from '@planning/stores/gateControl/ContainerStackOutDto'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { IControlledIsoCodeMappingInputProps } from '../Order/stores/IsoCodeMappingInputStore'
import { TruckVisitHeader } from './Components/Organisms/TruckVisitHeader'
import { TruckVisitsDialogs } from './Components/Organisms/TruckVisitsDialogs'
import { TruckVisitTable } from './Components/Organisms/TruckVisitTable'
import { TruckVisitsViewStore } from './Stores/TruckVisitsViewStore'

interface Props {
  containerStackOutFunc?: ContainerStackOutFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc
  validateAllocationSpace?: (inboundOrderIds: number[]) => Promise<number[]>
  openPlanContainerPositionDialog?: (orderId: number) => void
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}
export const TruckVisits = observer(
  ({
    containerStackOutFunc,
    renderIsoCodeMappingInput,
    validateOutboundRequest,
    validateAllocationSpace,
    openPlanContainerPositionDialog,
  }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const {
      tenantStore,
      gateInViewStore,
      generalCargoViewStore,
      containerDamageReportViewStore,
      isoCodeMappingInputStore,
      truckVisitAggregationStore,
    } = usePlanningStore()

    const store = useMemo(
      () => new TruckVisitsViewStore(tenantStore, truckVisitAggregationStore),
      [tenantStore, truckVisitAggregationStore],
    )

    const { loading, error } = useAsyncFetch(async () => {
      await Promise.all([
        store.fetchVisits(),
        generalCargoViewStore.getPackages(),
        containerDamageReportViewStore.fetch(),
      ])
    }, [store, containerDamageReportViewStore, generalCargoViewStore])

    gateInViewStore.getContainersWithStackOutSequenceByReference = containerStackOutFunc
    gateInViewStore.validateOutboundRequest = validateOutboundRequest
    gateInViewStore.validateAllocationSpace = validateAllocationSpace
    gateInViewStore.dropOffOrderSearchStore.validateAllocationSpace = validateAllocationSpace
    isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput
    store.openPlanContainerPositionDialog = openPlanContainerPositionDialog

    if (error) {
      return <Typography variant='h4'>{t('errorLoading', 'Error loading')}</Typography>
    }

    return (
      <InitializationWrapper isInitializing={loading}>
        <Box
          sx={{
            height: '100%',
            bgcolor: theme.palette.grey[100],
          }}
        >
          <TruckVisitHeader
            store={store}
            gateInViewStore={gateInViewStore}
            tenantStore={tenantStore}
          />
          <Container sx={{ paddingY: 2 }}>
            <TruckVisitTable store={store} gateInViewStore={gateInViewStore} />
          </Container>

          <TruckVisitsDialogs gateInViewStore={gateInViewStore} />
        </Box>
      </InitializationWrapper>
    )
  },
)
