import { Download } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { CloseIcon, Header } from '@tom-ui/ui'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.mjs`

type PdfViewerProps = {
  pdfBlob: Blob
  fileName?: string
  handleOnClose: () => void
}

const PdfViewerPage: React.FC<PdfViewerProps> = ({ pdfBlob, fileName, handleOnClose }) => {
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [width, setWidth] = useState<number>(300)

  const containerRef = useRef<HTMLDivElement>(null)

  const file = useMemo(() => pdfBlob, [pdfBlob])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  const handleDownload = () => {
    const url = URL.createObjectURL(pdfBlob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName || 'document.pdf'
    link.click()
    URL.revokeObjectURL(url)
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || entries.length === 0) return
      const entry = entries[0]
      setWidth(entry.contentRect.width - 16)
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <>
      <Header
        title={fileName}
        leftRenderOption={
          <IconButton onClick={handleOnClose}>
            <CloseIcon />
          </IconButton>
        }
        rightRenderOption={
          <IconButton onClick={handleDownload}>
            <Download />
          </IconButton>
        }
      />
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box
          ref={containerRef}
          sx={{
            flexGrow: 1,
            border: '1px solid #ddd',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            p: 1,
          }}
        >
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={width} />
          </Document>
        </Box>
      </Box>
    </>
  )
}
export default PdfViewerPage
