import { Box, Card, CardContent, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  ContainerJourneyDto,
  HoldResponseDto,
  OrderResponseDto,
} from '@planning/app/api'
import { ContainerDirectionChip } from '@planning/components/ContainerDirectionChip'
import { OrderAlerts } from '@planning/components/molecules/OrderAlerts'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { GateClerkSearchResultDto } from '@planning/stores/gateClerk/GateClerkViewStore'
import { canOrderProceed } from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { HighlightedText } from '@tom-ui/ui'
import moment from 'moment'
import { FC } from 'react'

interface Props {
  containerNumber: string
  holds: HoldResponseDto[]
  highlight?: string
  onClick?: () => void
  children?: React.ReactNode
  direction?: CarrierVisitDirection
  order?: GateClerkSearchResultDto
  releaseEnd?: string | null
  isOnTerminal?: boolean
}

export const GateClerkSearchResult: FC<Props> = ({
  containerNumber,
  holds,
  highlight,
  onClick,
  children,
  direction,
  order,
  releaseEnd,
  isOnTerminal,
}) => {
  const { tenantStore, gateClerkViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const hasActiveHolds = holds && holds.some(h => h.status === 'Active')
  const isValidState = canOrderProceed({
    allowGateInWhenUnitNotOnTerminal: tenantStore.allowGateInWhenUnitNotOnTerminal,
    direction,
    hasActiveHolds,
    isOnTerminal,
    isReleaseExpired: !!releaseEnd && moment() > moment(releaseEnd),
    skipReleaseOrder: tenantStore.skipReleaseOrder,
  })

  const openInboundForADifferentCarrierValidation = (order: ContainerJourneyDto) => {
    return !!gateClerkViewStore.ordersByContainerNumber.find(
      item =>
        item.containerNumber === order.containerNumber &&
        item.direction === CarrierVisitDirection.Inbound &&
        item.carrierVisitId !== null,
    )
  }

  const openOutboundForADifferentCarrierValidation = (
    order: ContainerJourneyDto | OrderResponseDto,
  ) => {
    return !!gateClerkViewStore.pickUpOrderSearchStore.ordersByContainerNumber.find(
      item =>
        item.containerNumber === order.containerNumber &&
        item.direction === CarrierVisitDirection.Outbound &&
        item.carrierVisitId !== null,
    )
  }

  const OpenOrderWithDifferentCarrierAlert = () => (
    <CustomAlert
      message={t(
        'containerHasOpenOrderForADifferentCarrier',
        'Container has an open order for a different carrier',
      )}
      severity='warning'
    />
  )

  return (
    <Card
      sx={{
        mb: '1rem',
      }}
      onClick={() => {
        if (isValidState) onClick?.()
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h3'>
            <HighlightedText highlight={highlight}>{containerNumber}</HighlightedText>
          </Typography>
          {direction ? (
            <ContainerDirectionChip
              direction={direction}
              label={direction === 'Inbound' ? t('dropOff', 'Drop Off') : t('pickUp', 'Pick Up')}
            />
          ) : (
            ''
          )}
        </Box>

        {isValidState && children}

        {order && openInboundForADifferentCarrierValidation(order) && (
          <OpenOrderWithDifferentCarrierAlert />
        )}

        {order && openOutboundForADifferentCarrierValidation(order) && (
          <OpenOrderWithDifferentCarrierAlert />
        )}

        <OrderAlerts
          direction={direction}
          hasActiveHolds={hasActiveHolds}
          isOnTerminal={order?.isOnTerminal}
          releaseEnd={releaseEnd}
          invalidAllocationSpace={order?.invalidAllocationSpace}
          plannedYardLocation={order?.plannedYardLocation}
        />
      </CardContent>
    </Card>
  )
}
