import { Box, Grid, TextField } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  CarrierType,
  ContainerDamageResponseDto,
  OrderResponseDto,
  UnitType,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IContainerDamage } from '@planning/components'
import { ControlledContainerSealsWithTypes } from '@planning/components/ControlledContainerSealsWithTypes'
import { ControlledDamageRecordBox } from '@planning/components/damage-report/ControlledDamageRecordBox'
import { OogFormData } from '@planning/components/organisms/OogSubForm/oog-helper'
import { OogSubForm } from '@planning/components/organisms/OogSubForm/OogSubForm'
import { UnitLabelSubForm } from '@planning/components/organisms/UnitLabelSubForm/UnitLabelSubForm'
import { DamageReportBox } from '@planning/pages/TallymanV2/Components/DamageReportBox'
import { IContainerItem } from '@planning/rt-stores/container/ContainerItem'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { useFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IProps {
  damages?: ContainerDamageResponseDto[]
  containerItem?: IContainerItem
  containerAutocomplete?: React.ReactNode
}

export interface IInspectContainerFormData extends OrderResponseDto {
  carrierType: CarrierType
  carrierVisitId: number
  damages: ContainerDamageResponseDto[]
  damagesReported?: IContainerDamage[]
  hasDamage?: boolean
  oog?: OogFormData
}

export const InspectContainerForm: FC<IProps> = observer(
  ({ containerItem, containerAutocomplete }) => {
    const { t } = useTranslate()
    const hasNewDamageRecording = useBooleanFlagValue('damage-recording', false)
    const { isoCodeMappingInputStore } = usePlanningStore()

    const formStore = useFormStore<IInspectContainerFormData>()

    const { control, getValues, setValue, watch, formState } =
      useFormContext<IInspectContainerFormData>()

    const hasDoorDirection = !!getValues('doorDirection')
    const hasOperationalInstructions = !!getValues('operationalInstructions')

    const onEditDamageReport = (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ): void => {
      setValue('damages', [
        ...getValues('damages').filter(d => d.description !== initialReport.description),
        editedReport as ContainerDamageResponseDto,
      ])
    }

    const unitTypeIsContainer = containerItem?.data.unitType === UnitType.Container

    return (
      <form>
        <Grid container spacing='0.5rem' p='1rem'>
          {containerAutocomplete ? (
            <Grid item xs={12}>
              {containerAutocomplete}
            </Grid>
          ) : (
            ''
          )}
          {hasOperationalInstructions && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name='operationalInstructions'
                render={({ field }) => (
                  <TextField
                    {...field}
                    data-cy='container-operational-instructions'
                    disabled
                    multiline
                    fullWidth
                    label={t('operationalInstructions', 'Operational Instructions')}
                    variant='outlined'
                    sx={{ marginTop: '1rem' }}
                  />
                )}
              />
            </Grid>
          )}
          {unitTypeIsContainer && (
            <Grid item xs={12}>
              {isoCodeMappingInputStore.renderIsoCodeMappingInput?.({
                label: `${t('isoCode', 'ISO Code')} *`,
                fullWidth: true,
                variant: 'outlined',
                type: 'text',
                uppercase: true,
                formState: formState,
                name: 'containerIsoCode',
                required: true,
                control: control,
                watch: watch,
              })}
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              control={control}
              name='containerMaxGross'
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  data-cy='container-max-gross-weight'
                  fullWidth
                  label={`${t('maxGrossWeight', 'Max. gross weight')}`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position='end' color='secondary'>
                        kg
                      </CustomInputAdornment>
                    ),
                  }}
                  sx={{ marginTop: '1rem' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='containerTare'
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  data-cy='container-tare-weight'
                  fullWidth
                  label={`${t('tareWeight', 'Tare weight')}`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position='end' color='secondary'>
                        kg
                      </CustomInputAdornment>
                    ),
                  }}
                  sx={{ marginTop: '1rem' }}
                />
              )}
            />
          </Grid>
          {hasDoorDirection && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name='doorDirection'
                render={({ field }) => (
                  <TextField
                    {...field}
                    data-cy='container-door-direction'
                    disabled
                    fullWidth
                    label={t('doorDirection', 'Door direction')}
                    variant='outlined'
                    sx={{ marginTop: '1rem' }}
                  />
                )}
              />
            </Grid>
          )}
          {containerItem && (
            <Grid item xs={12}>
              {hasNewDamageRecording ? (
                <ControlledDamageRecordBox control={control} name='hasDamage' />
              ) : (
                <Box sx={{ marginTop: '1rem !important' }}>
                  <Controller
                    control={control}
                    name={`damages`}
                    render={({ field: { onChange } }) => (
                      <DamageReportBox
                        containerId={0}
                        damages={getValues('damages')}
                        onCreateDamageReport={(_, data) =>
                          onChange([
                            ...getValues('damages'),
                            {
                              ...(data as ContainerDamageResponseDto),
                              created: new Date().toISOString(),
                            },
                          ])
                        }
                        onEditDamageReport={onEditDamageReport}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Controller
              control={control}
              name={'unitLabel'}
              render={({ field: { onChange, value } }) => (
                <UnitLabelSubForm
                  formStore={formStore}
                  name='unitLabel'
                  initialValue={value}
                  onChange={value => onChange(value)}
                />
              )}
            />
          </Grid>

          {!getValues('isEmpty') && (
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              <Controller
                control={control}
                name={'oog'}
                render={({ field: { onChange, value } }) => (
                  <OogSubForm
                    formStore={formStore}
                    name='oog'
                    initialValue={value}
                    onChange={oog => onChange(oog)}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginTop: '1rem !important' }}>
            <ControlledContainerSealsWithTypes
              name='seals'
              control={control}
              sealsName='seals'
              hasSealsName='hasSeals'
            />
          </Grid>
        </Grid>
      </form>
    )
  },
)
