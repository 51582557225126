import { ContainerHeight, ContainerType } from '@planning/app/api'
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

export interface RoutingSlipPdfProps {
  dropOffs?: (NumericContainerInfo | NonNumericContainerInfo)[]
  pickUps?: (NumericContainerInfo | NonNumericContainerInfo)[]
}

export interface NumericContainerInfo {
  type: 'numeric'
  containerNumber: string
  location: string
}

export interface NonNumericContainerInfo {
  type: 'non-numeric'
  location: string
  archetype: string
  length: number[]
  height: ContainerHeight[]
  containerType: ContainerType[]
}

const styles = StyleSheet.create({
  page: { padding: 20, fontSize: 12 },
  pageTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  section: {
    border: '1pt solid #e0e0e0',
    borderRadius: 8,
    marginBottom: 12,
    overflow: 'hidden',
  },
  header: {
    padding: 10,
    borderBottom: '1pt solid #e0e0e0',
    backgroundColor: '#ffffff',
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  containerItem: {
    backgroundColor: '#f9f9f9',
    padding: 10,
    borderBottom: '1pt solid #e0e0e0',
  },
  lastContainerItem: {
    borderBottom: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  containerNumber: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  icon: {
    marginRight: 4,
    fontSize: 10,
  },
  locationText: {
    fontSize: 11,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 11,
    marginRight: 4,
  },
})

export const RoutingSlip: React.FC<RoutingSlipPdfProps> = ({ dropOffs, pickUps }) => {
  const renderContainerItem = (
    item: NumericContainerInfo | NonNumericContainerInfo,
    isLast: boolean,
    key: number,
  ) => (
    <View
      key={key}
      style={isLast ? [styles.containerItem, styles.lastContainerItem] : styles.containerItem}
    >
      {item.type === 'numeric' ? (
        <>
          <Text style={styles.containerNumber}>{item.containerNumber}</Text>
          <Text style={styles.locationText}>{item.location || 'No location provided'}</Text>
        </>
      ) : (
        <>
          <Text style={styles.containerNumber}>Non-numeric container</Text>
          <Text style={styles.locationText}>{item.location}</Text>
          <View style={styles.row}>
            <Text style={styles.label}>Archetype:</Text>
            <Text>{item.archetype}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Length:</Text>
            <Text>{item.length.join(', ')}</Text>
            <Text style={styles.label}>Height:</Text>
            <Text>{item.height.join(', ')}</Text>
            <Text style={styles.label}>Type:</Text>
            <Text>{item.containerType.join(', ')}</Text>
          </View>
        </>
      )}
    </View>
  )

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.pageTitle}>Routing slip</Text>

        <View style={styles.section}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Drop offs</Text>
          </View>
          {dropOffs?.map((item, index) =>
            renderContainerItem(item, index === dropOffs.length - 1, index),
          )}
        </View>

        <View style={styles.section}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Pick ups</Text>
          </View>
          {pickUps?.map((item, index) =>
            renderContainerItem(item, index === pickUps.length - 1, index),
          )}
        </View>
      </Page>
    </Document>
  )
}
