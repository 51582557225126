import { Stack } from '@mui/material'
import { TruckVisitWithOrdersDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { TruckVisitDriver } from '../Atoms/TruckVisitDriver'
import { TruckVisitExpectedArrival } from '../Atoms/TruckVisitExpectedArrival'

interface Props {
  truckVisit: TruckVisitWithOrdersDto
}
export const TruckVisitDetails = observer(({ truckVisit }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack gap={1}>
      <TruckVisitDriver truckVisit={truckVisit} />

      <TruckVisitExpectedArrival
        truckVisit={truckVisit}
        label={t('expectedArrivalWindow', 'Expected arrival window')}
      />
    </Stack>
  )
})
