import { usePlanningStore } from '@planning/AppProvider'
import { UpdatesNotificationCard } from '@planning/pages/VesselVisit/Components/atoms/UpdatesNotificationCard'
import { ReviewUpdatesDialog } from '@planning/pages/VesselVisit/Components/organisms/ReviewUpdatesDialog'
import { ReviewUpdatesDialogViewStore } from '@planning/pages/VesselVisit/Stores/ReviewUpdatesDialogViewStore'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

interface Props {
  orders: IOrderItem[]
}
export const VesselNotifications = observer(({ orders }: Props) => {
  const { orderUpdateItemStore } = usePlanningStore()

  const reviewUpdatesDialogStore = useMemo(
    () => new ReviewUpdatesDialogViewStore(orderUpdateItemStore),
    [orderUpdateItemStore],
  )

  useEffect(() => {
    if (orders.length) {
      reviewUpdatesDialogStore.setOrders(orders.map(order => order.data))
    }
  }, [orders, reviewUpdatesDialogStore])

  return (
    <>
      {reviewUpdatesDialogStore.updatesCount > 0 && (
        <UpdatesNotificationCard
          onClick={() => reviewUpdatesDialogStore.openDialog()}
          updateCount={reviewUpdatesDialogStore.updatesCount}
        />
      )}

      <ReviewUpdatesDialog store={reviewUpdatesDialogStore} />
    </>
  )
})
