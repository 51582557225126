import { Container } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useGridApiRef } from '@mui/x-data-grid'
import { UnitType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { NnrOrderLocalStore } from '@planning/rt-stores/nnrOrder/NnrOrderLocalStore'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { ISortDelegate, PaginatedLocalStore } from '@planning/stores/PaginatedLocalStore'
import { simpleSearchFilterDelegate } from '@planning/stores/simpleSearchFilterDelegate'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ContainersCriteria, ContainerSummary } from '../Order/components/ContainerSummary'
import { NNROrderDetailsTable } from './Components/NNROrderDetailsTable'
import { NNROrderDetailsToolbar } from './Components/NNROrderDetailsToolbar'
import { NNROrderHeaderWithBreadcrumbs } from './Components/organisms/NNROrderHeaderWithBreadcrumbs'

const orderSortingDelegate: ISortDelegate<IOrderItem> = (sortingModel, a, b) => {
  const sort = (a.data.containerNumber ?? '').localeCompare(b.data.containerNumber ?? '')
  return sortingModel.isDescending ? -sort : sort
}

const orderFilterDelegate = simpleSearchFilterDelegate(['containerNumber'])

export const NNROrderDetailsPage = observer(() => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const theme = useMinimalsTheme()
  const apiRef = useGridApiRef()

  const {
    nnrOrderItemStore,
    orderItemStore,
    containerItemStore,
    containerJourneyItemStore,
    vesselVisitItemStore,
    railVisitItemStore,
    truckVisitItemStore,
    vesselItemStore,
    truckItemStore,
  } = usePlanningStore()

  const { nnrOrderId } = useParams()

  if (!Number(nnrOrderId) || Number(nnrOrderId) < 0) {
    navigate(PATH_PLANNING.nnrOrders)
  }

  const id = Number(nnrOrderId)

  const nnrOrderLocalStore = useMemo(
    () =>
      new NnrOrderLocalStore(
        nnrOrderItemStore,
        orderItemStore,
        containerItemStore,
        containerJourneyItemStore,
        vesselVisitItemStore,
        railVisitItemStore,
        truckVisitItemStore,
        vesselItemStore,
        truckItemStore,
        id,
      ),
    [
      nnrOrderItemStore,
      orderItemStore,
      containerItemStore,
      containerJourneyItemStore,
      vesselVisitItemStore,
      railVisitItemStore,
      truckVisitItemStore,
      vesselItemStore,
      truckItemStore,
      id,
    ],
  )

  const orderLocalPaginationStore = new PaginatedLocalStore(
    nnrOrderLocalStore,
    orderSortingDelegate,
    orderFilterDelegate,
  )

  const { loading } = useAsyncFetch(
    async () => await nnrOrderLocalStore.fetch(),
    [nnrOrderLocalStore],
  )

  if (!nnrOrderLocalStore.nnrOrder) return <></>

  const nnrOrder = nnrOrderLocalStore.nnrOrder.data

  const containersCriteria: ContainersCriteria = {
    id: 0,
    height: nnrOrder.containerHeights?.[0] ?? 'Standard',
    length: nnrOrder.containerLengths?.[0] ?? 0,
    type: nnrOrder.containerTypes?.[0] ?? 'General',
    isoCode: nnrOrder.containerIsoCode,
    archetype: nnrOrder.archetypeName,
    archetypeHeights: nnrOrder.containerHeights,
    archetypeLengths: nnrOrder.containerLengths,
    archetypeTypes: nnrOrder.containerTypes,
    damages: [],
    isEmpty: true,
    isOnTerminal: false,
    unitType: UnitType.Container,
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <NNROrderHeaderWithBreadcrumbs
          id={id}
          apiRef={apiRef}
          nnrOrder={nnrOrder}
          orderLocalPaginationStore={orderLocalPaginationStore}
        />
        <Container>
          <Stack mt={1}>
            <ContainerSummary
              noContainerNumberText={t('containerRequirements', 'Container requirements')}
              container={containersCriteria}
              showArchetypeAttributes={true}
            />
          </Stack>
          <Stack mt={1}>
            <NNROrderDetailsToolbar nnrOrder={nnrOrder} />
          </Stack>
        </Container>
        <NNROrderDetailsTable
          apiRef={apiRef}
          nnrOrder={nnrOrder}
          orderStore={orderLocalPaginationStore}
        />
      </Box>
    </InitializationWrapper>
  )
})
