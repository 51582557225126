import {
  CompanyDto,
  CompanyType,
  CreateCompanyCommand,
  UpdateCompanyCommand,
} from '@billing/app/api'
import { CompanyFormProfile } from './company-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  shortName,
  alternativeNames,
  companyTypes,
  email,
  phoneNumber,
  remarks,
  taxId,
  address,
  isDeactivated,
}: CompanyFormProfile): CreateCompanyCommand => ({
  name,
  shortName,
  alternativeNames: alternativeNames.filter(x => x.trim() !== ''),
  companyTypes: companyTypes as CompanyType[],
  email,
  phoneNumber,
  remarks,
  taxId,
  address,
  isDeactivated,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  shortName,
  alternativeNames,
  companyTypes,
  email,
  phoneNumber,
  remarks,
  taxId,
  address,
  isDeactivated,
}: CompanyFormProfile): UpdateCompanyCommand => ({
  id: id!,
  name,
  shortName,
  alternativeNames: alternativeNames.filter(x => x.trim() !== ''),
  companyTypes: companyTypes as CompanyType[],
  email,
  phoneNumber,
  remarks,
  taxId,
  address,
  isDeactivated,
})

const mapDtoToFormValues = ({
  id,
  name,
  shortName,
  alternativeNames,
  companyTypes,
  email,
  phoneNumber,
  remarks,
  taxId,
  address,
  isDeactivated,
}: CompanyDto): CompanyFormProfile => ({
  id,
  name,
  shortName,
  companyTypes,
  alternativeNames: alternativeNames,
  email: email ?? '',
  phoneNumber: phoneNumber ?? '',
  remarks: remarks ?? '',
  taxId: taxId ?? '',
  address: address ?? '',
  isDeactivated: isDeactivated ?? false,
})

export const CompanyFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
