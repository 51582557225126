import { StowagePositionDto, YardLocation } from '@planning/app/api'
import _ from 'lodash'

/**
 * Formats a stowage position into a string representation.
 *
 * The position is formatted as `bay.row.tier`, where each component is
 * zero-padded to a fixed width:
 * - `bay` is padded to 3 digits
 * - `row` is padded to 2 digits
 * - `tier` is padded to 2 digits
 *
 * @param position - The stowage position to format.
 * @returns The formatted stowage position string.
 */
export const formatPosition = (position: StowagePositionDto) => {
  const bay = (position.bay ?? 0).toString().padStart(3, '0')
  const row = (position.row ?? 0).toString().padStart(2, '0')
  const tier = (position.tier ?? 0).toString().padStart(2, '0')

  return `${bay}.${row}.${tier}`
}

/**
 * Formats a given weight into a string representation.
 *
 * @param weight - The weight to format. Can be a number, null, or undefined.
 * @param returnZeroIfUnknown - If true, returns '0kg' when the weight is null or undefined.
 * @returns A string representing the formatted weight. Returns '0kg' if `returnZeroIfUnknown` is true and weight is null or undefined.
 *          Otherwise, returns the weight followed by 'kg' or 'Unknown' if the weight is null or undefined.
 *          If the weight is negative, returns '0kg'.
 */
export const formatWeight = (weight: number | null | undefined, returnZeroIfUnknown = false) => {
  if (weight == null) {
    return returnZeroIfUnknown ? '0kg' : 'Unknown'
  }

  return Math.max(0, weight) + 'kg'
}

export const toYardLocationString = (yardLocations: YardLocation[]) => {
  if (yardLocations.length === 0) return ''

  const sortedBays = _(yardLocations.map(yard => yard.bay))
    .uniq()
    .sort()
    .value()
  const formattedBay =
    sortedBays.length > 1 ? `${sortedBays[0]}-${sortedBays[sortedBays.length - 1]}` : sortedBays[0]
  const location = yardLocations[0]
  return `${location.block}.${formattedBay}.${location.row}.${location.tier}`
}
