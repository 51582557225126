import { Box, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '../../../hooks'

interface Props {
  color: string
  label: string
  value?: number | null
  dataCyValue?: string
}
export const RangeGraphLegend = ({ color, label, value, dataCyValue }: Props) => {
  const theme = useMinimalsTheme()
  return (
    <Stack gap={theme.customSpacing.xxs}>
      <Typography variant='caption' color='secondary'>
        {label}
      </Typography>
      <Stack flexDirection='row' gap={theme.customSpacing.xxs} alignItems='center'>
        <Box
          sx={{
            width: 16,
            height: 16,
            backgroundColor: color,
            borderRadius: theme.customRadius.oval,
          }}
        />
        <Typography variant='subtitle2' data-cy={dataCyValue}>
          {value ?? 0}
        </Typography>
      </Stack>
    </Stack>
  )
}
