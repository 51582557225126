import {
  CarrierVisitStatus,
  ContainerJourneyResponseDto,
  ContainerResponseDto,
  OrderResponseDto,
  TruckVisitDto,
  TruckVisitWithOrdersDto,
} from '@planning/app/api'
import { GetTruckVisitsQuery } from '@planning/messages/queries'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { ContainerItemStore } from '@planning/rt-stores/container/ContainerItemStore'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { TruckVisitItemStore } from '@planning/rt-stores/truckVisit/TruckVisitItemStore'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'

export class TruckVisitAggregationStore {
  constructor(
    private readonly truckVisitItemStore: TruckVisitItemStore,
    private readonly orderItemStore: OrderItemStore,
    private readonly containerItemStore: ContainerItemStore,
    private readonly containerJourneyItemStore: ContainerJourneyItemStore,
  ) {
    makeObservable(this, {
      truckVisits: computed,
      orders: computed,
      containers: computed,
      containerJourneys: computed,

      upsertVisitsToItemStores: action,
    })
  }

  get truckVisits() {
    return _.values(this.truckVisitItemStore.elements)
  }

  get orders() {
    return _.values(this.orderItemStore.elements)
  }

  get containers() {
    return _.values(this.containerItemStore.elements)
  }

  get containerJourneys() {
    return _.values(this.containerItemStore.elements)
  }

  fetch = (
    startDate?: Date,
    endDate?: Date,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    filterBy?: string,
    isOnTerminal?: boolean,
  ) => {
    this.truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        startDate,
        endDate,
        includeCompleted,
        orderBy,
        isDescending,
        filterBy,
        undefined,
        isOnTerminal,
      ),
    )
  }

  upsertVisitsToItemStores = (truckVisitsWithOrders: TruckVisitWithOrdersDto[]) => {
    const orders = truckVisitsWithOrders
      .flatMap(x => x.inboundOrders)
      .concat(truckVisitsWithOrders.flatMap(x => x.outboundOrders))
      .filter(x => !!x)
      .map(x => x!)
      .map<OrderResponseDto>(o => {
        return {
          ...o,
          id: o.id ?? 0,
          containerId: o.containerId ?? 0,
        }
      })
    this.orderItemStore.upsertBulk(orders)

    const containers = truckVisitsWithOrders
      .flatMap(x => x.containers)
      .filter(x => !!x)
      .map(x => x!)
      .map<ContainerResponseDto>(c => {
        return {
          ...c,
          id: c.id ?? 0,
        }
      })
    this.containerItemStore.upsertBulk(containers)

    const containerJourneys = truckVisitsWithOrders
      .flatMap(x => x.containerJourneys)
      .filter(x => !!x)
      .map(x => x!)
      .map<ContainerJourneyResponseDto>(cj => {
        return {
          ...cj,
          id: cj.id ?? 0,
          containerId: cj.containerId ?? 0,
          inboundOrderId: cj.inboundOrderId ?? 0,
          outboundOrderId: cj.outboundOrderId ?? 0,
        }
      })
    this.containerJourneyItemStore.upsertBulk(containerJourneys)

    const truckVisits: TruckVisitDto[] = truckVisitsWithOrders.map<TruckVisitDto>(x => ({
      id: x.id!,
      status: x.status ?? CarrierVisitStatus.Expected,
      carrierIds: [],
      cargoType: x.cargoType ?? 'Unknown',
      driverName: x.driver?.name,
      driverId: x.driver?.driverID ?? '0',
      externalDriverId: x.driver?.id,
      truckCompanyId: x.truckCompany?.id,
      isCancelled: x.isCancelled,
      eta: x.eta ?? undefined,
      ata: x.ata ?? undefined,
      etd: x.etd ?? undefined,
      atd: x.atd ?? undefined,
      identifier: x.identifier,
      truckCompanyName: x.truckCompany?.name ?? 'Unknown',
    }))
    this.truckVisitItemStore.upsertBulk(truckVisits)
  }
}
