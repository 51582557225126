import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

export const NoSpaceInExistingAllocationAlert: FC = () => {
  const { t } = useTranslate()
  return (
    <CustomAlert
      message={t(
        'thereIsNoSpaceInTheExistingAllocation',
        'There is no space in the existing allocation',
      )}
      severity='warning'
    />
  )
}
