import { Stack, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitStatus } from '@planning/app/api'
import { TruckVisitsViewStore } from '@planning/pages/TruckVisits/Stores/TruckVisitsViewStore'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, SyntheticEvent, useState } from 'react'
import { FilterOption, TabOption } from './FilterOption'
import { GateOutWarningDialogs } from './GateOutWarningDialogs'
import { TabItem } from './Molecules/TabItem'

interface TabPanelProps {
  children?: any
  index: number
  value: number
}

export interface TabData {
  key: 'expected' | 'onTerminal' | 'departed'
  title: string
  data: ITruckVisitItem[]
  count: (tab: number) => number
  carrierVisitStatus: CarrierVisitStatus
}

interface ReusableTabsProps {
  viewStore: TruckVisitsViewStore
}

const CustomTabPanel: FC<TabPanelProps> = props => {
  const { children, value, index } = props

  return (
    <div hidden={value !== index}>{value === index && <Box sx={{ pt: 1 }}>{children}</Box>}</div>
  )
}

const FilterOptions: FC<{ store: TruckVisitsViewStore; tab: TabData }> = ({ store, tab }) => {
  const { t } = useTranslate()

  const tabsFilteringOptions: TabOption = {
    expected: {
      defaultOption: 'expectedToday',
      options: [
        { value: 'expectedLast7Days', label: t('last7Days', 'Last 7 days') },
        { value: 'expectedToday', label: t('today', 'Today') },
        { value: 'expectedNext7Days', label: t('next7Days', 'Next 7 days') },
      ],
    },
    onTerminal: {
      defaultOption: 'onTerminal',
      options: [{ value: 'onTerminal', label: t('onTerminal', 'On Terminal') }],
    },
    departed: {
      defaultOption: 'departedToday',
      options: [
        { value: 'departedLast7Days', label: t('last7Days', 'Last 7 days') },
        { value: 'departedToday', label: t('today', 'Today') },
      ],
    },
  }

  const filteringOptions = tabsFilteringOptions[tab.key]

  return (
    <Box key={`searchable-list-action-${tab.key}`} sx={{ ml: '1rem', minWidth: '140px' }}>
      <FilterOption
        disabled={tab.key === 'onTerminal'}
        key='dayFilter'
        onChange={store.handleFilterChange}
        defaultOption={filteringOptions.defaultOption}
        options={filteringOptions.options}
      />
    </Box>
  )
}

const SortingOptions: FC<{ store: TruckVisitsViewStore }> = ({ store }) => {
  const { t } = useTranslate()

  const handleSortingChange = (value: any) => {
    store.setSorting(value)
  }

  const sortingOptions = [
    { value: 'old', label: t('oldToNew', 'Old to new') },
    { value: 'new', label: t('newToOld', 'New to old') },
  ]

  return (
    <Box key={`searchable-list-action-sorting`} sx={{ ml: '1rem', minWidth: '140px' }}>
      <FilterOption key='sortingFilter' onChange={handleSortingChange} options={sortingOptions} />
    </Box>
  )
}

export const TabsList: FC<ReusableTabsProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const [currentTab, setCurrentTab] = useState(0)
  const { gateInViewStore } = usePlanningStore()

  const handleTextFilterChange = (value: any) => {
    viewStore.setFilter(value.target.value)
  }

  const tabs: TabData[] = [
    {
      title: t('expected', 'Expected'),
      key: 'expected',
      data: viewStore.filteredExpectedTruckVisitsInDateRange,
      carrierVisitStatus: CarrierVisitStatus.Expected,
      count: tab => {
        return tab === 0 // 0 is the index of the 'expected' tab
          ? viewStore.filteredExpectedTruckVisitsInDateRange.length
          : viewStore.expectedTruckVisitsForToday.length
      },
    },
    {
      title: t('onTerminal', 'On terminal'),
      key: 'onTerminal',
      data: viewStore.filteredArrivedTruckVisits,
      carrierVisitStatus: CarrierVisitStatus.Arrived,
      count: tab => {
        return tab === 1 // 1 is the index of the 'onTerminal' tab
          ? viewStore.filteredArrivedTruckVisits.length
          : viewStore.arrivedTruckVisits.length
      },
    },
    {
      title: t('departed', 'Departed'),
      key: 'departed',
      data: viewStore.filteredDepartedTruckVisitsInDateRange,
      carrierVisitStatus: CarrierVisitStatus.Departed,
      count: tab => {
        return tab === 2 // 2 is the index of the 'departed' tab
          ? viewStore.filteredDepartedTruckVisitsInDateRange.length
          : viewStore.departedTruckVisitsForToday.length
      },
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Box display='flex' alignItems='center' mb='1rem' justifyContent='space-between'>
        <Box>
          <Tabs
            value={currentTab}
            onChange={(_: SyntheticEvent, newValue: number) => {
              setCurrentTab(newValue)
              viewStore.setFilter('')
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                data-cy={`tab-${tab.key}`}
                key={index}
                label={
                  <Stack spacing='8px' direction='row' alignItems='baseline'>
                    <Typography variant='subtitle1'>{tab.title}</Typography>
                    <Box
                      sx={{
                        backgroundColor: '#F9FAFB',
                        padding: '4px 8px',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant='caption' fontWeight='bold'>
                        {tab.count(currentTab)}
                      </Typography>
                    </Box>
                  </Stack>
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box display='flex'>
          <TextField
            key='filter'
            label={t('search', 'Search')}
            value={viewStore.filter}
            defaultValue={viewStore.filter}
            onChange={handleTextFilterChange}
          />
          {<FilterOptions store={viewStore} tab={tabs[currentTab]} />}
          {<SortingOptions store={viewStore} />}
        </Box>
      </Box>

      <Box>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={currentTab} index={index}>
            {tab.data.map(item => (
              <TabItem key={item.id} item={item} />
            ))}
          </CustomTabPanel>
        ))}
      </Box>
      <GateOutWarningDialogs viewStore={gateInViewStore} />
    </Box>
  )
})
