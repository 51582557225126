import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PATHS } from '@host/app/paths'
import { Grid, Stack } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CraneSplitSummary, EquipmentPlanningSummary, useOperationsStore } from '@tom-ui/operations'
import {
  BerthPlanningCard,
  ExtendedViewSwitch,
  InboundDashboardCard,
  IssueDashboardCard,
  OrderMovementEditableCard,
  OutboundDashboardCard,
  RestowDetailsDashboardCard,
  VesselNotifications,
  VisitBreak,
  usePlanningStore,
  vesselVisitDetailPages,
} from '@tom-ui/planning'
import { OrderListUploadDialogV2 } from '@tom-ui/planning/pages/VesselVisit/Components/Upload/OrderListUploadDialog'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { IssueStatus } from 'modules/operations/src/app/api'
import { CarrierType } from 'modules/planning/src/app/api'
import { FC, useState } from 'react'
import { CarrierVisitDirection } from './VesselVisitDetails'

export const VesselVisitDashboard: FC<{
  visitId: number
  vesselVisitItem: any
  handlePageChange: (page: string) => void
  onClickExportOrders?: (direction: CarrierVisitDirection) => void
}> = withAuthenticationRequired(
  observer(({ vesselVisitItem, handlePageChange, visitId, onClickExportOrders }) => {
    const isNewDashboardCardsActive = useBooleanFlagValue('vessel-dashboard-new-cards', false)
    const theme = useMinimalsTheme()
    const [isViewExtended, seIsViewExtended] = useState(false)
    const { tenantConfigStore: operationsTenantConfigStore, issueStore } = useOperationsStore()

    const { orderListUploadViewStoreV2 } = usePlanningStore()

    if (!vesselVisitItem) return <></>

    return (
      <Grid
        sx={{ paddingLeft: theme.spacing(3) }}
        container
        justifyContent='flex-start'
        spacing={{ xs: 1, md: 3 }}
      >
        {isNewDashboardCardsActive ? (
          <>
            <Grid item xs={12} paddingTop='0 !important'>
              <Stack alignItems='end'>
                <ExtendedViewSwitch onChange={seIsViewExtended} />
              </Stack>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <InboundDashboardCard
                visitId={vesselVisitItem?.data.id}
                orders={vesselVisitItem?.discharge.orders ?? []}
                visitType={CarrierType.Vessel}
                cargoType={vesselVisitItem?.data.cargoType}
                isViewExtended={isViewExtended}
                onViewClick={() => handlePageChange(vesselVisitDetailPages.discharge)}
                onClickExportOrders={onClickExportOrders}
                cardFooter={
                  <VesselNotifications orders={vesselVisitItem?.discharge.orders ?? []} />
                }
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <OutboundDashboardCard
                visitId={vesselVisitItem?.data.id}
                orders={vesselVisitItem?.load.orders ?? []}
                visitType={CarrierType.Vessel}
                cargoType={vesselVisitItem?.data.cargoType}
                isViewExtended={isViewExtended}
                onViewClick={() => handlePageChange(vesselVisitDetailPages.discharge)}
                onClickExportOrders={onClickExportOrders}
                cardFooter={<VesselNotifications orders={vesselVisitItem?.load.orders ?? []} />}
              />
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <RestowDetailsDashboardCard
                visitId={vesselVisitItem?.data.id}
                restows={vesselVisitItem?.restows ?? []}
                visitType={CarrierType.Vessel}
                cargoType={vesselVisitItem?.data.cargoType}
                isViewExtended={isViewExtended}
                onViewClick={() => handlePageChange(vesselVisitDetailPages.restow)}
              />
            </Grid>
            <OrderListUploadDialogV2
              enableFieldSelectionFromOrdersView={false}
              open={orderListUploadViewStoreV2.open}
              handleDialogClose={() => {
                orderListUploadViewStoreV2.setOpen(false)
              }}
              visitType={CarrierType.Vessel}
            />
          </>
        ) : (
          <>
            <Grid item sm={12} md={6} lg={4}>
              <OrderMovementEditableCard
                visitType={CarrierType.Vessel}
                vesselVisitItem={vesselVisitItem}
                direction={CarrierVisitDirection.Inbound}
                onViewClick={() => handlePageChange(vesselVisitDetailPages.discharge)}
                onClickExportOrders={onClickExportOrders}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <OrderMovementEditableCard
                visitType={CarrierType.Vessel}
                vesselVisitItem={vesselVisitItem}
                direction={CarrierVisitDirection.Outbound}
                onViewClick={() => handlePageChange(vesselVisitDetailPages.load)}
                onClickExportOrders={onClickExportOrders}
              />
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <OrderMovementEditableCard
                direction={CarrierVisitDirection.Outbound}
                visitType={CarrierType.Vessel}
                vesselVisitItem={vesselVisitItem}
                isRestow
                onViewClick={() => handlePageChange(vesselVisitDetailPages.restow)}
              />
            </Grid>
          </>
        )}

        <Grid item sm={12} md={6} lg={4}>
          <IssueDashboardCard
            visitType={CarrierType.Vessel}
            vesselVisitItem={vesselVisitItem}
            unresolvedOperationsIssueCount={
              issueStore.items.filter(
                i =>
                  i.carrierVisitIds.includes(visitId) && i.issueStatus === IssueStatus.Unresolved,
              ).length
            }
            onViewClick={() => handlePageChange(`${PATHS.issues}`)}
          />
        </Grid>

        <Grid item sm={12} md={6} lg={4}>
          <VisitBreak vesselVisitItem={vesselVisitItem} />
        </Grid>

        <Grid item sm={12} md={6} lg={4}>
          <BerthPlanningCard vesselVisitItem={vesselVisitItem} />
        </Grid>

        {!operationsTenantConfigStore.skipCraneSplit && (
          <>
            <Grid item sm={12} md={4} lg={4}>
              <CraneSplitSummary />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <EquipmentPlanningSummary
                navigateToUrl={`/EquipmentPlanning/${vesselVisitItem?.id}/equipments`}
              />
            </Grid>
          </>
        )}
      </Grid>
    )
  }),
)
