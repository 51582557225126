import { CargoType, CarrierType, RestowResponseDto } from '@planning/app/api'
import { getVisitDashboardTotalDefinitionByRestows } from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { VisitDashboardDirectionCard } from './VisitDashboardDirectionCard'

interface Props {
  restows: RestowResponseDto[]
  cargoType?: CargoType
  visitId?: number
  visitType?: CarrierType
  isViewExtended?: boolean
  onViewClick: () => void
}
export const RestowDetailsDashboardCard = observer(
  ({ restows, cargoType, visitId, visitType, isViewExtended, onViewClick }: Props) => {
    const { t } = useTranslate()

    const totalDefinition = computed(() => {
      return getVisitDashboardTotalDefinitionByRestows(restows)
    }).get()

    return (
      <VisitDashboardDirectionCard
        title={t('restow', 'Restow')}
        cargoType={cargoType}
        visitId={visitId}
        visitType={visitType}
        archetypeGroups={[]} //TODO: fazer isso aqui
        totalDefinition={totalDefinition}
        isViewExtended={isViewExtended}
        onViewClick={onViewClick}
      />
    )
  },
)
