import { Box, Button, Stack, TextField, debounce } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import Permission from '@planning/permission.enum'
import { VisitItem } from '@planning/rt-stores/visit/VisitViewStore'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { ButtonWithDropdown, Header, PlusIcon } from '@tom-ui/ui'
import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  store: IPaginatedStoreWithItems<VisitItem>
}
export const VisitsHeader = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const { authStore, visitViewStore } = usePlanningStore()
  const navigate = useNavigate()
  const canAddRailVisit = authStore.hasPermission(Permission.WriteRailVisits)

  const startDate = dayjs().subtract(5, 'days').startOf('day')
  const endDate = dayjs().add(5, 'days').endOf('day')

  const { RangePicker } = DatePicker
  type RangeValue = [from: Dayjs | null, to: Dayjs | null] | null
  const [dateRange, setDateRange] = useState<RangeValue>([startDate, endDate])

  const handleDateChange = (val: RangeValue) => {
    if (!val) return
    setDateRange(val)
    visitViewStore.fetchVisits(val[0]?.toDate(), val[1]?.toDate())
  }

  const onAddVesselVisit = () => {
    navigate('/vessel-visits-planning')
  }

  const onAddRailVisit = () => {
    navigate('/rail-visits-planning')
  }

  const OnSearchFilterChange = debounce(async (filter: string) => {
    await store.setFilter(filter)
  }, 500)

  const onTodayClick = () => {
    const today = dayjs(new Date()).hour(0).minute(0).toDate()
    visitViewStore.fetchVisits(today, dayjs(today).hour(23).minute(59).toDate())
  }

  const onNextSevenDaysClick = () => {
    const today = dayjs(new Date()).hour(0).minute(0).toDate()
    const nextSevenDays = dayjs(today).hour(23).minute(59).add(7, 'day').toDate()
    visitViewStore.fetchVisits(today, nextSevenDays)
  }

  const onNextThirtyDaysClick = () => {
    const today = dayjs(new Date()).hour(0).minute(0).toDate()
    const nextThirtyDays = dayjs(today).add(30, 'day').hour(23).minute(59).toDate()
    visitViewStore.fetchVisits(today, nextThirtyDays)
  }

  const getCreateVisitOptions = () => {
    const options = [
      {
        label: t('vesselVisit', 'Vessel visit'),
        testId: 'create-vessel-visit-btn',
        onClick: onAddVesselVisit,
      },
    ]

    if (canAddRailVisit)
      options.push({
        label: t('railVisit', 'Rail visit'),
        testId: 'create-rail-visit-btn',
        onClick: onAddRailVisit,
      })

    return options
  }

  return (
    <Header
      title={t('visits', 'Visits')}
      rightRenderOption={
        <Stack flexDirection='row' alignItems='center' gap={2}>
          <Button color='inherit' variant='contained' sx={{ flex: 'none' }} onClick={onTodayClick}>
            {t('today', 'Today')}
          </Button>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            onClick={onNextSevenDaysClick}
          >
            {t('nextSevenDays', 'Next 7 days')}
          </Button>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            onClick={onNextThirtyDaysClick}
          >
            {t('nextThirtyDays', 'Next 30 days')}
          </Button>

          <Box width='300px' data-cy='visits-page-date-picker'>
            <RangePicker
              style={{ height: '3.5rem' }}
              value={dateRange}
              onChange={handleDateChange}
              data-cy='visits-page-date-picker-field'
            />
          </Box>

          <Box width='300px'>
            <TextField
              label={t('search', 'Search')}
              defaultValue={store.filter}
              sx={{ width: '100%', marginTop: 0 }}
              data-cy='visits-page-search-field'
              onChange={event => OnSearchFilterChange(event.currentTarget.value)}
            />
          </Box>

          <Box flex='none'>
            <ButtonWithDropdown
              testId='add-new-visit-btn'
              label={t('addNew', 'Add new')}
              startIcon={<PlusIcon />}
              color='inherit'
              options={getCreateVisitOptions()}
            />
          </Box>
        </Stack>
      }
    />
  )
})
