import { TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import { FC } from 'react'

interface Props {
  label: string
  onChange: (data: any) => void
  value?: Dayjs
  fullWidth?: boolean
}

export const CustomTimePicker: FC<Props> = ({ label, onChange, value, fullWidth }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={value}
        label={label}
        onChange={onChange}
        sx={{ width: fullWidth ? '100%' : undefined }}
      />
    </LocalizationProvider>
  )
}
