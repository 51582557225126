import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

export const OpenInboundWithDifferentCarrierAlert: FC = () => {
  const { t } = useTranslate()
  return (
    <CustomAlert
      message={t(
        'containerHasOpenOrderForADifferentCarrier',
        'Container has an open order for a different carrier',
      )}
      severity='warning'
    />
  )
}
