import { Box } from '@mui/material'
import { RoutingInfoStore } from '@storage/stores/routing-info.store'
import { useTolgee } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import LocationInfo from './LocationInfo'
import { NonNumeric } from './RoutingInfo'

interface Props {
  pickUpContainers: {
    containerNumber: string
    linkedOrderArrivedOrInOperationVisitName: string | undefined
  }[]
  nonNumeric: NonNumeric[]
  routingInfoStore: RoutingInfoStore
  allowDirectDelivery: boolean
}

const ContainerPickUpInfo = observer(
  ({ pickUpContainers, routingInfoStore, nonNumeric, allowDirectDelivery }: Props) => {
    const { t } = useTolgee()

    useEffect(() => {
      const loadPickUpInfo = async () => {
        await routingInfoStore.fetchPickUpInfo(pickUpContainers.map(c => c.containerNumber))
      }
      loadPickUpInfo()
    }, [pickUpContainers, routingInfoStore])

    const getNonNumericSuggestedBlockNames = (nonNumeric: NonNumeric) => {
      if (!nonNumeric.suggestedContainers || nonNumeric.suggestedContainers?.length === 0) return ''

      const blockNames = new Set(
        nonNumeric.suggestedContainers.map(suggestedContainer => suggestedContainer.blockName),
      )
      return `(${Array.from(blockNames).join(', ')})`
    }

    return (
      <Box>
        {nonNumeric.length > 0 &&
          nonNumeric.map((nn, index) => (
            <Box key={index} sx={{ marginBottom: '16px' }}>
              <LocationInfo
                key={index}
                isNonNumeric
                nonNumeric={nn}
                action={'Pick up'}
                blockName={getNonNumericSuggestedBlockNames(nn)}
              />
            </Box>
          ))}
        {routingInfoStore.pickUpInfo.length > 0 &&
          routingInfoStore.pickUpInfo.map(info => {
            const linkedOrderArrivedOrInOperationVisitName = pickUpContainers.find(
              c => c.containerNumber === info.containerNumber,
            )?.linkedOrderArrivedOrInOperationVisitName
            if (allowDirectDelivery && linkedOrderArrivedOrInOperationVisitName) {
              info.blockName = `${linkedOrderArrivedOrInOperationVisitName}${info.blockName ? '\n(' + info.blockName + ')' : ''}`
            }
            if (!info.blockName) {
              routingInfoStore.incrementWarnings()
            }
            return (
              <Box key={info.containerNumber} sx={{ marginBottom: '16px' }}>
                <LocationInfo
                  key={info.containerNumber}
                  blockName={info.blockName ?? t('noLocation', 'No Location')}
                  containerNumber={info.containerNumber}
                  action={'Pick up'}
                />
              </Box>
            )
          })}
      </Box>
    )
  },
)

export default ContainerPickUpInfo
