import { CarrierVisitDirection } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { RangeGraphWithLegend, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

export interface DashboardStatusField {
  label?: string
  value?: number | null
}

interface Props {
  expected: DashboardStatusField
  onTerminal: DashboardStatusField
  offTerminal: DashboardStatusField
  direction?: CarrierVisitDirection
}
export const VisitOrdersStatusRange = observer(
  ({ expected, onTerminal, offTerminal, direction }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <RangeGraphWithLegend
        options={[
          {
            textColor: theme.palette.info.contrastText,
            color: theme.palette.info.dark,
            value: expected.value ?? 0,
            label: expected.label ?? t('expected', 'Expected'),
            order: 0,
            legendDataCy: `order-${direction ?? 'restow'}-expected-value`,
          },
          {
            textColor: theme.palette.info.contrastText,
            color: theme.palette.info.main,
            value: onTerminal.value ?? 0,
            label: onTerminal.label ?? t('onTerminal', 'On terminal'),
            order: 1,
            legendDataCy: `order-${direction ?? 'restow'}-on-terminal-value`,
          },
          {
            textColor: theme.palette.info.contrastText,
            color: theme.palette.info.light,
            value: offTerminal.value ?? 0,
            label: offTerminal.label ?? t('offTerminal', 'Off terminal'),
            order: 2,
            legendDataCy: `order-${direction ?? 'restow'}-off-terminal-value`,
          },
        ]}
      />
    )
  },
)
