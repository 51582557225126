import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RangeGraphOption } from './range-graph-option'
import { RangeGraph } from './RangeGraph'
import { RangeGraphLegend } from './RangeGraphLegend'

interface Props {
  options: RangeGraphOption[]
}
export const RangeGraphWithLegend = observer(({ options }: Props) => {
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <RangeGraph options={options} />
      </Grid>

      {options.map((option, index) => (
        <Grid item xs={12} sm={Math.floor(12 / options.length) ?? 1} key={index}>
          <RangeGraphLegend
            color={option.color}
            label={option.label}
            value={option.value}
            dataCyValue={option.legendDataCy}
          />
        </Grid>
      ))}
    </Grid>
  )
})
