import { CarrierVisitDirection } from '@planning/app/api'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

interface Props {
  filter: string
  direction: CarrierVisitDirection
}
export const TruckVisitSearchAlert = observer(({ filter, direction }: Props) => {
  const { t } = useTranslate()

  const title = useMemo(() => {
    return direction === CarrierVisitDirection.Inbound
      ? t('noAppointmentsFoundFor', 'No appointments found for {filter}', {
          filter: filter,
        })
      : t('truckNotFoundInTheTerminal', '{filter} not found in the terminal', {
          filter: filter,
        })
  }, [direction, filter, t])

  return (
    <CustomAlert
      title={title}
      message={t('contactControlRoom', 'Contact control room')}
      severity='warning'
    />
  )
})
