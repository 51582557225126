import { Box, Grid, Typography } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerVisitStatusChip } from '@planning/components/ContainerVisitStatusChip'
import { HoldsChip } from '@planning/components/hold/DisplayHoldsButton'
import {
  ContainerJourneyStatus,
  ContainerJourneyStatusChip,
} from '@planning/components/molecules/ContainerJourneyStatusChip'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import {
  ServiceOrdersType,
  ServiceOrdersViewStore,
} from '@planning/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { ContainerYardOperationViewStore } from '@planning/pages/VesselVisit/Components'
import adminActionService from '@planning/services/adminActionService'
import companyService from '@planning/services/customerService'
import { useTranslate } from '@tolgee/react'
import { ButtonOption, OptionsButton, useMinimalsTheme } from '@tom-ui/ui'
import { authStore } from '@tom-ui/utils'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { ContainerVisitsCardCarrierVisitInfo } from './ContainerVisitsCardCarrierVisitInfo'
import { ContainerVisitsCardServicesInfo } from './ContainerVisitsCardServicesInfo'

type Props = {
  containerVisit: IContainerJourney
  onClickEdit?: (journey: IContainerJourney) => void
  isInsideIssueCard?: boolean
  containerYardOperationViewStore?: ContainerYardOperationViewStore
}

export const ContainerVisitsCardBody: FC<Props> = observer(
  ({ containerVisit, onClickEdit, isInsideIssueCard, containerYardOperationViewStore }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { serviceOrdersViewStore, appViewStore } = usePlanningStore()
    const [customerName, setCustomerName] = useState<string>('')
    const { navigateCheckoutContainerVisitsPage, navigateCheckinContainerVisitsPage } =
      useOrderNavigation()

    useEffect(() => {
      const fetchCustomerName = async () => {
        const order = (containerVisit?.inboundOrder ?? containerVisit?.outboundOrder)?.order

        if (order?.customerName) {
          setCustomerName(order.customerName)
        } else if (order?.customerId) {
          const company = await companyService.getById(order.customerId)
          setCustomerName(company.name)
        }
      }
      fetchCustomerName()
    }, [containerVisit?.inboundOrder, containerVisit?.outboundOrder])

    const inbound = containerVisit?.inboundOrder
    const outbound = containerVisit?.outboundOrder
    const inboundOrderId = containerVisit?.inboundOrderId
    const outboundOrderId = containerVisit?.outboundOrderId
    const order = (inbound ?? outbound)?.order

    const navigateToServiceOrderForm = (
      containerVisit: IContainerJourney,
      serviceOrdersViewStore: ServiceOrdersViewStore,
      serviceOrdersType: ServiceOrdersType,
    ) => {
      if (containerVisit.container) {
        serviceOrdersViewStore.setCallbackUrlOnClose(
          `${PATH_PLANNING.containerVisits}/${containerVisit.container.number}`,
        )

        serviceOrdersViewStore.setMainServiceType(serviceOrdersType)
        serviceOrdersViewStore.setSelectedContainerJourney({
          ...containerVisit,
        })
      }

      navigate(PATH_PLANNING.serviceOrders)
    }

    const featureFlagClient = useOpenFeatureClient()
    const containerJourneyStatusFF = useMemo(
      () => featureFlagClient.getBooleanValue('container-journey-status', false),
      [featureFlagClient],
    )

    if (!order && !inboundOrderId && !outboundOrderId) return <></>

    const onDeleteJourney = async (order: OrderResponseDto) => {
      const hasServiceOrders = order.hasServiceOrders

      const isConfirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'journeyWillBeDeletedWarning',
          'The entire Journey and all associated orders will be deleted',
        ),
        t('confirmJourneyDelete', 'Confirm journey delete'),
        hasServiceOrders
          ? t(
              'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
              'Any associated stripping/stuffing orders will be deleted',
            )
          : undefined,
      )

      if (!isConfirmed) return

      try {
        const orderIds = []

        if (inboundOrderId && inboundOrderId !== -1) orderIds.push(inboundOrderId)
        if (outboundOrderId && outboundOrderId !== -1) orderIds.push(outboundOrderId)

        await adminActionService.deleteByIds(orderIds)

        appViewStore.setShowAlert(
          'success',
          t('successfullyDeletedJourney', 'Successfully deleted journey'),
        )
      } catch (e) {
        appViewStore.setShowAlert('error', t('failedToDeleteJourney', 'Failed to delete journey'))
      }
    }

    const getOptions = () => {
      const actions: ButtonOption[] = [
        {
          label: t('updateVisit', 'Update Visit'),
          testId: 'edit-visit-button',
          onClick: () => onClickEdit!(containerVisit),
        },
      ]

      const isAdmin = authStore.isAdmin()

      if (!isAdmin) return actions

      const adminActions: ButtonOption = {
        label: t('admin', 'Admin'),
        testId: 'admin-actions',
        openToLeft: true,
        children: [
          {
            testId: 'checkin-container',
            label: t('checkInContainer', 'Check in container'),
            disabled:
              (containerVisit?.journeyStatus !== ContainerJourneyStatus.Expected &&
                containerVisit?.journeyStatus !== ContainerJourneyStatus.Arrived) ||
              !inbound?.order,
            onClick: () => {
              navigateCheckinContainerVisitsPage(inbound!.order!)
            },
          },
          {
            testId: 'undo-checkin-container',
            label: t('undoCheckIn', 'Undo check in'),
            disabled:
              containerVisit?.journeyStatus === ContainerJourneyStatus.Expected ||
              containerVisit?.journeyStatus === ContainerJourneyStatus.Arrived ||
              containerVisit?.journeyStatus === ContainerJourneyStatus.Departed ||
              !inbound?.order,
            onClick: async () => {
              const inboundOrder = inbound!.order!

              const confirmed = await appViewStore.setOpenConfirmDialog(
                true,
                t(
                  'containerWillBeResetToExpected',
                  'Container no. {container} will be reset to Expected status and any associated jobs will be terminated. This will afect billing.',
                  { container: inboundOrder.containerNumber },
                ),
                t('undoCheckin', 'Undo check in'),
              )

              if (confirmed) {
                adminActionService.undoOrderCheckIn(inboundOrder.id)
              }
            },
          },
          {
            testId: 'checkout-container',
            label: t('checkOutContainer', 'Check out container'),
            disabled:
              containerVisit?.journeyStatus === ContainerJourneyStatus.Expected ||
              containerVisit?.journeyStatus === ContainerJourneyStatus.Arrived ||
              containerVisit?.journeyStatus === ContainerJourneyStatus.Departed ||
              !outbound?.order,
            onClick: () => {
              navigateCheckoutContainerVisitsPage(outbound!.order!)
            },
          },
          {
            testId: 'undo-checkout-container',
            label: t('undoCheckout', 'Undo check out'),
            disabled:
              containerVisit?.journeyStatus !== ContainerJourneyStatus.Departed || !outbound?.order,
            onClick: () => {
              const outboundOrder = outbound!.order!

              if (!outboundOrder.containerNumber) {
                appViewStore.setShowAlert(
                  'error',
                  t('containerNumberIsMissing', 'Container number is missing'),
                )
              }

              containerYardOperationViewStore!.open(
                outboundOrder.containerNumber!,
                outboundOrder.id,
                t('undoCheckout', 'Undo check out'),
                t(
                  'containerWillBeResetToTerminal',
                  'Container no. {container} will be reset to On terminal status. This will afect billing.',
                  { container: outboundOrder.containerNumber },
                ),
                async () => await adminActionService.undoOrderCheckOut(outboundOrder.id),
              )
            },
          },
          {
            label: t('correctYardLocation', 'Correct yard location'),
            disabled: containerVisit?.journeyStatus !== ContainerJourneyStatus.OnYard,
            onClick: () => {
              if (order?.containerNumber) {
                containerYardOperationViewStore!.open(order?.containerNumber, order?.id)
              } else {
                appViewStore.setShowAlert(
                  'error',
                  t('containerNumberIsMissing', 'Container number is missing'),
                )
              }
            },
          },
          {
            label: t('delete', 'Delete'),
            onClick: () => {
              if (order) onDeleteJourney(order)
            },
          },
        ],
      }

      return [...actions, adminActions]
    }

    return (
      <Grid container spacing={0}>
        <Grid item xs={isInsideIssueCard ? 4 : 2}>
          <Box
            sx={{
              padding: theme.customSpacing.s,
              height: '100%',
            }}
          >
            <Typography variant='subtitle1'>
              {order?.referenceNumber ? `Ref: ${order?.referenceNumber}` : ''}
            </Typography>
            <Typography variant='body1'>{customerName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={isInsideIssueCard ? 4 : 3}>
          <Box
            sx={{
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: inbound ? 'flex' : 'block',
              padding: theme.customSpacing.s,
              height: '100%',
            }}
          >
            <Typography variant='subtitle1' mr={theme.customSpacing.m}>
              {t('in', 'In')}
            </Typography>
            <ContainerVisitsCardCarrierVisitInfo direction='Inbound' containerData={inbound} />
          </Box>
        </Grid>
        {!isInsideIssueCard && (
          <Grid item xs={2}>
            <Box
              sx={{
                borderLeft: `1px solid ${theme.palette.divider}`,
                padding: theme.customSpacing.s,
                height: '100%',
              }}
            >
              <Typography variant='subtitle1'>{t('services', 'Services')}</Typography>
              <ContainerVisitsCardServicesInfo
                data={containerVisit}
                onClickAddService={(serviceOrdersType: ServiceOrdersType) => {
                  navigateToServiceOrderForm(
                    containerVisit,
                    serviceOrdersViewStore,
                    serviceOrdersType,
                  )
                }}
                onClickService={(serviceOrdersType: ServiceOrdersType) => {
                  navigateToServiceOrderForm(
                    containerVisit,
                    serviceOrdersViewStore,
                    serviceOrdersType,
                  )
                }}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={isInsideIssueCard ? 4 : 3}>
          <Box
            sx={{
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: outbound ? 'flex' : 'block',
              height: '100%',
              padding: theme.customSpacing.s,
            }}
          >
            <Typography variant='subtitle1' mr={theme.customSpacing.m}>
              {t('out', 'Out')}
            </Typography>

            <ContainerVisitsCardCarrierVisitInfo direction='Outbound' containerData={outbound} />
          </Box>
        </Grid>
        {!isInsideIssueCard && (
          <Grid item xs={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                height: '100%',
                alignItems: 'flex-start',
                placeContent: 'flex-end',
                borderLeft: `1px solid ${theme.palette.divider}`,
                padding: theme.customSpacing.s,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.customSpacing.m,
                }}
              >
                <HoldsChip
                  holds={inbound?.order?.holds.concat(outbound?.order?.holds || []) ?? []}
                />

                {(containerJourneyStatusFF && (
                  <ContainerJourneyStatusChip
                    status={containerVisit.journeyStatus}
                    direction={containerVisit.operationType}
                    inboundCarrierType={containerVisit.inboundOrder?.order?.carrierVisitType}
                    currentPosition={containerVisit.currentPosition}
                    equipment={containerVisit.equipmentType}
                    yardLocations={containerVisit.yardLocations}
                  />
                )) || (
                  <ContainerVisitStatusChip
                    label={t(_(containerVisit.status).lowerFirst())}
                    status={containerVisit.status}
                  />
                )}

                {onClickEdit && (
                  <OptionsButton
                    testId={'three-dot-menu-button'}
                    options={getOptions()}
                    position='bottom'
                  />
                )}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  },
)
