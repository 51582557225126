import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { FormProps } from '@storage/app/models'
import { ConfirmationDialogContainer } from '@storage/components/ConfirmationDialogContainer'
import { useTranslate } from '@tolgee/react'
import { IconButton, PlusIcon, ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { WeightClassSetContainerUIStore } from '../../stores/weight-class-set-ui-store'
import {
  WeightClassSetVesselServiceDialog,
  WeightClassSetWeightClassFormData,
} from './WeightClassSetVesselServiceDialog'
import { WeightClassSetWeighClassesForm } from './WeightClassSetWeighClassesForm'

interface Props extends FormProps {
  viewStore: WeightClassSetContainerUIStore
  onSubmit: (formData: WeightClassSetWeightClassFormData) => void
}

export const WeightClassSet: FC<Props> = observer(({ id, viewStore, onSubmit }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <>
      <Grid
        container
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: `${theme.customRadius.medium}`,
          height: '100%',
        }}
      >
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <List sx={{ padding: 0 }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant='subtitle1'>
                    {t('weightClassesSet', 'Weight classes set')}
                  </Typography>
                }
              />
              <IconButton
                variant='contained'
                color='default'
                onClick={() => viewStore.tryToggleVesselServiceDialog()}
              >
                <PlusIcon />
              </IconButton>
            </ListItem>
            <Divider />
            {viewStore.groupedWeightClassSets.map(set => (
              <Box key={set.vesselServiceId}>
                <ListItemButton
                  sx={{
                    borderRight:
                      viewStore.selectedWeightClassSetVesselServiceId === set.vesselServiceId
                        ? `4px solid ${theme.palette.primary.main}`
                        : '',
                    padding: `${set.vesselServiceId ? theme.customSpacing.xxs : theme.customSpacing.s} ${theme.customSpacing.m}`,
                    paddingRight:
                      viewStore.selectedWeightClassSetVesselServiceId === set.vesselServiceId
                        ? theme.customSpacing.s
                        : theme.customSpacing.m,
                  }}
                  onClick={_ => {
                    const weightClassMode = set.weightClassSets.find(ws => ws.isActive)
                      ?.containerLength
                      ? 1
                      : 0
                    viewStore.setSelectedWeightClassSetMode(weightClassMode)
                    viewStore.setSelectedWeightClassSetVesselServiceId(set.vesselServiceId)
                  }}
                >
                  <ListItemText primary={set.name} />
                  {set.vesselServiceId && (
                    <ThreeDotMenu>
                      <MenuItem
                        onClick={() => {
                          viewStore.setEditingVesselServiceId(set.vesselServiceId)
                          viewStore.tryToggleVesselServiceDialog()
                        }}
                      >
                        {t('edit', 'Edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          viewStore.setToBeRemovedWeightClassSetVesselServiceId(set.vesselServiceId)
                          viewStore.toggleDeleteConfirmationDialog()
                        }}
                      >
                        {t('remove', 'Remove')}
                      </MenuItem>
                    </ThreeDotMenu>
                  )}
                </ListItemButton>
                <Divider />
              </Box>
            ))}
          </List>
        </Grid>
        <Grid item xs={10}>
          <Grid container sx={{ padding: theme.customSpacing.m }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant='subtitle1'>
                    {`${t('setWeightClasses', '{set} weight classes', {
                      set: viewStore.selectedWeightClassSet?.name ?? t('unknown', 'Unknown'),
                    })}`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    sx={{ width: '100%' }}
                    value={viewStore.selectedWeightClassSetMode}
                    onChange={e =>
                      viewStore.setSelectedWeightClassSetMode(e.target.value as number)
                    }
                  >
                    <MenuItem
                      value={0}
                      onClick={() => {
                        viewStore.shareByContainerSize(
                          viewStore.selectedWeightClassSetVesselServiceId,
                        )
                      }}
                    >
                      {t('sharedByAllContainerSizes', 'Shared by all container sizes')}
                    </MenuItem>
                    <MenuItem
                      value={1}
                      onClick={() => {
                        viewStore.separateByContainerSize(
                          viewStore.selectedWeightClassSetVesselServiceId,
                        )
                      }}
                    >
                      {t('separatedByContainerSize', 'Separated by container size')}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <WeightClassSetWeighClassesForm id={id} viewStore={viewStore} onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <WeightClassSetVesselServiceDialog viewStore={viewStore} />
      <ConfirmationDialogContainer
        open={viewStore.isDeleteConfirmationDialogOpen}
        title={t('deleteWeightClass', 'Delete weight class set')}
        content={
          <Typography>
            {t(
              'deleteWeightClassConfirmation',
              'All expected containers will be grouped by the generic weight class set.',
            )}
          </Typography>
        }
        onClose={() => viewStore.toggleDeleteConfirmationDialog()}
        onConfirm={() => {
          viewStore.removeWeightClassSet(viewStore.toBeRemovedWeightClassSetVesselServiceId)
          viewStore.toggleDeleteConfirmationDialog()
        }}
      />
    </>
  )
})
