import { Box, Button, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  CarrierType,
  CarrierVisitStatus,
  OrderResponseDto,
  OrderStatus,
  TruckDto,
  VesselDto,
  VesselVisitDto,
} from '@planning/app/api'
import IconTruck from '@planning/components/icon/IconTruck'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { formatDate, formatTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { zip } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC } from 'react'
import { DropOffOrderStateCard } from './DropOffOrderStateCard'
import { GeneralCargoOrderStateCard } from './GeneralCargoOrderStateCard'
import { PickUpOrderStateCard } from './PickUpOrderStateCard'

type Props = {
  visit: ITruckVisitItem
  dropOffOrders: DropOffVisit[]
  pickUpOrders: PickUpVisit[]
  viewStore: GateInViewStore
}

// Use OrderItem instead
export type DropOffVisit = {
  dropOffOrder: OrderResponseDto
  theOtherDirectionStatus?: OrderStatus
  theOtherDirectionVisitType?: CarrierType
  theOtherDirectionVisit?: VesselVisitDto
  theOtherDirectionCarriers?: VesselDto[]
}

// Use OrderItem instead
export type PickUpVisit = {
  pickUpOrder: OrderResponseDto
  carriers: TruckDto[]
}

export const TruckVisitCard: FC<Props> = observer(
  ({ visit, dropOffOrders, pickUpOrders, viewStore }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const carrierVisitStatus = visit.data.status

    const onClickTruckAppointment = async (item: ITruckVisitItem) => {
      viewStore.openEditTruckVisitAppointment(item)
    }

    const renderVisitItems = () => {
      return zip(dropOffOrders, pickUpOrders).map(zipped => {
        const dropOff = zipped[0]
        const pickUp = zipped[1]
        const gridSize = dropOff && pickUp ? 6 : 12

        return (
          <Grid
            key={`${dropOff?.dropOffOrder.id}-${pickUp?.pickUpOrder.id}`}
            container
            sx={{
              borderStyle: 'solid',
              borderColor: `${theme.palette.grey[300]}`,
              borderWidth: '0px 1px 1px 1px',
            }}
          >
            {dropOff && (
              <Grid
                item
                xs={gridSize}
                sx={{
                  borderLeft: `1px solid ${theme.palette.grey[300]}`,
                  '&:first-of-type': { borderLeft: 'none' },
                }}
              >
                {(dropOff.dropOffOrder.commodityId && (
                  <GeneralCargoOrderStateCard type='DropOff' order={dropOff.dropOffOrder} />
                )) || (
                  <DropOffOrderStateCard
                    dropOffOrder={dropOff.dropOffOrder}
                    theOtherDirectionStatus={dropOff.theOtherDirectionStatus}
                    theOtherDirectionVisitType={dropOff.theOtherDirectionVisitType}
                    theOtherDirectionVisit={dropOff.theOtherDirectionVisit}
                    theOtherDirectionCarriers={dropOff.theOtherDirectionCarriers}
                    withBorderLineStyle={false}
                    withBorderAtTheEnd={!!pickUp}
                  />
                )}
              </Grid>
            )}
            {pickUp && (
              <Grid
                item
                xs={gridSize}
                sx={{
                  borderLeft: `1px solid ${theme.palette.grey[300]}`,
                  '&:first-of-type': { borderLeft: 'none' },
                }}
              >
                {(pickUp.pickUpOrder.commodityId && (
                  <GeneralCargoOrderStateCard type='PickUp' order={pickUp.pickUpOrder} />
                )) || (
                  <PickUpOrderStateCard
                    pickUpOrder={pickUp.pickUpOrder}
                    carriers={pickUp.carriers}
                    withBorderLineStyle={false}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )
      })
    }

    const validateGateOut = () => {
      viewStore.validateManualGateOut(
        visit.data,
        visit.orders.map(item => item.data),
      )
    }

    return (
      <div
        onClick={() =>
          carrierVisitStatus === CarrierVisitStatus.Expected
            ? onClickTruckAppointment(visit)
            : undefined
        }
        aria-hidden='true'
      >
        <Box
          data-cy={`truck-visit-card`}
          sx={{
            cursor: carrierVisitStatus === CarrierVisitStatus.Expected ? 'pointer' : undefined,
            backgroundColor: `${theme.palette.background.paper}`,
          }}
        >
          <Box
            sx={{
              borderTopLeftRadius: theme.customRadius.medium,
              borderTopRightRadius: theme.customRadius.medium,
              borderRadius:
                carrierVisitStatus === CarrierVisitStatus.Expected
                  ? theme.customRadius.medium
                  : undefined,
              border: `1px solid ${theme.palette.grey[300]}`,
              p: '0 16px',
              backgroundColor: `${theme.palette.background.paper}`,
            }}
          >
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='row' spacing='8px' alignSelf='center'>
                <IconTruck />
                <Typography variant='subtitle1'>{visit.data.identifier}</Typography>
              </Stack>

              {carrierVisitStatus === CarrierVisitStatus.Expected && (
                <Stack
                  direction='row'
                  sx={{ height: '64px', display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant='caption' alignSelf='center'>
                    {t('expected', 'Expected')}:{' '}
                    {visit.data.eta
                      ? `${formatDate(visit.data.eta)}   ${formatTime(visit.data.eta)}`
                      : 'unknown'}{' '}
                    - {visit.data.etd ? formatTime(visit.data.etd) : 'unknown'}
                  </Typography>
                </Stack>
              )}

              {carrierVisitStatus !== CarrierVisitStatus.Expected && (
                <Stack
                  direction='row'
                  sx={{ height: '4rem', display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant='caption' alignSelf='center'>
                    {carrierVisitStatus === CarrierVisitStatus.Arrived
                      ? `${t('arrived', 'Arrived')}: ${moment(visit.data.ata).fromNow()}`
                      : `${t('departed', 'Departed')}: ${moment(visit.data.atd).fromNow()}`}
                  </Typography>
                  {carrierVisitStatus === CarrierVisitStatus.Arrived && (
                    <Button
                      sx={{ marginLeft: '1rem' }}
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={validateGateOut}
                    >
                      {t('gateOut', 'Gate Out')}
                    </Button>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>
          {carrierVisitStatus !== CarrierVisitStatus.Expected && renderVisitItems()}
        </Box>
      </div>
    )
  },
)
